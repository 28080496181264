import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js'
import { Table, Grid, Segment, Header, Icon, Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import * as Messages from '../../../Resources/Messages.js';
import * as Constant from '../../../Constants/Constant.js';
import ErrorMessage from '../../ErrorMessage.js';

export class RequestConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnable: true,
            warrantyPeriodValidateMessage: Messages.REPAIRREQUEST_MSG_WARRANTYPERIODVALIDATION_VERIFIED,
            warrantyExpiredDetailList: [],
        }

        this.warrantyPeriodCheck = this.warrantyPeriodCheck.bind(this);
        this.getWarrantyPeriod = this.getWarrantyPeriod.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    componentDidMount() {
        this.warrantyPeriodCheck();
    }

    componentWillUnmount() {
        this.props.Action.requestSubmittingError(false);
    }

    warrantyPeriodCheck() {
        let submitEnable = true;
        let warrantyPeriodValidateMessage = Messages.REPAIRREQUEST_MSG_WARRANTYPERIODVALIDATION_VERIFIED;
        let warrantyExpiredDetailList = []; // 保証期間外のDetailを入れる
        let today = new Date();

        this.props.repairRequest.RepairDetails.filter(x => x.Category == Constant.REQUOEST_CATEGORY_RADIO).forEach(detail => {
            // シリアル番号から製造日を割り出す
            let manufactureDate = this.getManufactureDate(detail.SerialNumber);
            if (manufactureDate != null) {
                // モデルから保証期間を割り出す
                let warrantyPeriod = this.getWarrantyPeriod(detail.Model);
                // 製造日と保証期間、猶予期間を使って保証期間内かをチェック
                let warrantyEndMonth = manufactureDate.getMonth() + warrantyPeriod + this.props.Reducer.gracePeriod;
                let warrantyEndDate = new Date(manufactureDate.getFullYear(), warrantyEndMonth + 1, 0);
                if (today < warrantyEndDate) {
                    return; // continue
                }
            }

            // シリアル番号が間違っている or 保証期間外
            submitEnable = false;
            warrantyPeriodValidateMessage = Messages.REPAIRREQUEST_MSG_WARRANTYPERIODVALIDATION_WARRANTYEXPIRED;
            warrantyExpiredDetailList.push(detail);
        })

        this.setState({
            submitEnable,
            warrantyPeriodValidateMessage,
            warrantyExpiredDetailList,
        });
    }

    // モデル名から保証期間を月単位で返す
    getWarrantyPeriod(model) {
        let rtnWarrantyPeriod = 0;
        model = model.toUpperCase();
        loop:
        for (let i = 0; i < this.props.Reducer.warrantyMaster.length; i++) {
            let warranty = this.props.Reducer.warrantyMaster[i];
            if (warranty.Id == 0) {
                // デフォルトの保証期間
                rtnWarrantyPeriod = warranty.WarrantyPeriod;
                continue;
            }
            for (let j = 0; j < warranty.Rules?.length; j++) {
                let rule = warranty.Rules[j];
                if (model.charAt(rule.Digit) != rule.Value.toUpperCase()) {
                    break;
                }
                if (j + 1 == warranty.Rules.length) {
                    // 全てのルールに一致したら保証期間を上書き
                    rtnWarrantyPeriod = warranty.WarrantyPeriod;
                    break loop;
                }
            }
        }
        return rtnWarrantyPeriod;
    }

    // シリアル番号から購入日を割り出す
    getManufactureDate(serialNumber) {
        let year = 2000;
        let month = 0;

        // 一文字目はA-Z
        // 2000年の10の位まで特定
        // A: 200x, B: 201x, C: 202x, ...
        if (RegExp(/[A-Z]/).test(serialNumber.charAt(0))) {
            let tensPlaceOfYear = serialNumber.charCodeAt(0) - 'A'.charCodeAt(0);
            year += tensPlaceOfYear * 10;
        } else {
            return null;
        }

        // 二文字目は0-9
        // 年度の1の位の数
        // 1: xxx1年, 2: xxx2年, ...
        if (RegExp(/[0-9]/).test(serialNumber.charAt(1))) {
            year += Number(serialNumber.charAt(1));
        } else {
            return null;
        }

        // 三文字目は1-9A-C
        // 16進数みたいな感じで、製造月
        // 1: 1月, 2: 2月, ..., A: 10月, B: 11月, C: 12月
        let thirdChar = serialNumber.charAt(2);
        if (RegExp(/[1-9]/).test(thirdChar)) {
            month = Number(thirdChar);
        } else if (thirdChar === 'A') {
            month = 10;
        } else if (thirdChar === 'B') {
            month = 11;
        } else if (thirdChar === 'C') {
            month = 12;
        } else {
            return null;
        }

        let manufactureDate = new Date(year, month - 1);
        if (new Date() < manufactureDate) {
            // 製造日が未来の日付の場合、nullを返す
            return null;
        }

        return manufactureDate;
    }

    onSubmitClick() {
        document.querySelector('#confirmModal').scrollTo(0, 0);
        let request = {
            ...this.props.repairRequest,
            ShipToId: this.props.selectedShipToMasterId,
        }
        this.props.Action.submitRequest(this.props.Reducer.csrftoken, request, this.props.isSaveAddressBook);
    }

    render() {
        let segmentStyle = { height: '100%' };
        let marginRight = { marginRight: 20, display: 'inline-block' };

        let repairRequest = JSON.parse(JSON.stringify(this.props.repairRequest));

        if (this.state.submitEnable) {
            var warrantyPeriodValidateMessage =
                <Header as='h3' color='green'><Icon name='check' />{this.state.warrantyPeriodValidateMessage}</Header>;
        } else {
            warrantyPeriodValidateMessage =
                <Header as='h3' color='red'><Icon name='exclamation' />{this.state.warrantyPeriodValidateMessage}</Header>;
        }

        let errorMessage;
        if (this.props.Reducer.requestSubmittingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        return (
            <Modal open size='fullscreen' id='fullscreenModal'>
                <Modal.Header>
                    <Header content='Please confirm and press submit button' />
                </Modal.Header>
                <Modal.Content scrolling style={{ maxHeight: '70vh' }} id='confirmModal'>
                    {errorMessage}
                    {warrantyPeriodValidateMessage}
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='building' content='Customer information' dividing />
                                    <dl>
                                        <div style={marginRight}>
                                            <dt>Contact name</dt>
                                            <dd>{repairRequest.ContactName}</dd>
                                        </div>
                                        <div style={marginRight}>
                                            <dt>Email</dt>
                                            <dd>{repairRequest.Email}</dd>
                                        </div>
                                        <dt>Address</dt>
                                        <dd>{repairRequest.AddressLineOne}</dd>
                                        <dd>{repairRequest.AddressLineTwo != '' ? repairRequest.AddressLineTwo : '-'}</dd>
                                        <dd>{repairRequest.AddressLineThree != '' ? repairRequest.AddressLineThree : '-'}</dd>
                                        <dd>{repairRequest.City}, {repairRequest.State} {repairRequest.Zip}</dd>
                                        <dd>{repairRequest.Country}</dd>
                                        <div style={marginRight}>
                                            <dt>Telephone</dt>
                                            <dd>{repairRequest.Telephone}</dd>
                                        </div>
                                        <div style={marginRight}>
                                            <dt>Fax</dt>
                                            <dd>{repairRequest.Fax != '' ? repairRequest.Fax : '-'}</dd>
                                        </div>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='shipping' content='Shipping information' dividing />
                                    <dl>
                                        <dt>Shipping option</dt>
                                        <dd>{this.props.Reducer.upsServiceCodes.find(x => x.value == repairRequest.UpsServiceCode).text}</dd>
                                        <dt>Ship to</dt>
                                        <dd>{repairRequest.ShipToDescription}</dd>
                                        <dt>Address</dt>
                                        <dd>{repairRequest.ShipToAddress}</dd>
                                        <dd>{repairRequest.ShipToCity}, {repairRequest.ShipToState} {repairRequest.ShipToZip}</dd>
                                        <dd>{repairRequest.ShipToCountry}</dd>
                                        <dt>Weight</dt>
                                        <dd>
                                            <ol class='weight-shipTrackingNumber'>
                                                {repairRequest.TrackingNumberDetails.map(x => <li>{x.Weight} lbs</li>)}
                                            </ol>
                                        </dd>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header icon='tag' content='Repair details' dividing />
                                    <Table compact celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell content='Category' />
                                                <Table.HeaderCell content='Model' />
                                                <Table.HeaderCell content='Serial #' />
                                                <Table.HeaderCell content='Problem description' />
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {repairRequest.RepairDetails.sort((a, b) => {
                                                if (a.Category == b.Category) {
                                                    if (a.Model == b.Model) {
                                                        return a.SerialNumber < b.SerialNumber ? -1 : 1;
                                                    }
                                                    return a.Model < b.Model ? -1 : 1;
                                                }
                                                return a.Category < b.Category ? -1 : 1;
                                            }).map(detail => {
                                                // 保証期間外の製品はエラー表示
                                                let errorFlag = false;
                                                if (!this.state.submitEnable) {
                                                    errorFlag = this.state.warrantyExpiredDetailList.some(x => (
                                                        x.Model == detail.Model && x.SerialNumber == detail.SerialNumber
                                                    ));
                                                }
                                                return (
                                                    <Table.Row error={errorFlag}>
                                                        <Table.Cell collapsing content={detail.Category} />
                                                        <Table.Cell collapsing content={detail.Model} />
                                                        <Table.Cell collapsing content={detail.SerialNumber} />
                                                        <Table.Cell>
                                                            <pre style={{ whiteSpace: 'pre-line' }}>{detail.ProblemDescription}</pre>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Close' onClick={this.props.confirmModalClose} />
                    <Button content='Submit' icon='pin' color='blue' onClick={this.onSubmitClick} disabled={!this.state.submitEnable} />
                </Modal.Actions>

                <Dimmer active={this.props.Reducer.requestSubmitting}>
                    <Loader content="Loading..." size="huge" />
                </Dimmer>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestConfirm);
