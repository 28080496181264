// Action types //////////////////////////////////////////////////
// Root
export const ROOT_ACT_SESSIONCHECK_FETCHING = 'ROOT_ACT_SESSIONCHECK_FETCHING';
export const ROOT_ACT_LOGOUT_FETCHINGERROR = 'ROOT_ACT_LOGOUT_FETCHINGERROR';
export const ROOT_ACT_LOGOUT_FETCHING = 'ROOT_ACT_LOGOUT_FETCHING';
export const ROOT_ACT_LOGOUT_SUCCESS = 'ROOT_ACT_LOGOUT_SUCCESS';
export const ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHING = 'ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHING';
export const ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHINGERROR = 'ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHINGERROR';

// Login
export const LOGIN_ACT_LOGINCHECK_FETCHING = 'LOGIN_ACT_LOGINCHECK_FETCHING';
export const LOGIN_ACT_LOGINCHECK_FETCHINGERROR = 'LOGIN_ACT_LOGINCHECK_FETCHINGERROR';
export const LOGIN_ACT_INFORMATIONFETCH_FETCHING = 'LOGIN_ACT_INFORMATIONFETCH_FETCHING';
export const LOGIN_ACT_INFORMATIONFETCH_FETCHINGERROR = 'LOGIN_ACT_INFORMATIONFETCH_FETCHINGERROR';
export const LOGIN_ACT_INFORMATIONFETCH_SUCCESS = 'LOGIN_ACT_INFORMATIONFETCH_SUCCESS';

// History
export const HISTORY_ACT_REQUESTFETCH_LOADING = 'HISTORY_ACT_REQUESTFETCH_LOADING';
export const HISTORY_ACT_REQUESTFETCH_LOADINGERROR = 'HISTORY_ACT_REQUESTFETCH_LOADINGERROR';
export const HISTORY_ACT_REQUESTFETCH_SUCCESS = 'HISTORY_ACT_REQUESTFETCH_SUCCESS';

// Request
export const REQUEST_ACT_MASTERDATAFETCH_FETCHING = 'REQUEST_ACT_MASTERDATAFETCH_FETCHING';
export const REQUEST_ACT_MASTERDATAFETCH_FETCHINGERROR = 'REQUEST_ACT_MASTERDATAFETCH_FETCHINGERROR';
export const REQUEST_ACT_MASTERDATAFETCH_SUCCESS = 'REQUEST_ACT_MASTERDATAFETCH_SUCCESS';
export const REQUEST_ACT_REQUESTSUBMIT_FETCHING = 'REQUEST_ACT_REQUESTSUBMIT_FETCHING';
export const REQUEST_ACT_REQUESTSUBMIT_FETCHINGERROR = 'REQUEST_ACT_REQUESTSUBMIT_FETCHINGERROR';
export const REQUEST_ACT_REQUESTSUBMIT_SUCCESS = 'REQUEST_ACT_REQUESTSUBMIT_SUCCESS';
export const REQUEST_STAT_COMPLETEMODAL = 'REQUEST_STAT_COMPLETEMODAL';

// User Setting
export const USERSETTING_ACT_USERMASTERFETCH_FETCHINGERROR = 'USERSETTING_ACT_USERMASTERFETCH_FETCHINGERROR';
export const USERSETTING_ACT_USERMASTERFETCH_FETCHING = 'USERSETTING_ACT_USERMASTERFETCH_FETCHING';
export const USERSETTING_ACT_USERMASTERFETCH_SUCCESS = 'USERSETTING_ACT_USERMASTERFETCH_SUCCESS';
export const USERSETTING_ACT_UPDATE_FETCHINGERROR = 'USERSETTING_ACT_UPDATE_FETCHINGERROR';
export const USERSETTING_ACT_UPDATE_FETCHING = 'USERSETTING_ACT_UPDATE_FETCHING';
export const USERSETTING_ACT_UPDATE_SUCCESS = 'USERSETTING_ACT_UPDATE_SUCCESS';
export const USERSETTING_ACT_INSERT_FETCHINGERROR = 'USERSETTING_ACT_INSERT_FETCHINGERROR';
export const USERSETTING_ACT_INSERT_FETCHING = 'USERSETTING_ACT_INSERT_FETCHING';
export const USERSETTING_ACT_INSERT_SUCCESS = 'USERSETTING_ACT_INSERT_SUCCESS';

// File download
export const FILEDOWNLOAD_ACT_DOWNLOADING = 'FILEDOWNLOAD_ACT_DOWNLOADING';
export const FILEDOWNLOAD_ACT_DOWNLOADINGERROR = 'FILEDOWNLOAD_ACT_DOWNLOADINGERROR';
export const FILEDOWNLOAD_PACKINGSLIP = 'PackingSlip';
export const FILEDOWNLOAD_SHIPPINGLABEL = 'ShippingLabel';
export const FILEDOWNLOAD_ACT_MANUALDOWNLOADING = 'FILEDOWNLOAD_ACT_MANUALDOWNLOADING';
export const FILEDOWNLOAD_ACT_MANUALDOWNLOADINGERROR = 'FILEDOWNLOAD_ACT_MANUALDOWNLOADINGERROR';

// Protection plan
export const PROTECTIONPLAN_ACT_INVOICE_FETCHING = 'PROTECTIONPLAN_ACT_INVOICE_FETCHING';
export const PROTECTIONPLAN_ACT_INVOICE_FETCHINGERROR = 'PROTECTIONPLAN_ACT_INVOICE_FETCHINGERROR';
export const PROTECTIONPLAN_ACT_SAVING = 'PROTECTIONPLAN_ACT_SAVING';
export const PROTECTIONPLAN_ACT_SAVINGERROR = 'PROTECTIONPLAN_ACT_SAVINGERROR';
export const PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHING = 'PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHING';
export const PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHINGERROR = 'PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHINGERROR';
export const PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHING = 'PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHING';
export const PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHINGERROR = 'PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHINGERROR';
export const PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHING = 'PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHING';
export const PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHINGERROR = 'PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHINGERROR';

// Set Reducer
export const REPAIRREQUEST_ACT_SET_TABSTATUS = 'REPAIRREQUEST_ACT_SET_TABSTATUS';
export const LOGIN_ACT_SET_USERINFORMATION = 'LOGIN_ACT_SET_USERINFORMATION';
export const LOGIN_ACT_SET_CSRFTOKEN = 'LOGIN_ACT_SET_CSRFTOKEN';
export const REQUEST_ACT_SET_DISCLAIMER = 'REQUEST_ACT_SET_DISCLAIMER';
export const REQUEST_ACT_SET_CUSTOMERADDRESSBOOK = 'REQUEST_ACT_SET_CUSTOMERADDRESSBOOK';
export const USERSETTING_ACT_SET_CUSTOMERENDUSERS = 'USERSETTING_ACT_SET_CUSTOMERENDUSERS';
export const PROTECTOPNPLAN_ACT_SET_TEMPINVOICEMASTER = 'PROTECTOPNPLAN_ACT_SET_TEMPINVOICEMASTER';
export const PROTECTOPNPLAN_ACT_UPDATE_PROTECTIONPLAN = 'PROTECTOPNPLAN_ACT_UPDATE_PROTECTIONPLAN';
export const PROTECTOPNPLAN_ACT_SET_REPAIRSERVICEPLANMASTER = 'PROTECTOPNPLAN_ACT_SET_REPAIRSERVICEPLANMASTER';
export const PROTECTOPNPLAN_ACT_SET_MODELRULEMASTER = 'PROTECTOPNPLAN_ACT_SET_MODELRULEMASTER';
export const PROTECTOPNPLAN_ACT_SET_MODELRULESFORREPAIRSERVICEPLANCOVERAGE = 'PROTECTOPNPLAN_ACT_SET_MODELRULESFORREPAIRSERVICEPLANCOVERAGE';

// Constants //////////////////////////////////////////////////
// Screen status
export const REPAIRREQUEST_STAT_LOGIN = 'REPAIRREQUEST_STAT_LOGIN';
export const REPAIRREQUEST_STAT_FORGOTPASSWORD = 'REPAIRREQUEST_STAT_FORGOTPASSWORD';
export const REPAIRREQUEST_STAT_RESETPASSWORD = 'REPAIRREQUEST_STAT_RESETPASSWORD';
export const REPAIRREQUEST_STAT_NEW_SHIPMENT = 'REPAIRREQUEST_STAT_NEW_SHIPMENT';
export const REPAIRREQUEST_STAT_HISTORY = 'REPAIRREQUEST_STAT_HISTORY';
export const REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY = 'REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY';
export const REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER = 'REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER';
export const REPAIRREQUEST_STAT_CONATCTUS = 'REPAIRREQUEST_STAT_CONATCTUS';
export const REPAIRREQUEST_STAT_USERSETTING = 'REPAIRREQUEST_STAT_USERSETTING';
export const REPAIRREQUEST_STAT_SELECT_COMPONENT = 'REPAIRREQUEST_STAT_SELECT_COMPONENT';

// Category
export const REQUOEST_CATEGORY_RADIO = 'Radio';
export const REQUOEST_CATEGORY_CONTROLEHEAD = 'Control Head';
export const REQUOEST_CATEGORY_ACCESSORIES = 'Accessories';

// File name
export const FILEDOWNLOAD_NAME_MANUAL = 'How to use Extended Services Portal';

// Protection Plan
export const PROTECTIONPLAN_STAT_LIST = 'LIST';
export const PROTECTIONPLAN_STAT_REGISTER = 'REGISTER';
export const PROTECTIONPLAN_STAT_VIEW = 'VIEW';
