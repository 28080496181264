import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import { Table, Checkbox, Input, Button, Message, Dimmer, Loader, Modal, Form, Tab } from 'semantic-ui-react';
import Functions from '../../Functions.js';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

export class UsersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [], // List of users currently displayed on the screen
            updateUserIds: [], // List of IDs for the modified UserMaster
            loginUsersId: 0, // ID of the currently logged-in user in the UserMaster
            validationErrors: {},
            isUpdateSuccess: false,
            isAddUserSuccess: false,

            // Add user modal
            isAddUserModalOpen: false,
            userId: '',
            userName: '',
        }

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.onAddUserModalClick = this.onAddUserModalClick.bind(this);
        this.onAddUserFormChange = this.onAddUserFormChange.bind(this);
        this.onAddUserSubmitClick = this.onAddUserSubmitClick.bind(this);
        this.onUserIdBlur = this.onUserIdBlur.bind(this);
    }

    componentDidMount() {
        let userList = this.props.Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
        let loginUsersId = userList.find(x => x.userId == this.props.Reducer.userInformation.UserId)?.id;
        this.setState({ userList, loginUsersId });
    }

    componentDidUpdate(prevProps, prevState) {
        // Initial communication has been completed
        if (prevProps.Reducer.userMasterFetching && !this.props.Reducer.userMasterFetching && !this.props.Reducer.userMasterFetchingError) {
            let userList = this.props.Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
            let loginUsersId = userList.find(x => x.userId == this.props.Reducer.userInformation.UserId).id
            this.setState({ userList, loginUsersId });
        }
        // UserMaster has been successfully updated
        else if (prevProps.Reducer.userSettingUpdating && !this.props.Reducer.userSettingUpdating && !this.props.Reducer.userSettingUpdatingError) {
            let userList = this.props.Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
            this.setState({
                userList,
                isUpdateSuccess: true,
                updateUserIds: [],
            });
            this.props.setHasChanged(false);
        }
        // User addtionan has been completed
        else if (prevProps.Reducer.customerUserAdding && !this.props.Reducer.customerUserAdding && !this.props.Reducer.customerUserAddingError) {
            let userList = this.props.Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
            this.setState({
                userList,
                isAddUserSuccess: true,
                isAddUserModalOpen: false,
                userId: '',
                userName: '',
            });
        }
        // User addtion modal has been opened
        else if (!prevState.isAddUserModalOpen && this.state.isAddUserModalOpen) {
            let userList = this.props.Reducer.userMaster.map(x => Object.assign(Object.create(x), x));
            this.setState({
                userList,
                updateUserIds: [],
                isUpdateSuccess: false,
            });
            this.props.setHasChanged(false);
        }
        else if (
            (!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading)
            || (!prevProps.Reducer.logouting && this.props.Reducer.logouting)
        ) {
            this.setState({ validationErrors: {} });
        }
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization(true);
    }

    loadingErrorFlagInitialization(isUnmount = false) {
        if (!isUnmount || !this.props.Reducer.userMasterFetchingError) {
            this.props.Action.userSettingUpdatingError(false);
            this.props.Action.customerUserAddingError(false);
            this.props.Action.masterDataFetchingError(false);
            this.props.Action.userMasterFetchError(false);
            this.props.Action.logoutFetchingError(false);
            this.props.Action.manualDownloadingError(false);
        }
    }

    onTableChange(env, data) {
        const { name, value, userMasterId } = data;
        let userList = this.state.userList.map(x => Object.assign(Object.create(x), x));
        let index = userList.findIndex(x => x.id == userMasterId);
        userList[index][name] = value;
        let updateUserIds = [...this.state.updateUserIds];
        if (!updateUserIds.some(x => x == userMasterId)) {
            updateUserIds.push(userMasterId);
        }
        this.setState({ userList, updateUserIds });
        this.props.setHasChanged(true);
    }

    onUpdateClick() {
        // validation check
        let updatedUserList = [];
        let validationErrors = {};
        this.state.updateUserIds.forEach(id => {
            let user = this.state.userList.find(x => x.id == id);
            if (!validationErrors?.userId) {
                let userId = user.userId.trim().toLowerCase();
                if (!userId) {
                    validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_EMPTY;
                } else if (Functions.byteLengthOf(userId) > 100) {
                    validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_LENGTH;
                } else if (this.state.userList.filter(x => x.userId.trim().toLowerCase() == userId).length > 1) {
                    validationErrors.userId = `"${user.userId}" ${Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_DUPLICATE}`;
                }
            }
            if (!validationErrors?.userName) {
                if (Functions.byteLengthOf(user.userName) > 255) {
                    validationErrors.userName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERNAME_LENGTH;
                }
            }
            updatedUserList.push(user);
        })
        this.setState({ validationErrors, isAddUserSuccess: false, isUpdateSuccess: false });
        if (updatedUserList.length == 0) {
            window.alert(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_NOCONTENT);
            return;
        }
        if (Object.keys(validationErrors).length == 0) {
            // 自分のUserIdを変更していた場合、セッションが削除されるので、更新後にメッセージを表示する
            let ownUserMaster = this.props.Reducer.userMaster.find(x => x.userId == this.props.Reducer.userInformation.UserId);
            let isChangeOwnUserId = false;
            if (updatedUserList.some(x => x.id == ownUserMaster.id)) {
                var updateUser = updatedUserList.find(x => x.id == ownUserMaster.id);
                isChangeOwnUserId = updateUser.userId != ownUserMaster.userId;
            }
            this.props.Action.updateUserSetting(updatedUserList, this.props.Reducer.csrftoken, isChangeOwnUserId);
        }

        this.loadingErrorFlagInitialization();
    }

    onAddUserModalClick() {
        if (!this.state.isAddUserModalOpen && (this.state.updateUserIds.length > 0)) {
            if (!window.confirm(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_ADDUSER)) {
                return;
            }
        } else if (this.state.isAddUserModalOpen && this.state.validationErrors.length > 0) {
            return;
        }
        this.setState({
            isAddUserModalOpen: !this.state.isAddUserModalOpen,
            userId: '',
            userName: '',
            validationErrors: {},
            isAddUserSuccess: false,
        });

        this.loadingErrorFlagInitialization();
    }

    onAddUserFormChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onAddUserSubmitClick() {
        this.props.Action.customerUserAddingError(false);
        let validationErrors = {};
        let userId = this.state.userId.trim().toLowerCase();
        let userName = this.state.userName;
        if (!userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_EMPTY;
        } else if (userId.length > 100) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_LENGTH;
        } else if (this.props.Reducer.userMaster.find(x => x.userId == userId)) {
            validationErrors.userId = `"${this.state.userId}" ${Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_DUPLICATE}`;
        }
        if (userName.length > 255) {
            validationErrors.userName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERNAME_LENGTH;
        }
        this.setState({ validationErrors, isAddUserSuccess: false });
        if (Object.keys(validationErrors).length == 0) {
            this.props.Action.addCustomerUser(userId, userName, this.props.Reducer.csrftoken);
        }
    }

    // Remove space from the inpur UserId
    onUserIdBlur() {
        let userList = this.state.userList.map(user => {
            user.userId = user.userId.replace(/\s+/g, '');
            return user;
        })
        let userId = this.state.userId.replace(/\s+/g, '');
        this.setState({ userList, userId });
    }

    render() {
        let defaultBtnColor = 'blue';
        let styleCell = { padding: '0.3em 0.5em' };

        let userInformation = this.props.Reducer.userInformation;
        let managerFlag = userInformation.ManagerFlag;

        let errorMessage = '';
        if (Object.keys(this.state.validationErrors).length) {
            let errors = Object.keys(this.state.validationErrors).map(x => <>{this.state.validationErrors[x]}<br /></>);
            errorMessage = <Message error>{errors}</Message>;
        } else if (this.props.Reducer.userMasterFetchingError || this.props.Reducer.userSettingUpdatingError || this.props.Reducer.customerUserAddingError || this.props.Reducer.manualDownloadingError || this.props.Reducer.logoutingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        let successMessage = '';
        if (this.state.isUpdateSuccess || this.state.isAddUserSuccess) {
            successMessage = <Message info>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_USERSETTING}</Message>;
        }

        let userTable = this.state.userList.map(user => {
            // errorFlag
            let userIdErrorFlag = false;
            if (this.state.validationErrors.userId) {
                if (user.userId === null || user.userId === '' || Functions.byteLengthOf(user.userId) > 100) {
                    userIdErrorFlag = true;
                }
            }
            let userNameErrorFlag = false;
            if (this.state.validationErrors.userName) {
                if (user.userName && Functions.byteLengthOf(user.userName) > 255) {
                    userNameErrorFlag = true;
                }
            }

            return (
                <Table.Row key={user.id}>
                    <Table.Cell>
                        {managerFlag
                            ? <Input fluid name='userId' value={user.userId} userMasterId={user.id} onChange={this.onTableChange}
                                onBlur={this.onUserIdBlur} error={userIdErrorFlag} />
                            : user.userId}
                    </Table.Cell>
                    <Table.Cell>
                        {managerFlag
                            ? <Input fluid name='userName' value={user.userName} userMasterId={user.id} onChange={this.onTableChange}
                                error={userNameErrorFlag} />
                            : user.userName}
                    </Table.Cell>
                    <Table.Cell>
                        {/* Enable for the logged-in user cannot be modified */}
                        <Checkbox toggle name='accountEnable' checked={user.accountEnable} value={!user.accountEnable} onChange={this.onTableChange} userMasterId={user.id} disabled={!managerFlag || user.id == this.state.loginUsersId} />
                    </Table.Cell>
                    <Table.Cell>
                        {user.updateDate ? Functions.dateForm(user.updateDate) : '-'}
                    </Table.Cell>
                    <Table.Cell>
                        {user.latestLogin ? Functions.dateForm(user.latestLogin) : '-'}
                    </Table.Cell>
                </Table.Row>
            );
        });

        let addUserModal;
        if (this.state.isAddUserModalOpen) {
            let userId = this.state.userId;
            let userName = this.state.userName;

            // errorFlag
            let userIdErrorFlag = false;
            if (this.state.validationErrors.userId) {
                if (userId === null || userId === '' || Functions.byteLengthOf(userId) > 100) {
                    userIdErrorFlag = true;
                }
            }
            let userNameErrorFlag = false;
            if (this.state.validationErrors.userName) {
                if (userName && Functions.byteLengthOf(userName) > 255) {
                    userNameErrorFlag = true;
                }
            }

            addUserModal = (
                <Modal open>
                    <Modal.Header>Add user to {userInformation.CustomerName}(#{userInformation.CustomerNumber})</Modal.Header>
                    <Modal.Content>
                        {errorMessage}
                        <Form>
                            <Form.Input label='User ID' required name='userId' onChange={this.onAddUserFormChange} value={userId} onBlur={this.onUserIdBlur} error={userIdErrorFlag} />
                            <Form.Input label='User name' name='userName' onChange={this.onAddUserFormChange} value={userName} error={userNameErrorFlag} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content='Submit' color='blue' onClick={this.onAddUserSubmitClick} />
                        <Button content='Cancel' onClick={this.onAddUserModalClick} />
                    </Modal.Actions>
                    <Dimmer active={this.props.Reducer.customerUserAdding}>
                        <Loader content='Loading...' size='huge' />
                    </Dimmer>
                </Modal>
            );
        }

        return (
            <Tab.Pane>
                {this.state.isAddUserModalOpen ? '' : errorMessage}
                {successMessage}
                {managerFlag
                    ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button content='Add user' icon='plus' onClick={this.onAddUserModalClick} color={defaultBtnColor}
                            disabled={this.props.Reducer.userMasterFetchingError} />
                        <Button content='Update' icon='sync alternate' color='green' onClick={this.onUpdateClick}
                            disabled={this.props.Reducer.userMasterFetchingError} />
                    </div>
                    : ''}
                <Table celled striped size='small' compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={styleCell} width={6}>User ID</Table.HeaderCell>
                            <Table.HeaderCell style={styleCell} width={6}>User name</Table.HeaderCell>
                            <Table.HeaderCell style={styleCell} width={0}>Enable</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Update date</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Latest login</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {userTable}
                    </Table.Body>
                </Table>
                {addUserModal}
            </Tab.Pane>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersTab);
