import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js'

import { Segment, Header, Input, Table, Button, Icon, Pagination, Dimmer, Loader, Dropdown, Popup } from 'semantic-ui-react';

import Functions from '../../../Functions.js';
import HistoryDetail from './HistoryDetail';
import ErrorMessage from '../../ErrorMessage.js';

export class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestedDateFrom: '',
            requestedDateTo: '',
            modelSerial: '',
            shipTracking: '',

            currentPage: 1,
            sort: 'requestedDateDesc',
            openModal: false,
            selectedRepairRequestId: 0,

            sortOptions: [
                { key: 'requestedDateDesc', value: 'requestedDateDesc', text: 'Requested date (DESC)' },
                { key: 'requestedDateAsc', value: 'requestedDateAsc', text: 'Requested date (ASC)' },
            ],
        };

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onSearchConditionChange = this.onSearchConditionChange.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onModalButtonClick = this.onModalButtonClick.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.filteringAndReordering = this.filteringAndReordering.bind(this);
    }

    componentDidMount() {
        this.props.Action.requestFetch(this.props.Reducer.csrftoken, !!this.props.Reducer.repairRequests.length);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization();
    }

    loadingErrorFlagInitialization() {
        this.props.Action.requestFetchingError(false);
        this.props.Action.logoutFetchingError(false);
        this.props.Action.manualDownloadingError(false);
    }

    onSearchConditionChange(evn, data) {
        this.setState({ [data.name]: data.value, currentPage: 1 });
    }

    onClearClick() {
        this.setState({
            shipTracking: '',
            modelSerial: '',
            requestedDateFrom: '',
            requestedDateTo: '',
            currentPage: 1,
        });
    }

    onModalButtonClick(evn, data) {
        let selectedRepairRequestId = data.id ?? 0;
        this.setState({ openModal: !this.state.openModal, selectedRepairRequestId });
        this.loadingErrorFlagInitialization();
    }

    onSortChange(evn, data) {
        this.setState({ sort: data.value, currentPage: 1 });
    }

    onPageChange(evn, data) {
        this.setState({ currentPage: data.activePage });
    }

    filteringAndReordering() {
        let repairRequests = JSON.parse(JSON.stringify(this.props.Reducer.repairRequests));

        // filter
        if (this.state.requestedDateFrom) {
            let requestedDateFrom = new Date(this.state.requestedDateFrom).setHours(0, 0, 0, 0);
            repairRequests = repairRequests.filter(x => new Date(x.RequestedDate) >= requestedDateFrom);
        }
        if (this.state.requestedDateTo) {
            let requestedDateTo = new Date(this.state.requestedDateTo).setHours(23, 59, 59, 999);
            repairRequests = repairRequests.filter(x => new Date(x.RequestedDate) <= requestedDateTo);
        }
        if (this.state.modelSerial) {
            let modelSerial = this.state.modelSerial.toLocaleLowerCase();
            let filterdRepairRequests = [];
            repairRequests.forEach(header => {
                header.RepairDetails = header.RepairDetails.filter(x => x.Model.toLocaleLowerCase().indexOf(modelSerial) != -1 || x.SerialNumber.toLocaleLowerCase().indexOf(modelSerial) != -1);
                if (header.RepairDetails.length) {
                    filterdRepairRequests.push(header);
                }
            })
            repairRequests = filterdRepairRequests;
        }
        if (this.state.shipTracking) {
            let shipTracking = this.state.shipTracking.toLocaleLowerCase();
            repairRequests = repairRequests.filter(request => {
                return request.TrackingNumberDetails.some(x => x.TrackingNumber.toLocaleLowerCase().indexOf(shipTracking) != -1);
            });
        }

        // sort
        switch (this.state.sort) {
            case 'requestedDateAsc':
                repairRequests.sort((a, b) => {
                    return new Date(a.RequestedDate) - new Date(b.RequestedDate);
                });
                break;
            case 'requestedDateDesc':
            default:
                repairRequests.sort((a, b) => {
                    return new Date(b.RequestedDate) - new Date(a.RequestedDate);
                });
                break;
        }
        repairRequests = repairRequests.map(request => {
            request.RepairDetails.sort((a, b) => {
                if (a.Category == b.Category) {
                    if (a.Model == b.Model) {
                        return a.SerialNumber < b.SerialNumber ? -1 : 1;
                    }
                    return a.Model < b.Model ? -1 : 1;
                }
                return a.Category < b.Category ? -1 : 1;
            })
            return request;
        })

        return repairRequests;
    }

    render() {
        let searchConditionItemStyle = { display: 'inline-block', paddingTop: 0, paddingRight: 0, paddingBottom: 0, marginBottom: 0 };
        let labelStyle = { marginRight: 10, fontWeight: 'bolder' };

        let errorMessage = '';
        if (this.props.Reducer.requestFetchingError || this.props.Reducer.logoutingError || this.props.Reducer.manualDownloadingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        let tableRows = [];
        this.filteringAndReordering().forEach(request => {
            let requestedDate = Functions.dateForm(request.RequestedDate);
            let shipTrackingNumber = <Table.Cell content={request.TrackingNumberDetails[0].TrackingNumber} />;
            if (request.TrackingNumberDetails.length > 1) {
                shipTrackingNumber = <Popup trigger={<Table.Cell content={request.TrackingNumberDetails[0].TrackingNumber + ', ...'} />}>
                    <Popup.Header>All ship tracking #</Popup.Header>
                    <Popup.Content>
                        <ol class='weight-shipTrackingNumber'>
                            {request.TrackingNumberDetails.map(x => <li>{x.TrackingNumber}</li>)}
                        </ol>
                    </Popup.Content>
                </Popup>;
            }
            request.RepairDetails.forEach(detail => {
                tableRows.push(
                    <Table.Row>
                        <Table.Cell content={request.Id} />
                        <Table.Cell content={requestedDate} />
                        <Table.Cell content={request.ContactName} />
                        <Table.Cell content={detail.Category} />
                        <Table.Cell content={detail.Model} />
                        <Table.Cell content={detail.SerialNumber} />
                        <Table.Cell>
                            <pre>{detail.ProblemDescription}</pre>
                        </Table.Cell>
                        {shipTrackingNumber}
                        <Table.Cell>
                            <Button icon color='blue' onClick={this.onModalButtonClick} id={request.Id}>
                                <Icon.Group>
                                    <Icon name='file alternate outline' />
                                    <Icon corner name='search' style={{ color: 'black' }} />
                                </Icon.Group>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                );
            });
        });

        return (
            <>
                <Header as='h2' icon='box' content='Shipping History' />
                {errorMessage}
                <Segment>
                    <Header content='Search Condition' icon='filter' />
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: 'auto' }}>
                            <Segment style={{ paddingLeft: 0, ...searchConditionItemStyle }} basic>
                                <Button icon='x' color='red' onClick={this.onClearClick} />
                            </Segment>
                            <Segment style={searchConditionItemStyle} basic>
                                <label style={labelStyle}>Requested date</label>
                                <Input name='requestedDateFrom' type='date' value={this.state.requestedDateFrom} onChange={this.onSearchConditionChange} />
                                <span style={{ margin: 'auto 7px' }}>-</span>
                                <Input name='requestedDateTo' type='date' value={this.state.requestedDateTo} onChange={this.onSearchConditionChange} />
                            </Segment>
                            <Segment style={searchConditionItemStyle} basic>
                                <label style={labelStyle}>Model / Serial #</label>
                                <Input name='modelSerial' onChange={this.onSearchConditionChange} value={this.state.modelSerial} />
                            </Segment>
                            <Segment style={searchConditionItemStyle} basic>
                                <label style={labelStyle}>Ship tracking #</label>
                                <Input name='shipTracking' onChange={this.onSearchConditionChange} value={this.state.shipTracking} />
                            </Segment>
                        </div>
                    </div>
                </Segment>
                <Pagination firstItem={null} lastItem={null} style={{ marginRight: 10 }} activePage={this.state.currentPage}
                    totalPages={Math.ceil(tableRows.length / 30)} onPageChange={this.onPageChange} />
                <Dropdown selection options={this.state.sortOptions} value={this.state.sort} onChange={this.onSortChange} />
                <div style={{ overflowX: 'scroll', marginTop: 10 }}>
                    <Table striped celled style={{ whiteSpace: 'nowarp' }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content='No.' />
                                <Table.HeaderCell content='Requested date' />
                                <Table.HeaderCell content='Contact name' />
                                <Table.HeaderCell content='Category' />
                                <Table.HeaderCell content='Model' />
                                <Table.HeaderCell content='Serial #' />
                                <Table.HeaderCell content='Problem description' />
                                <Table.HeaderCell content='Ship tracking #' />
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableRows.slice((this.state.currentPage - 1) * 30, this.state.currentPage * 30)}
                        </Table.Body>
                    </Table>
                </div>
                {this.state.openModal ? <HistoryDetail onModalButtonClick={this.onModalButtonClick} id={this.state.selectedRepairRequestId} /> : ''}
                <Dimmer active={this.props.Reducer.requestFetching} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
