import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js'
import { Segment, Header, Icon, Button, Dimmer, Loader, Tab } from 'semantic-ui-react';
import * as Messages from '../../Resources/Messages';
import UsersTab from './UsersTab';
import AddressBookTab from './AddressBookTab.js';
import EndUserTab from './EndUserTab.js';

export class UserSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActiveIndex: 0,
            hasChanged: false,
        };

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onTabIndexChange = this.onTabIndexChange.bind(this);
        this.setHasChanged = this.setHasChanged.bind(this);
    }

    componentDidMount() {
        this.props.Action.userMasterFetch(this.props.Reducer.csrftoken);
    }

    componentDidUpdate(prevProps, prevState) {
        // The function is execute when the user clicks the "Manual Download" button
        if (!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading) {
            this.props.Action.masterDataFetchingError(false);
        }
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization();
    }

    loadingErrorFlagInitialization() {
        this.props.Action.masterDataFetchingError(false);
        this.props.Action.userMasterFetchError(false);
        this.props.Action.logoutFetchingError(false);
        this.props.Action.manualDownloadingError(false);
    }

    onTabIndexChange(evn, data) {
        if (!this.state.hasChanged || window.confirm(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_ADDUSER)) {
            this.setState({
                tabActiveIndex: data.activeIndex,
                hasChanged: false,
            });
            // this.loadingErrorFlagInitialization();
        }
    }

    setHasChanged(bool) {
        this.setState({ hasChanged: bool });
    }

    render() {
        let marginRight = { marginRight: 20, display: 'inline-block', verticalAlign: 'top' };

        let userInformation = this.props.Reducer.userInformation;

        const panes = [
            { menuItem: 'Users', render: () => <UsersTab setHasChanged={this.setHasChanged} /> }
        ];
        if (!this.props.Reducer.userInformation.RepairCenterFlag) {
            panes.push({ menuItem: 'Address book', render: () => <AddressBookTab /> });
            panes.push({ menuItem: 'End-user', render: () => <EndUserTab /> });
        }

        return (
            <>
                <Header as='h2' >
                    <Icon name='setting' />
                    <Header.Content>
                        User Setting
                    </Header.Content>
                </Header>
                <Segment>
                    <Header icon='building' content='Customer information' dividing />
                    <dl>
                        <div style={marginRight}>
                            <dt>Customer #</dt>
                            <dd>{userInformation.CustomerNumber}</dd>
                        </div>
                        <div style={marginRight}>
                            <dt>Customer name</dt>
                            <dd>{userInformation.CustomerName}</dd>
                        </div>
                        {this.props.Reducer.userInformation.RepairCenterFlag ? null : <>
                            <div style={marginRight}>
                                <dt>Address</dt>
                                <dd>{userInformation.AddressLineOne ? userInformation.AddressLineOne : '-'}</dd>
                                <dd>{userInformation.AddressLineTwo ? userInformation.AddressLineTwo : '-'}</dd>
                                <dd>{userInformation.AddressLineThree ? userInformation.AddressLineThree : '-'}</dd>
                                <dd>{userInformation.City}, {userInformation.State} {userInformation.Zip}</dd>
                                <dd>{userInformation.Country}</dd>
                            </div>
                            <div style={marginRight}>
                                <dt>Telephone</dt>
                                <dd>{userInformation.Telephone}</dd>
                            </div>
                            <div style={marginRight}>
                                <dt>Fax</dt>
                                <dd>{userInformation.Fax}</dd>
                            </div>
                        </>
                        }
                    </dl>
                </Segment>
                <Tab panes={panes} renderActiveOnly activeIndex={this.state.tabActiveIndex} onTabChange={this.onTabIndexChange} />

                <Dimmer active={this.props.Reducer.userMasterFetching || this.props.Reducer.userSettingUpdating} page>
                    <Loader content="Loading..." size="huge"></Loader>
                </Dimmer>
                <Dimmer active={this.props.Reducer.isChangeOwnUserId} page>
                    <div className='flex-column'>
                        <Header icon inverted>
                            <Icon name='check' />
                            {Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_USERSETTING}
                            <Header.Subheader>{Messages.REPAIRREQUEST_MSG_USERID_NOTMATCH}</Header.Subheader>
                        </Header>
                        <div style={{ display: 'flex' }}>
                            <Button content='Back to Login' icon='sign-in' basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }} onClick={() => window.location.reload()} />
                        </div>
                    </div>
                </Dimmer>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSetting);
