import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Button, Segment, Modal, Table, Grid, Dimmer, Loader } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';
import Functions from '../../../Functions.js';
import ErrorMessage from '../../ErrorMessage.js';

class ProtectionPlanConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.onRegisterClick = this.onRegisterClick.bind(this);
    }

    componentWillUnmount() {
        this.props.Action.protectionPlanSavingError(false);
    }

    onRegisterClick() {
        document.querySelector('#confirmModal').scrollTo(0, 0);
        // DB通信を行う
        this.props.Action.saveProtectionPlan(
            this.props.Reducer.csrftoken,
            this.props.header,
            this.props.invoices,
            this.props.isSaveEndUser,
        );
    }

    render() {
        const marginRightStyle = { marginRight: 20, display: 'inline-block' };

        let errorMessage = null;
        if (this.props.Reducer.protectionPlanSavingError) {
            errorMessage = <ErrorMessage page active content={this.props.Reducer.errorMessage} />;
        }

        const models = this.props.invoices.map(x => x.model)
        const modelSet = new Set(models);
        const uniqueModelList = [...modelSet.values()];

        const invoiceRows =
            uniqueModelList.map(model => {
                const qty = this.props.invoices.filter(x => x.model == model).length;
                return <Table.Row>
                    <Table.Cell>{model}</Table.Cell>
                    <Table.Cell>{qty}</Table.Cell>
                </Table.Row>
            })

        //Estimated priceの表示
        let planIds = this.props.invoices.map(x => x.planId)
        //選択したプランがない(null)場合は表示しない
        planIds = planIds.filter(x => x !== null);
        const planIdSet = new Set(planIds);
        const uniquePlanIdList = [...planIdSet.values()];

        let total = 0;

        const estimatedPriceRows =
            uniquePlanIdList.map(planId => {
                const selectedPlan = this.props.Reducer.repairServicePlanMaster.find(x => x.Id == planId);
                const price = selectedPlan.getPrice(this.props.header.purchaseDate);
                const qty = this.props.invoices.filter(x => x.planId == planId).length;
                const subtotal = Functions.decimalMultiplication(price, qty)
                total = Functions.decimalAddition(total, Number(subtotal))

                return <Table.Row>
                    <Table.Cell>{selectedPlan.PlanName}</Table.Cell>
                    <Table.Cell>${Functions.toNumberToLocaleString(price, 2)}</Table.Cell>
                    <Table.Cell>{qty}</Table.Cell>
                    <Table.Cell>${Functions.toNumberToLocaleString(subtotal, 2)}</Table.Cell>
                </Table.Row>
            })

        return (
            <Modal open={this.props.modalOpen} size='fullscreen' id='fullscreenModal'>
                <Modal.Header>
                    <Header content='Please confirm and press register button' />
                </Modal.Header>
                <Modal.Content scrolling style={{ maxHeight: '70vh' }} id='confirmModal'>
                    {errorMessage}
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment>
                                    <Header icon='user' content='End-user information' dividing />
                                    <dl>
                                        <div style={marginRightStyle}>
                                            <dt>End-user name</dt>
                                            <dd>{this.props.header.endUserName}</dd>
                                        </div>
                                        <div style={marginRightStyle}>
                                            <dt>Contact name</dt>
                                            <dd>{this.props.header.contactName}</dd>
                                        </div>
                                        <div style={marginRightStyle}>
                                            <dt>Email</dt>
                                            <dd>{this.props.header.email}</dd>
                                        </div>
                                    </dl>
                                    <dl style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div style={marginRightStyle}>
                                            <dt>Address</dt>
                                            <dd>{this.props.header.addressLineOne}</dd>
                                            <dd>{this.props.header.addressLineTwo ? this.props.header.addressLineTwo : '-'}</dd>
                                            <dd>{this.props.header.addressLineThree ? this.props.header.addressLineThree : '-'}</dd>
                                            <dd>{this.props.header.city}, {this.props.header.state} {this.props.header.zip}</dd>
                                            <dd>{this.props.header.country}</dd>
                                            <div style={marginRightStyle}>
                                                <dt>Telephone</dt>
                                                <dd>{this.props.header.telephone}</dd>
                                            </div>
                                            <div style={marginRightStyle}>
                                                <dt>Fax</dt>
                                                <dd>{this.props.header.fax ? this.props.header.fax : '-'}</dd>
                                            </div>
                                        </div>
                                    </dl>
                                    <dl>
                                        <dt>Note</dt>
                                        <dd>
                                            <pre style={{ whiteSpace: 'pre-line', marginTop: 0, marginBottom: 0 }}>
                                                {this.props.header.note ? this.props.header.note : '-'}
                                            </pre>
                                        </dd>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment style={{ height: '100%' }}>
                                    <Header icon='cart' content='Purchase information' dividing />
                                    <dl>
                                        <div style={marginRightStyle}>
                                            <dt>PO</dt>
                                            <dd>{this.props.header.po}</dd>
                                        </div>
                                        <div style={marginRightStyle}>
                                            <dt>Purchase date</dt>
                                            <dd>{Functions.dateForm(this.props.header.purchaseDate)}</dd>
                                        </div>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header icon='file text' content='Invoice information' dividing />
                                    <Table striped celled>
                                        <Table.Header >
                                            <Table.Row>
                                                <Table.HeaderCell content='Model' />
                                                <Table.HeaderCell content='Quantity' />
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {invoiceRows}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header icon='dollar' content='Estimated price' dividing />
                                    <Table striped celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell content='Plan name' />
                                                <Table.HeaderCell content='Price' />
                                                <Table.HeaderCell content='Quantity' />
                                                <Table.HeaderCell content='Subtotal' />
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {estimatedPriceRows}
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='4' style={{ textAlign: 'right' }}>
                                                    <b>Total (tax not included)</b> : ${Functions.toNumberToLocaleString(total, 2)}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={this.props.onCloseClick} />
                    <Button content="Register" color='blue' onClick={this.onRegisterClick} />
                </Modal.Actions>
                <Dimmer active={this.props.Reducer.protectionPlanSaving}>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectionPlanConfirm);
