import AppConfig from '../AppConfig';
import * as Constant from '../Constants/Constant';
import * as Messages from '../Resources/Messages';
import { saveAs } from 'file-saver';
import moment from 'moment';

// SessionCheck
// #region
export function sessionCheck() {
    return (dispatch) => {
        fetch(AppConfig.ApiUrlLogin, { mode: 'cors', credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                if (response.userInformation != null && response.csrfToken != null) {
                    dispatch(setUserInformation(response.userInformation));
                    dispatch(serCsrftoken(response.csrfToken));
                    if (response.userInformation.repairCenterFlag) {
                        dispatch(setTabStatus(Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY));
                    } else {
                        dispatch(setTabStatus(Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT));
                    }
                }
                dispatch(sessionChecking(false));
            })
            .catch(() => {
                dispatch(sessionChecking(false));
            });
    };
}
export function sessionChecking(bool) {
    return {
        type: Constant.ROOT_ACT_SESSIONCHECK_FETCHING,
        bool: bool,
    };
}
// #endregion

// LoginCheck
// #region
export function LoginCheck(userId, password) {
    return (dispatch) => {
        dispatch(loginCheckingError(false));
        dispatch(loginChecking(true));
        fetch(AppConfig.ApiUrlLogin, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, password })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(loginChecking(false));
                return response;
            })
            .then(response => response.json())
            .then(response => {
                if (response.userInformation != null && response.csrfToken != null) {
                    dispatch(setUserInformation(response.userInformation));
                    dispatch(serCsrftoken(response.csrfToken));
                    if (response.userInformation.repairCenterFlag) {
                        dispatch(setTabStatus(Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY));
                    } else {
                        dispatch(setTabStatus(Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT));
                    }
                } else {
                    dispatch(loginCheckingError(true, Messages.REPAIRREQUEST_MSG_ERROROCCURREDATDB));
                }
            })
            .catch(e => {
                dispatch(loginCheckingError(true, e.message));
                dispatch(loginChecking(false));
            });
    };
}
export function loginCheckingError(bool, message = '') {
    return {
        type: Constant.LOGIN_ACT_LOGINCHECK_FETCHINGERROR,
        bool: bool,
        message: message,
    };
}
export function loginChecking(bool) {
    return {
        type: Constant.LOGIN_ACT_LOGINCHECK_FETCHING,
        bool: bool
    };
}
// #endregion

// Login - InformationFetch
// #region
export function informationFetch() {
    return (dispatch) => {
        dispatch(informationFetchingError(false));
        dispatch(informationFetching(true));
        fetch(AppConfig.ApiUrlLogin + '/information', { mode: 'cors', credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(informationFetching(false));
                return response;
            })
            .then(response => response.json())
            .then(information => {
                dispatch(informationFetchSuccess(information))
            })
            .catch(() => {
                dispatch(informationFetchingError(true));
                dispatch(informationFetching(false));
            });
    };
}
export function informationFetchingError(bool) {
    return {
        type: Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHINGERROR,
        bool: bool,
    };
}
export function informationFetching(bool) {
    return {
        type: Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHING,
        bool: bool
    };
}
export function informationFetchSuccess(information) {
    if (!information) {
        information = {};
    }
    return {
        type: Constant.LOGIN_ACT_INFORMATIONFETCH_SUCCESS,
        information: information,
    };
}
// #endregion

// Shipping History
// #region
// partialFlag-> True: 最新100件の更新、False: 全件取得
export function requestFetch(csrftoken, partialFlag) {
    return (dispatch) => {
        dispatch(requestFetchingError(false));
        dispatch(requestFetching(true));
        fetch(AppConfig.ApiUrlHistory + (partialFlag ? '/partial' : ''), {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(requestFetching(false));
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(requestFetchSuccess(response))
            })
            .catch((e) => {
                dispatch(requestFetchingError(true, e.message));
                dispatch(requestFetching(false));
            });
    };
}
export function requestFetchingError(bool, message = '') {
    return {
        type: Constant.HISTORY_ACT_REQUESTFETCH_LOADINGERROR,
        bool: bool,
        message: message,
    };
}
export function requestFetching(bool) {
    return {
        type: Constant.HISTORY_ACT_REQUESTFETCH_LOADING,
        bool: bool
    };
}
export function requestFetchSuccess(response) {
    return {
        type: Constant.HISTORY_ACT_REQUESTFETCH_SUCCESS,
        repairRequestHeaders: response.headers,
        repairRequestDetails: response.details,
        trackingNumberDetails: response.trackingNumberDetails,
    };
}
// #endregion

// Reuest - master fetch
// #region
// ShipTo,Category,WarrantyMaster,CustomerAddressBookデータを取得
export function masterDataFetch(csrftoken) {
    return (dispatch) => {
        dispatch(masterDataFetchingError(false));
        dispatch(masterDataFetching(true));
        fetch(AppConfig.ApiUrlRequest, {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(masterDataFetchingSuccess(response));
                dispatch(setCustomerAddressBook(response.customerAddressBook));
                dispatch(setRepairServicePlanMaster(response.repairServicePlanMaster, response.repairServicePlanPrices));
                dispatch(setModelRuleMaster(response.modelRuleMaster));
                dispatch(setModelRulesForRepairServicePlanCoverage(response.coverages));
                dispatch(masterDataFetching(false));
            })
            .catch(e => {
                dispatch(masterDataFetchingError(true, e.message));
                dispatch(masterDataFetching(false));
            });
    };
}
export function masterDataFetchingError(bool, message = '') {
    return {
        type: Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function masterDataFetching(bool) {
    return {
        type: Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHING,
        bool: bool,
    }
}
export function masterDataFetchingSuccess(response) {
    return {
        type: Constant.REQUEST_ACT_MASTERDATAFETCH_SUCCESS,
        ...response,
    }
}
//#endregion

// Request - submit
// #region
export function submitRequest(csrftoken, request, isSaveAddressBook) {
    return (dispatch) => {
        dispatch(requestSubmittingError(false));
        dispatch(requestSubmitting(true));
        fetch(AppConfig.ApiUrlRequest + '/submitRequest', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ request, isSaveAddressBook }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                dispatch(requestSubmitting(false));
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(requestSubmitSuccess(response));
            })
            .catch(e => {
                dispatch(requestSubmittingError(true, e.message));
                dispatch(requestSubmitting(false));
            });
    };
}
export function requestSubmittingError(bool, message = '') {
    return {
        type: Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function requestSubmitting(bool) {
    return {
        type: Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHING,
        bool: bool,
    }
}
export function requestSubmitSuccess(response) {
    return {
        type: Constant.REQUEST_ACT_REQUESTSUBMIT_SUCCESS,
        id: response.id,
        trackingNumberDetails: response.trackingNumberDetails,
    }
}
export function completeRequestModal(bool) {
    return {
        type: Constant.REQUEST_STAT_COMPLETEMODAL,
        bool,
    }
}
// #endregion

// Log out
// #region
export function logout() {
    return (dispatch) => {
        dispatch(logoutFetchingError(false));
        dispatch(logoutFetching(true));
        fetch(AppConfig.ApiUrlLogin + '/logout', {
            mode: 'cors', method: 'delete', credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                dispatch(logoutFetching(false));
                dispatch(logoutSuccess());
            })
            .catch(e => {
                dispatch(logoutFetchingError(true, e.message));
                dispatch(logoutFetching(false));
            });
    };
}
export function logoutFetchingError(bool, message = '') {
    return {
        type: Constant.ROOT_ACT_LOGOUT_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function logoutFetching(bool) {
    return {
        type: Constant.ROOT_ACT_LOGOUT_FETCHING,
        bool: bool,
    }
}
export function logoutSuccess() {
    return {
        type: Constant.ROOT_ACT_LOGOUT_SUCCESS,
    };
}
// #endregion

// User Setting
//#region
export function userMasterFetch(csrftoken) {
    return (dispatch) => {
        dispatch(userMasterFetchError(false));
        dispatch(userMasterFetching(true));
        fetch(AppConfig.ApiUrlUserSetting, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(userMasterFetchingSuccess(response.userMaster));
                dispatch(setCustomerAddressBook(response.customerAddressBook));
                dispatch(setCustomerEndUsers(response.customerEndUsers));
                dispatch(userMasterFetching(false));
            })
            .catch(e => {
                dispatch(userMasterFetchError(true, e.message));
                dispatch(userMasterFetching(false));
            });
    };
}
export function userMasterFetchError(bool, message = '') {
    return {
        type: Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function userMasterFetching(bool) {
    return {
        type: Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHING,
        bool: bool,
    }
}
export function userMasterFetchingSuccess(userMaster) {
    return {
        type: Constant.USERSETTING_ACT_USERMASTERFETCH_SUCCESS,
        userMaster: userMaster,
    };
}

export function updateUserSetting(userList, csrftoken, isChangeOwnUserId) {
    return (dispatch) => {
        dispatch(userSettingUpdatingError(false));
        dispatch(userSettingUpdating(true));
        fetch(AppConfig.ApiUrlUserSetting + '/update', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify(userList),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(updateUserSettingSuccess(response.returnUserMaster, isChangeOwnUserId));
                if (!isChangeOwnUserId) {
                    dispatch(setUserInformation(response.userInformation));
                }
                dispatch(userSettingUpdating(false));
            })
            .catch(e => {
                dispatch(userSettingUpdatingError(true, e.message));
                dispatch(userSettingUpdating(false));
            });
    };
}
export function userSettingUpdatingError(bool, message = '') {
    return {
        type: Constant.USERSETTING_ACT_UPDATE_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function userSettingUpdating(bool) {
    return {
        type: Constant.USERSETTING_ACT_UPDATE_FETCHING,
        bool: bool,
    }
}
export function updateUserSettingSuccess(userMaster, isChangeOwnUserId) {
    return {
        type: Constant.USERSETTING_ACT_UPDATE_SUCCESS,
        userMaster: userMaster,
        isChangeOwnUserId: isChangeOwnUserId,
    };
}

export function addCustomerUser(userId, userName, csrftoken) {
    return (dispatch) => {
        dispatch(customerUserAddingError(false));
        dispatch(customerUserAdding(true));
        fetch(AppConfig.ApiUrlUserSetting + '/insert', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ userId, userName }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(addCustomerUserSuccess(response));
                dispatch(customerUserAdding(false));
            })
            .catch(e => {
                dispatch(customerUserAddingError(true, e.message));
                dispatch(customerUserAdding(false));
            });
    };
}
export function customerUserAddingError(bool, message = '') {
    return {
        type: Constant.USERSETTING_ACT_INSERT_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function customerUserAdding(bool) {
    return {
        type: Constant.USERSETTING_ACT_INSERT_FETCHING,
        bool: bool,
    }
}
export function addCustomerUserSuccess(userMaster) {
    return {
        type: Constant.USERSETTING_ACT_INSERT_SUCCESS,
        userMaster: userMaster,
    };
}
// #endregion

// File download (Shipping label / Packing slip)
//#region
export function fileDownload(csrftoken, labelType, repairRequestHeaderId) {
    return (dispatch) => {
        dispatch(fileDownloadingError(false));
        dispatch(fileDownloading(true));
        fetch(AppConfig.ApiUrlFileDownload + '/' + labelType + '/?id=' + repairRequestHeaderId, {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(fileDownloading(false));
                return response;
            })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, labelType + '_' + moment(Date()).format('MMDDYYYY'));
            })
            .catch(e => {
                dispatch(fileDownloadingError(true, e.message));
                dispatch(fileDownloading(false));
            });
    };
}
export function fileDownloading(bool) {
    return {
        type: Constant.FILEDOWNLOAD_ACT_DOWNLOADING,
        bool,
    };
}
export function fileDownloadingError(bool, message = '') {
    return {
        type: Constant.FILEDOWNLOAD_ACT_DOWNLOADINGERROR,
        bool,
        message,
    };
}
//#endregion

// File download (Manual)
//#region
export function manualDownload(csrftoken) {
    return (dispatch) => {
        dispatch(manualDownloadingError(false));
        dispatch(manualDownloading(true));
        fetch(AppConfig.ApiUrlFileDownload + '/manual', {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, Constant.FILEDOWNLOAD_NAME_MANUAL);
            })
            .catch(e => {
                dispatch(manualDownloadingError(true, e.message));
            })
            .finally(() => {
                dispatch(manualDownloading(false));
            });
    };
}
export function manualDownloading(bool) {
    return {
        type: Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADING,
        bool,
    };
}
export function manualDownloadingError(bool, message = '') {
    return {
        type: Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADINGERROR,
        bool,
        message,
    };
}
//#endregion

// Get CustomerAddressBook
//#region
export function customerAddressBookFetch(csrftoken) {
    return (dispatch) => {
        dispatch(customerAddressBookFetchingError(false));
        dispatch(customerAddressBookFetching(true));
        fetch(AppConfig.ApiUrlRequest + '/customerAddressBook', {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(customerAddressBook => {
                dispatch(setCustomerAddressBook(customerAddressBook));
                dispatch(customerAddressBookFetching(false));
            })
            .catch(e => {
                dispatch(customerAddressBookFetchingError(true, e.message));
                dispatch(customerAddressBookFetching(false));
            });
    };
}
export function customerAddressBookFetchingError(bool, message = '') {
    return {
        type: Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHINGERROR,
        bool: bool,
        message: message,
    };
}
export function customerAddressBookFetching(bool) {
    return {
        type: Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHING,
        bool: bool,
    };
}
//#endregion

// Protection plan - fetch protection plan data list
//#region
export function fetchProtectionPlanList(csrftoken, isAllItem) {
    const uri = AppConfig.ApiUrlProtectionPlan + (isAllItem ? '' : '/partial');
    return (dispatch) => {
        dispatch(protectionPlanListFetchingError(false));
        dispatch(protectionPlanListFetching(true));
        fetch(uri, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(updateProtectionPlan(response.protectionPlanHeaders, response.protectionPlanDetails));
                dispatch(protectionPlanListFetching(false));
            })
            .catch(e => {
                dispatch(protectionPlanListFetchingError(true, e.message));
                dispatch(protectionPlanListFetching(false));
            });
    };
}
export function protectionPlanListFetchingError(bool, message = '') {
    return {
        type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function protectionPlanListFetching(bool) {
    return {
        type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHING,
        bool: bool,
    }
}
//#endregion

// Protectoin plan - fetch protection plan data by headerId
//#region
export function fetchProtectionPlan(csrftoken, headerId) {
    return (dispatch) => {
        dispatch(protectionPlanFetchingError(false));
        dispatch(protectionPlanFetching(true));
        fetch(AppConfig.ApiUrlProtectionPlan + '/' + headerId, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                dispatch(updateProtectionPlan([response.protectionPlanHeader], response.protectionPlanDetails));
                dispatch(protectionPlanFetching(false));
            })
            .catch(e => {
                dispatch(protectionPlanFetchingError(true, e.message));
                dispatch(protectionPlanFetching(false));
            });
    };
}
export function protectionPlanFetchingError(bool, message = '') {
    return {
        type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function protectionPlanFetching(bool) {
    return {
        type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHING,
        bool: bool,
    }
}
//#endregion

// Protection plan - fetch End-user information
//#region
export function fetchCustomerEndUsers(csrftoken) {
    return (dispatch) => {
        dispatch(customerEndUsersFetchingError(false));
        dispatch(customerEndUsersFetching(true));
        fetch(AppConfig.ApiUrlProtectionPlan + "/EndUsers", {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(customerEndUsers => {
                dispatch(setCustomerEndUsers(customerEndUsers));
                dispatch(customerEndUsersFetching(false));
            })
            .catch(e => {
                dispatch(customerEndUsersFetchingError(true, e.message));
                dispatch(customerEndUsersFetching(false));
            });
    };
}
export function customerEndUsersFetchingError(bool, message = '') {
    return {
        type: Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHINGERROR,
        bool,
        message,
    }
}
export function customerEndUsersFetching(bool) {
    return {
        type: Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHING,
        bool,
    }
}
//#endregion

// Protection plan - fetch invoices
//#region
export function fetchInvoices(csrftoken, invoiceNumber) {
    const uri = AppConfig.ApiUrlProtectionPlan + "/Invoices/" + encodeURIComponent(invoiceNumber);
    return (dispatch) => {
        dispatch(invoiceFetchingError(false));
        dispatch(invoiceFetching(true));
        fetch(uri, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(invoices => {
                if (invoices.length === 0) {
                    throw new Error('This invoice has already been registered for other protection plans. Please enter a different invoice # to search.');
                }
                dispatch(setTempInvoiceMaster(invoices));
                dispatch(invoiceFetching(false));
            })
            .catch(e => {
                dispatch(invoiceFetchingError(true, e.message));
                dispatch(invoiceFetching(false));
            });
    };
}
export function invoiceFetchingError(bool, message = '') {
    return {
        type: Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHINGERROR,
        bool: bool,
        message: message,
    }
}
export function invoiceFetching(bool) {
    return {
        type: Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHING,
        bool: bool,
    }
}
//#endregion

// Protection plan - save
// #region
export function saveProtectionPlan(csrftoken, header, details, isSaveEndUser) {
    return (dispatch) => {
        dispatch(protectionPlanSavingError(false));
        dispatch(protectionPlanSaving(true));
        fetch(AppConfig.ApiUrlProtectionPlan, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ header, details, isSaveEndUser }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                dispatch(protectionPlanSaving(false));
            })
            .catch(e => {
                dispatch(protectionPlanSavingError(true, e.message));
                dispatch(protectionPlanSaving(false));
            });
    };
}
export function protectionPlanSavingError(bool, message = '') {
    return {
        type: Constant.PROTECTIONPLAN_ACT_SAVINGERROR,
        bool: bool,
        message: message,
    }
}
export function protectionPlanSaving(bool) {
    return {
        type: Constant.PROTECTIONPLAN_ACT_SAVING,
        bool: bool,
    }
}
// #endregion

// set state
export function setUserInformation(userInformation) {
    return {
        type: Constant.LOGIN_ACT_SET_USERINFORMATION,
        userInformation: userInformation,
    };
}
export function serCsrftoken(csrftoken) {
    return {
        type: Constant.LOGIN_ACT_SET_CSRFTOKEN,
        csrftoken: csrftoken,
    };
}
export function setDisclaimer(isDisclaimerOpen) {
    return {
        type: Constant.REQUEST_ACT_SET_DISCLAIMER,
        isDisclaimerOpen: isDisclaimerOpen,
    }
}
export function setCustomerAddressBook(customerAddressBook) {
    return {
        type: Constant.REQUEST_ACT_SET_CUSTOMERADDRESSBOOK,
        customerAddressBook,
    };
}
export function setTempInvoiceMaster(invoices) {
    return {
        type: Constant.PROTECTOPNPLAN_ACT_SET_TEMPINVOICEMASTER,
        invoices,
    };
}
export function updateProtectionPlan(headers, details) {
    return {
        type: Constant.PROTECTOPNPLAN_ACT_UPDATE_PROTECTIONPLAN,
        headers,
        details,
    };
}
export function setCustomerEndUsers(customerEndUsers) {
    return {
        type: Constant.USERSETTING_ACT_SET_CUSTOMERENDUSERS,
        customerEndUsers,
    };
}
export function setRepairServicePlanMaster(repairServicePlanMaster, repairServicePlanPrices) {
    return {
        type: Constant.PROTECTOPNPLAN_ACT_SET_REPAIRSERVICEPLANMASTER,
        repairServicePlanMaster,
        repairServicePlanPrices,
    };
}
export function setModelRuleMaster(modelRuleMaster) {
    return {
        type: Constant.PROTECTOPNPLAN_ACT_SET_MODELRULEMASTER,
        modelRuleMaster,
    };
}
export function setModelRulesForRepairServicePlanCoverage(coverages) {
    return {
        type: Constant.PROTECTOPNPLAN_ACT_SET_MODELRULESFORREPAIRSERVICEPLANCOVERAGE,
        coverages,
    };
}

// set tabStatus
export function setTabStatus(tabStatus) {
    return {
        type: Constant.REPAIRREQUEST_ACT_SET_TABSTATUS,
        tabStatus,
    };
}