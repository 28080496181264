import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import { Button, Modal, Form, Dimmer, Loader } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import * as Messages from '../../Resources/Messages';
import CustomerAddressBook from '../../Models/ViewModels/CustomerAddressBook.js';
import ErrorMessage from '../ErrorMessage.js';

export class AddressEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
            telephone: '',
            fax: '',

            validationErrors: {},
            loading: false,
            loadingError: false,
            errorMessage: '',
        };

        this.addressInsert = this.addressInsert.bind(this);
        this.addressUpdate = this.addressUpdate.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.createSaveData = this.createSaveData.bind(this);
        this.validationCheck = this.validationCheck.bind(this);
    }

    componentDidMount() {
        if (this.props.edit) {
            const addressBook = this.props.Reducer.customerAddressBook.find(x => x.Id == this.props.id);
            this.setState({
                addressLine1: addressBook.AddressLineOne,
                addressLine2: addressBook.AddressLineTwo,
                addressLine3: addressBook.AddressLineThree,
                city: addressBook.City,
                state: addressBook.State,
                zip: addressBook.Zip,
                country: addressBook.Country,
                telephone: addressBook.Telephone,
                fax: addressBook.Fax,
            });
        }
    }

    // fetch
    //#region
    addressInsert(saveData) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                this.props.Action.setCustomerAddressBook(customerAddressBook);
                this.setState({ loading: false });
                this.props.setSuccessFlag(true, false);
                this.props.onModalClose();
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    addressUpdate(saveData) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook', {
            mode: 'cors', method: 'put', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                this.props.Action.setCustomerAddressBook(customerAddressBook);
                this.setState({ loading: false });
                this.props.setSuccessFlag(false, true);
                this.props.onModalClose();
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }
    //#endregion

    onValueChange(evn, data) {
        this.setState({ [data.stateName]: data.value });
    }

    onSubmitClick() {
        let saveData = this.createSaveData();
        let validationErrors = this.validationCheck(saveData);
        this.setState({
            ...saveData,
            validationErrors,
            loadingError: false,
            errorMessage: '',
        });

        if (!Object.keys(validationErrors).length) {
            if (this.props.new) {
                this.addressInsert(saveData);
            } else if (this.props.edit) {
                this.addressUpdate(saveData);
            }
        }
    }

    createSaveData() {
        return {
            id: this.props.id,
            addressLine1: this.state.addressLine1.trim(),
            addressLine2: this.state.addressLine2.trim(),
            addressLine3: this.state.addressLine3.trim(),
            city: this.state.city.trim(),
            state: this.state.state.trim(),
            zip: this.state.zip.trim(),
            country: this.state.country.trim(),
            telephone: this.state.telephone.trim(),
            fax: this.state.fax.trim(),
        };
    }

    validationCheck(saveData) {
        let errors = {};

        if (!saveData.addressLine1) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (saveData.addressLine1.length > 100 || saveData.addressLine2?.length > 100 || saveData.addressLine3?.length > 100) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (!saveData.city) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (saveData.city.length > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (!saveData.state) {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (!saveData.zip) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (saveData.zip.length > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (!saveData.telephone) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (saveData.telephone.length > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (saveData.fax.length > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }

        if (Object.keys(errors).length == 0) {
            const userInformation = this.props.Reducer.userInformation;
            // CustomerMasterの住所と一致するかをチェック
            if (
                userInformation.AddressLineOne == saveData.addressLine1
                && userInformation.AddressLineTwo == saveData.addressLine2
                && userInformation.AddressLineThree == saveData.addressLine3
                && userInformation.City == saveData.city
                && userInformation.State == saveData.state
                && userInformation.Zip == saveData.zip
                && userInformation.Country == saveData.country
                && userInformation.Telephone == saveData.telephone
                && userInformation.Fax == saveData.fax
            ) {
                errors.duplicate = Messages.REPAIRREQUEST_MSG_VALIDATION_ADDRESS_DUPLICATE;
            }
            // AddressBookの住所と一致するかをチェック
            else if (this.props.Reducer.customerAddressBook.some(x => (
                x.Id != this.props.id
                && x.AddressLineOne == saveData.addressLine1
                && x.AddressLineTwo == saveData.addressLine2
                && x.AddressLineThree == saveData.addressLine3
                && x.City == saveData.city
                && x.State == saveData.state
                && x.Zip == saveData.zip
                && x.Country == saveData.country
                && x.Telephone == saveData.telephone
                && x.Fax == saveData.fax
            ))) {
                errors.duplicate = Messages.REPAIRREQUEST_MSG_VALIDATION_ADDRESS_DUPLICATE;
            }
        }

        return errors;
    }

    render() {
        let title, submitButtonLabel;
        if (this.props.new) {
            title = 'Add new address';
            submitButtonLabel = 'Add';
        } else if (this.props.edit) {
            title = 'Edit address';
            submitButtonLabel = 'Save';
        }

        let errorMessage;
        if (Object.keys(this.state.validationErrors).length) {
            let content = Object.values(this.state.validationErrors).map(x => <>{x}<br /></>);
            errorMessage = <ErrorMessage content={content} />;
        } else if (this.state.loadingError) {
            errorMessage = <ErrorMessage content={this.state.errorMessage} active />;
        }

        return (
            <Modal open={this.props.new || this.props.edit}>
                <Modal.Header content={title} />
                <Modal.Content>
                    {errorMessage}
                    <Form>
                        <Form.Input label='Address' required error={!!this.state.validationErrors.addressLine}
                            value={this.state.addressLine1} onChange={this.onValueChange} stateName='addressLine1' />
                        <Form.Input error={!!this.state.validationErrors.addressLine}
                            value={this.state.addressLine2} onChange={this.onValueChange} stateName='addressLine2' />
                        <Form.Input error={!!this.state.validationErrors.addressLine}
                            value={this.state.addressLine3} onChange={this.onValueChange} stateName='addressLine3' />
                        <Form.Group widths='equal'>
                            <Form.Input label='City' required error={!!this.state.validationErrors.city}
                                value={this.state.city} onChange={this.onValueChange} stateName='city' />
                            <Form.Dropdown label='State' required selection search placeholder='Select state'
                                error={!!this.state.validationErrors.state}
                                options={this.props.Reducer.stateProvinceCodeMaster.map(x => (
                                    { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` }
                                ))}
                                value={this.state.state} onChange={this.onValueChange} stateName='state' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input label='Zip/Postal code' required error={!!this.state.validationErrors.zip}
                                value={this.state.zip} onChange={this.onValueChange} stateName='zip' />
                            <Form.Input label='Country' value={this.state.country}>
                                <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                            </Form.Input>

                        </Form.Group>
                        <Form.Input label='Telephone' required error={!!this.state.validationErrors.telephone}
                            value={this.state.telephone} onChange={this.onValueChange} stateName='telephone' />
                        <Form.Input label='Fax' error={!!this.state.validationErrors.fax}
                            value={this.state.fax} onChange={this.onValueChange} stateName='fax' />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={submitButtonLabel} primary onClick={this.onSubmitClick} />
                    <Button content='Close' onClick={this.props.onModalClose} />
                </Modal.Actions>

                <Dimmer active={this.state.loading}>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressEditModal);
