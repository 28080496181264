import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../Root.js'
import { Header, Modal, Button } from 'semantic-ui-react';

export class Disclaimer extends React.Component {
    constructor(props) {
        super(props);

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onCloseClick() {
        this.props.Action.setDisclaimer(false);
    }

    render() {
        return (
            <Modal open={this.props.Reducer.isDisclaimerOpen}>
                <Modal.Header>
                    JVCKENWOOD USA CORPORATION<br />
                    Extended Services Portal
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>Terms of Service</Header>
                        <p>
                            By printing a label and shipping the returned product(s), you agree and acknowledge that JVCKENWOOD USA Corporation ("JKUSA") shall not be held responsible for any loss, damage and/or injury caused by, during and for the time period to and through delivery of the return shipment of such product(s). Further, you shall indemnify and hold JKUSA harmless for any act or omission by you, your company, its affiliates, employees or agents related to the same. All terms and conditions of JKUSA's applicable warranty statement remain in full force and effect.
                        </p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.Reducer.userInformation.FirstLogin
                        ? <Button content='Confirmed' icon='check' color='blue' onClick={this.onCloseClick} />
                        : <Button content="Close" onClick={this.onCloseClick} />}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Disclaimer);
