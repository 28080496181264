import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js'

import { Container, Segment, Form, Button, Message, Dimmer, Loader, Header, Icon, Popup, List } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

// Passwordのバリデーション用
// 入力可能文字：a-zA-Z0-9
// 入力可能記号：!#$%&'"()=-~^|@`[]{}:;/?_<>,.*+
const prohibitedCharacters = '!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+';
// 入力不可な文字を洗い出すための正規表現
const prohibitedCharactersRegExp = /[^a-zA-Z0-9!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+]/g;

export class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            showPassword: false,

            validationErrors: {},
            loading: false,
            loadingError: '',
            loadingSuccess: false,
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.toLoginScreen = this.toLoginScreen.bind(this);
        this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    onFormChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onSubmitClick() {
        let validationErrors = {};
        // 入力禁止の文字を洗い出す
        let prohibitedValues = this.state.newPassword.match(prohibitedCharactersRegExp) ?? [];
        if (!this.state.newPassword) {
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_NEWPASSWORD;
        } else if (this.state.newPassword.length < 8 || 16 < this.state.newPassword.length) {
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_PASSWORDVALIDATION;
        } else if (prohibitedValues.length != 0) {
            // 入力禁止文字を使用している場合。prohibitedValuesから重複した値を削除。
            let badValues = Array.from(new Set(prohibitedValues)).map(x => `"${x}"`);
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_PASSWORD_CONTAIN_UNAVAILABLE_CHARACTER + `(${badValues.join(', ')}).`;
        }
        if (!this.state.confirmNewPassword) {
            validationErrors.confirmNewPassword = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CONFIRMPASSWORD;
        } else if (this.state.newPassword != this.state.confirmNewPassword) {
            validationErrors.confirmNewPassword = Messages.REPAIRREQUEST_MSG_RESETPASSWORD_NOTMATCH;
        }
        this.setState({ validationErrors, loadingError: '' });
        if (!Object.keys(validationErrors).length) {
            this.resetPassword();
        }
    }

    resetPassword() {
        this.setState({ loading: true, loadingSuccess: false });
        fetch(AppConfig.ApiUrlLogin + '/resetPassword', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newPassword: this.state.newPassword, recepid: this.props.Reducer.recepid })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.setState({ loading: false, loadingSuccess: true });
            })
            .catch(e => {
                this.setState({ loading: false, loadingError: e.message });
            });
    }

    toLoginScreen() {
        let url = window.location.href;
        let newUrl = url.substring(0, url.indexOf("/?"));
        window.history.pushState("", "", newUrl);
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_LOGIN);
    }

    onShowPasswordClick() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    render() {
        let errorMessage = '';
        if (Object.keys(this.state.validationErrors).length) {
            let errors = Object.keys(this.state.validationErrors).map(x => <>{this.state.validationErrors[x]}<br /></>);
            errorMessage = <Message error>{errors}</Message>;
        } else if (this.state.loadingError) {
            errorMessage = <ErrorMessage content={this.state.loadingError} active page />;
        }

        let inputType = this.state.showPassword ? 'text' : 'password';

        return (
            <Container style={{ 'minHeight': '50vh' }}>
                <Segment style={{ maxWidth: 562.5, margin: 'auto' }}>
                    {errorMessage}
                    <Form size='big'>
                        <Popup
                            trigger={<Form.Input fluid label='New password' icon='lock' iconPosition='left' type={inputType} name='newPassword' onChange={this.onFormChange} value={this.state.newPassword} error={!!this.state.validationErrors.newPassword} />}
                            position='left center'
                            on='focus'
                        >
                            <Popup.Header content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_TITLE} />
                            <Popup.Content>
                                <List bulleted>
                                    <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_LENGTH} />
                                    <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPECIALCHARACTERS + prohibitedCharacters} />
                                    <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPACES} />
                                </List>
                            </Popup.Content>
                        </Popup>

                        <Form.Input fluid label='Confirm new password' icon='lock' iconPosition='left' type={inputType} name='confirmNewPassword' onChange={this.onFormChange} value={this.state.confirmNewPassword} error={!!this.state.validationErrors.confirmNewPassword} />
                        <Form.Checkbox label='Show password' onClick={this.onShowPasswordClick} />
                        <Button fluid color='blue' content='Submit' size='huge' onClick={this.onSubmitClick} />
                    </Form>
                    <Segment basic size='big'>
                        <a className='link_style' onClick={this.toLoginScreen}>Back to login</a>
                    </Segment>
                </Segment>
                <Dimmer active={this.state.loading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
                <Dimmer active={this.state.loadingSuccess} page>
                    <div>
                        <Header as='h2' icon inverted>
                            <Icon name='check' />
                            {Messages.REPAIRREQUEST_MSG_SUCCESSHEADER_RESETPASSWORD}
                            <Header.Subheader>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_RESETPASSWORD}</Header.Subheader>
                        </Header>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={this.toLoginScreen}>
                            <Icon name='check' />
                            OK
                        </Button>
                    </div>
                </Dimmer>
            </Container>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
