import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../Root.js'
import { Button, Menu, Dropdown } from 'semantic-ui-react';

import * as Constant from '../Constants/Constant.js';

const menuItemsForGeneral = [
    {
        name: 'Shipping',
        children: [
            { name: 'New Shipping', tabStatus: Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT },
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_HISTORY },
        ],
    },
    {
        name: 'Protection Plan',
        children: [
            { name: 'Register', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER },
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY },
        ],
    },
    { name: 'User Setting', tabStatus: Constant.REPAIRREQUEST_STAT_USERSETTING },
    { name: 'Contact Us', tabStatus: Constant.REPAIRREQUEST_STAT_CONATCTUS },
];
const menuItemsForRepairCenter = [
    {
        name: 'Protection Plan',
        children: [
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY },
        ],
    },
    { name: 'User Setting', tabStatus: Constant.REPAIRREQUEST_STAT_USERSETTING },
    { name: 'Contact Us', tabStatus: Constant.REPAIRREQUEST_STAT_CONATCTUS },
];

export class MenuBar extends React.Component {
    constructor(props) {
        super(props);

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onDisclaimerClick = this.onDisclaimerClick.bind(this);
        this.onManualDownloadClick = this.onManualDownloadClick.bind(this);
    }

    loadingErrorFlagInitialization() {
        this.props.Action.loginCheckingError(false);
        this.props.Action.requestFetchingError(false);
        this.props.Action.masterDataFetchingError(false);
        this.props.Action.requestSubmittingError(false);
        this.props.Action.logoutFetchingError(false);
        this.props.Action.userMasterFetchError(false);
        this.props.Action.userSettingUpdatingError(false);
        this.props.Action.customerUserAddingError(false);
        this.props.Action.fileDownloadingError(false);
        this.props.Action.manualDownloadingError(false);
        this.props.Action.customerAddressBookFetchingError(false);
        this.props.Action.protectionPlanListFetchingError(false);
        this.props.Action.protectionPlanFetchingError(false);
        this.props.Action.invoiceFetchingError(false);
        this.props.Action.protectionPlanSavingError(false);
        this.props.Action.customerEndUsersFetchingError(false);
    }

    onMenuClick(evn, data) {
        this.props.Action.setTabStatus(data.tabStatus);
    }

    onLogoutClick() {
        this.loadingErrorFlagInitialization();
        this.props.Action.logout();
    }

    onDisclaimerClick() {
        this.props.Action.setDisclaimer(true);
    }

    onManualDownloadClick() {
        this.loadingErrorFlagInitialization();
        this.props.Action.manualDownload(this.props.Reducer.csrftoken);
    }

    render() {
        let userInformation = Object.keys(this.props.Reducer.userInformation).length
            ? `#${this.props.Reducer.userInformation.CustomerNumber} : ${this.props.Reducer.userInformation.UserName}` : "";

        let menuItems = this.props.Reducer.userInformation.RepairCenterFlag ? menuItemsForRepairCenter : menuItemsForGeneral;
        return (
            <Menu inverted secondary stackable>
                {menuItems.map(item => {
                    if (item.hasOwnProperty('children')) {
                        const className = item.children.some(x => x.tabStatus === this.props.Reducer.tabStatus)
                            ? 'inverted active'
                            : 'inverted';
                        return <Dropdown item text={item.name} className={className}>
                            <Dropdown.Menu>
                                {item.children.map(item => (
                                    <Dropdown.Item
                                        text={item.name}
                                        selected={item.tabStatus === this.props.Reducer.tabStatus}
                                        tabStatus={item.tabStatus}
                                        onClick={this.onMenuClick}
                                    />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>;
                    }
                    return <Menu.Item name={item.name} active={this.props.Reducer.tabStatus == item.tabStatus}
                        tabStatus={item.tabStatus} onClick={this.onMenuClick} />;
                })}
                <Menu.Menu position='right' stackable>
                    <Menu.Item>{userInformation}</Menu.Item>
                    <Button compact basic inverted content='Disclaimer' onClick={this.onDisclaimerClick} />
                    <Button compact basic inverted content='Manual download' onClick={this.onManualDownloadClick} />
                    <Button compact basic inverted content='Logout' onClick={this.onLogoutClick} />
                </Menu.Menu>
            </Menu>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar);