import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Header, Icon } from 'semantic-ui-react';

import { mapDispatchToProps, mapStateToProps } from '../../Root';

export class SelectItem extends Component {
    constructor(props) {
        super(props);

        this.onSelectComponent = this.onSelectComponent.bind(this);
    }

    onSelectComponent() {
        this.props.Action.setTabStatus(this.props.nextTabStatus);
    }

    render() {
        return (
            <Card onClick={this.onSelectComponent}>
                <Card.Content style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Header as='h2' icon>
                        <Icon name={this.props.icon} />
                        {this.props.header}
                        <Header.Subheader>
                            {this.props.subheader}
                        </Header.Subheader>
                    </Header>
                    {/* TODO: The CSS written for this label should be deleted along with it. */}
                    {this.props.header == 'Protection Plan' ?
                        <p style={{ fontWeight: 'bold', textAlign: 'center', color: 'orange', fontSize: '20px' }}>
                            Under Preparation
                        </p>
                        : null
                    }
                </Card.Content>
            </Card>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectItem);
