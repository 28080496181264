import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import _ from 'lodash';
import store from './App';

import { Segment, Header, Image, Dimmer, Loader } from 'semantic-ui-react';

import './App.css';
import * as Action from './Actions/Action';
import * as Constant from './Constants/Constant';
import logo from './logo.jpg';
import Tab from './Tab';
import Login from './Components/Login/Login';
import ForgotPassword from './Components/Login/ForgotPassword';
import ResetPassword from './Components/Login/ResetPassword';
import Disclaimer from './Components/Disclaimer';
import MenuBar from './Components/MenuBar';

export class Root extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.Reducer.tabStatus != Constant.REPAIRREQUEST_STAT_RESETPASSWORD) {
            this.props.Action.sessionCheck();
        } else {
            this.props.Action.sessionChecking(false);
        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    render() {
        let component, headerMenu;

        if (this.props.Reducer.sessionChecking) {
            component = <Dimmer active page><Loader content='Loading...' size='huge' /></Dimmer>;
        } else {
            switch (this.props.Reducer.tabStatus) {
                case Constant.REPAIRREQUEST_STAT_LOGIN:
                    component = <Login />;
                    break;
                case Constant.REPAIRREQUEST_STAT_FORGOTPASSWORD:
                    component = <ForgotPassword />;
                    break;
                case Constant.REPAIRREQUEST_STAT_RESETPASSWORD:
                    component = <ResetPassword />;
                    break;
                default:
                    component = <Tab />;
                    headerMenu = <MenuBar />;
                    break;
            }
        }

        let header =
            <Segment inverted>
                <Header as='h1'>Extended Services Portal</Header>
                {headerMenu}
            </Segment>;
        let footer =
            <Segment as='footer' inverted color='black'>
                <Header as='h5' textAlign='center'>
                    © 2024 JVCKENWOOD USA Corporation. All rights reserved.
                </Header>
            </Segment>;

        return (
            <div>
                <Segment style={{ 'minHeight': '100vh' }} basic>
                    <Image src={logo} size='small' />
                    {header}
                    {component}
                    {footer}
                </Segment>

                <Disclaimer />
                <Dimmer active={this.props.Reducer.logouting || this.props.Reducer.manualDownloading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </div>

        );
    }
}

// state の中に store.js の combineReducers で指定したキーの State が全部入ってくる
export function mapStateToProps(state) {
    return state;
}

export function mapDispatchToProps(dispatch) {
    return {
        Action: bindActionCreators(Action, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Root);