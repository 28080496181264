import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tab, Table, Button, Message, Dimmer, Loader } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import EndUserEditModal from './EndUserEditModal.js';
import AppConfig from '../../AppConfig.js';

import CustomerEndUser from '../../Models/ViewModels/CustomerEndUser.js';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

const MODAL_STAT_ENDUSER_NEW = 'MODAL_STAT_ENDUSER_NEW';
const MODAL_STAT_ENDUSER_EDIT = 'MODAL_STAT_ENDUSER_EDIT';

export class EndUserTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: '',
            id: 0,

            loading: false,
            loadingError: false,
            errorMessage: "",

            isAdditionSuccessFlag: false,
            isUpdateSuccessFlag: false,
            isDeleteSuccessFlag: false,
        };

        this.flagInitialization = this.flagInitialization.bind(this);
        this.onAddNewEndUserClick = this.onAddNewEndUserClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.endUserDelete = this.endUserDelete.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.setSuccessFlag = this.setSuccessFlag.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // The function is execute when the user clicks the "Manual Download" button
        if ((!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading)
            || (!prevProps.Reducer.logoutingError && this.props.Reducer.logoutingError)) {
            this.setState({
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
                loadingError: false,
                errorMessage: "",
            });
        }
        // EndUser edit modal has been opened
        else if (!prevState.modalStatus && this.state.modalStatus) {
            this.flagInitialization();
        }
    }

    componentWillUnmount() {
        this.flagInitialization(true);
    }

    flagInitialization(isUnmount = false) {
        if (!isUnmount || !this.props.Reducer.userMasterFetchingError) {
            this.props.Action.manualDownloadingError(false);
            this.props.Action.masterDataFetchingError(false);
            this.props.Action.userMasterFetchError(false);
            this.props.Action.logoutFetchingError(false);
            this.setState({
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
                loadingError: false,
                errorMessage: "",
            });
        }
    }

    endUserDelete(id) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/endUser/' + id, {
            mode: 'cors', method: 'delete', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                this.props.Action.setCustomerEndUsers(endUsers);
                this.setState({
                    loading: false,
                    isDeleteSuccessFlag: true,
                });
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    onAddNewEndUserClick() {
        this.setState({ modalStatus: MODAL_STAT_ENDUSER_NEW });
    }

    onEditClick(id) {
        this.setState({
            modalStatus: MODAL_STAT_ENDUSER_EDIT,
            id,
        });
    }

    onDeleteClick(id) {
        this.flagInitialization();
        if (window.confirm(Messages.REPAIRREQUEST_MSG_CONFIRMMESSAGE_DELETE)) {
            this.endUserDelete(id);
        }
    }

    modalClose() {
        this.setState({ modalStatus: '', id: 0 });
    }

    onModalStatusChange(modalStatus, id = 0) {
        this.setState({ modalStatus, id });
    }

    setSuccessFlag(isAdditionSuccessFlag, isUpdateSuccessFlag) {
        this.setState({
            isAdditionSuccessFlag,
            isUpdateSuccessFlag,
        });
    }

    render() {
        let errorMessage;
        if (this.state.loadingError) {
            errorMessage = <ErrorMessage active content={this.state.errorMessage} page />;
        } else if (this.props.Reducer.userMasterFetchingError || this.props.Reducer.manualDownloadingError || this.props.Reducer.logoutingError) {
            errorMessage = <ErrorMessage active content={this.props.Reducer.errorMessage} page />;
        }

        let successMessage;
        if (this.state.isAdditionSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_ADDITION} />;
        } else if (this.state.isUpdateSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_UPDATE} />;
        } else if (this.state.isDeleteSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_DELETE} />;
        }

        let EndUserRows = this.props.Reducer.customerEndUsers
            .sort((a, b) => b.Id - a.Id)
            .map(x => {
                return (
                    <Table.Row key={x.Id}>
                        <Table.Cell>{x.Id}</Table.Cell>
                        <Table.Cell style={{ wordWrap: "break-word" }}>{x.EndUserName}</Table.Cell>
                        <Table.Cell style={{ wordWrap: "break-word" }}>{x.ContactName}</Table.Cell>
                        <Table.Cell style={{ wordWrap: "break-word" }}>{x.Email}</Table.Cell>
                        <Table.Cell style={{ wordWrap: "break-word" }}>
                            <p className="endUserNote">{x.Note ? x.Note : "-"}</p>
                        </Table.Cell>
                        <Table.Cell collapsing>
                            <Button content='Edit' color='green' onClick={() => this.onEditClick(x.Id)} disabled={this.props.Reducer.userMasterFetchingError} />
                            <Button content='Delete' color='red' onClick={() => this.onDeleteClick(x.Id)} disabled={this.props.Reducer.userMasterFetchingError} />
                        </Table.Cell>
                    </Table.Row>
                )
            });

        return (
            <Tab.Pane>
                {errorMessage}
                {successMessage}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button primary content='Add new end-user' icon='plus' onClick={this.onAddNewEndUserClick} disabled={this.props.Reducer.userMasterFetchingError} />
                </div>
                <Table striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='No.' collapsing />
                            <Table.HeaderCell content='End-user name' />
                            <Table.HeaderCell content='Contact name' />
                            <Table.HeaderCell content='Email' />
                            <Table.HeaderCell content='Note' />
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {EndUserRows}
                    </Table.Body>
                </Table>

                {
                    this.state.modalStatus
                        ?
                        <EndUserEditModal
                            new={this.state.modalStatus === MODAL_STAT_ENDUSER_NEW}
                            edit={this.state.modalStatus === MODAL_STAT_ENDUSER_EDIT}
                            id={this.state.id}
                            onModalClose={this.modalClose}
                            setSuccessFlag={this.setSuccessFlag}
                        />
                        : ''
                }
                <Dimmer active={this.state.loading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Tab.Pane>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EndUserTab);
