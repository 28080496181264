import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js'
import { Header, Segment } from 'semantic-ui-react';
import Functions from '../../../Functions.js';
import { marginRightStyle } from "./ProtectionPlanView.js";

export class BasicInformation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Segment>
                <Header icon='info' content='Basic information' dividing />
                <dl>
                    <div style={marginRightStyle}>
                        <dt>No.</dt>
                        <dd>{this.props.proptectionPlan.Id}</dd>
                    </div>
                    {this.props.Reducer.userInformation.RepairCenterFlag
                        ? <div style={marginRightStyle}>
                            <dt>Customer</dt>
                            <dd>{`${this.props.proptectionPlan.CustomerName} (#${this.props.proptectionPlan.CustomerNumber})`}</dd>
                        </div>
                        : null
                    }
                    <div style={marginRightStyle}>
                        <dt>Registered date</dt>
                        <dd>{Functions.dateForm(this.props.proptectionPlan.CreatedDate)}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Registered by</dt>
                        <dd>{this.props.proptectionPlan.CreatedByUserName}</dd>
                    </div>
                    {this.props.Reducer.userInformation.RepairCenterFlag
                        ? <div style={marginRightStyle}>
                            <dt>updated date</dt>
                            <dd>{Functions.dateForm(this.props.proptectionPlan.UpdatedDate)}</dd>
                        </div>
                        : null
                    }
                    {this.props.Reducer.userInformation.RepairCenterFlag
                        ? <div style={marginRightStyle}>
                            <dt>Notification date to Repair Center</dt>
                            <dd>{this.props.proptectionPlan.IsNotified ? Functions.dateForm(this.props.protectionPlan.LastNotificationDate) : '-'}</dd>
                        </div>
                        : null
                    }
                </dl>
            </Segment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BasicInformation);
