import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../Root.js'
import { Header, Message, Button, Dimmer, Icon } from 'semantic-ui-react';

import * as Constant from '../Constants/Constant';
import Functions from '../Functions.js';

export class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        this.onContactUsClick = this.onContactUsClick.bind(this);
    }

    onContactUsClick() {
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_CONATCTUS);
    }

    render() {
        let content = this.props.content ?? null;
        let active = this.props.active ?? false;
        let page = this.props.page ?? false;

        if (Functions.isIncludes(this.props.Reducer.comparedPhrase, content)) {
            let button;
            if (Functions.isIncludes([this.props.Reducer.comparedPhrase[0]], content)) {
                button = <Button basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }}
                    content='Reload' icon='redo' onClick={() => window.location.reload()} />;
            } else if (Functions.isIncludes([this.props.Reducer.comparedPhrase[1]], content)) {
                button = <Button basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }}
                    content='Go to Contact Us' icon='mail' onClick={this.onContactUsClick} />;
            }

            return (
                <Dimmer active={active} page={page}>
                    <div className='flex-column'>
                        <Header as='h2' icon inverted>
                            <Icon name='warning sign' />
                            {content}
                        </Header>
                        <div style={{ display: 'flex', minWidth: '50vw' }}>
                            {button}
                        </div>
                    </div>
                </Dimmer>
            );
        }

        return <Message error content={content} style={{ whiteSpace: 'pre' }} />;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorMessage);
