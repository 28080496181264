import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js'

import { Container, Grid, Segment, Header, Form, Button, Message, Dimmer, Loader, Icon, Image } from 'semantic-ui-react';

import * as Constant from '../../Constants/Constant';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            password: '',
            showPassword: false,

            validationErrors: {},
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
        this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
    }

    componentDidMount() {
        if (!Object.keys(this.props.Reducer.information).length) {
            this.props.Action.informationFetch();
        }
    }

    componentWillUnmount() {
        this.props.Action.loginCheckingError(false);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    onFormChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onLoginClick() {
        this.props.Action.loginCheckingError(false);
        let validationErrors = {};
        if (!this.state.userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_USERID;
        }
        if (!this.state.password) {
            validationErrors.password = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_PASSWORD;
        }

        let password = this.state.password;
        if (!Object.keys(validationErrors).length) {
            this.props.Action.LoginCheck(this.state.userId, this.state.password);
            password = '';
        }
        this.setState({ validationErrors, password });
    }

    onForgotPasswordClick() {
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_FORGOTPASSWORD);
    }

    onShowPasswordClick() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    render() {
        let errorMessage = '';
        if (Object.keys(this.state.validationErrors).length) {
            let errors = Object.keys(this.state.validationErrors).map(x => <>{this.state.validationErrors[x]}<br /></>);
            errorMessage = <Message error>{errors}</Message>;
        } else if (this.props.Reducer.loginCheckingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        let information = (
            <Segment attached style={{ minHeight: 80 }}>
                {this.props.Reducer.informationIsLoading || this.props.Reducer.informationIsLoadingError ? '' : <p>No information...</p>}
                <Dimmer active={this.props.Reducer.informationIsLoading}>
                    <Loader content="Loading..." size='small' />
                </Dimmer>
                <Dimmer active={this.props.Reducer.informationIsLoadingError}>
                    <Icon name='exclamation triangle' size='large' />
                    <p>{Messages.REPAIRREQUEST_MSG_ERROROCCURRED_INFORMATION}</p>
                </Dimmer>
            </Segment>
        );
        if (Object.keys(this.props.Reducer.information).length) {
            let date = new Date(this.props.Reducer.information.notificationDate);
            information = (
                <Segment attached>
                    <Header as='h3'>
                        {date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </Header>
                    <p>{this.props.Reducer.information.information}</p>
                </Segment>
            );
        }

        return (
            <Container style={{ 'minHeight': '50vh' }}>
                <Grid columns={2} stackable>
                    <Grid.Column>
                        <Segment>
                            {errorMessage}
                            <Form size='big'>
                                <Form.Input fluid label='User ID' icon='user' iconPosition='left' error={!!this.state.validationErrors.userId} name='userId' onChange={this.onFormChange} value={this.state.userId} />
                                <Form.Input fluid label='Password' icon='lock' iconPosition='left' type={this.state.showPassword ? 'text' : 'password'} error={!!this.state.validationErrors.password} name='password' onChange={this.onFormChange} value={this.state.password} />
                                <Form.Checkbox label='Show password' onClick={this.onShowPasswordClick} />
                                <Button fluid color='blue' content='Login' size='huge' onClick={this.onLoginClick} />
                            </Form>
                            <Segment basic size='big'>
                                <a className='link_style' onClick={this.onForgotPasswordClick}>Forgot password?</a>
                            </Segment>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as='h2' icon='info' content='Information' attached='top' />
                        {information}
                        <Segment style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div>
                                <p>Welcome to the JVCKENWOOD USA Service Website.</p>
                                <p>
                                    This is a site for authorized users, only accessible for JVCKENWOOD Service Partners with a valid User ID and Password.<br />
                                    Please set a password from link of "Forgot password". The User ID is your email address.
                                </p>
                                <p>End users: please visit the <a href="https://store.jvc.com/" className='link_style' target='jvc'>JVC</a> or <a href="https://store.kenwoodusa.com/" className='link_style' target='kenwood'>Kenwood</a> Accessory Store.</p>
                            </div>
                            <Image src='/images/radio_and_repeater_v1.png' alt='Image of radio and repeater'
                                spaced='left' id='radioAndRepeaterImage' className='maintainAspectRatio' />
                        </Segment>
                    </Grid.Column>
                </Grid>
                <Dimmer active={this.props.Reducer.loginChecking} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Container>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
