import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import * as Constants from '../../Constants/Constant.js';
import ProtectionPlanList from './History/ProtectionPlanList.js';
import ProtectionPlanEdit from './Register/ProtectionPlanEdit.js';
import ProtectionPlanView from './History/ProtectionPlanView.js';

export class ProtectionPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabStatus: (this.props.Reducer.tabStatus == Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY)
                ? Constants.PROTECTIONPLAN_STAT_LIST
                : Constants.PROTECTIONPLAN_STAT_REGISTER,
            id: 0,
        };

        this.setProtectionPlanTabStatus = this.setProtectionPlanTabStatus.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.Reducer.tabStatus !== this.props.Reducer.tabStatus) {
            const tabStatus = this.props.Reducer.tabStatus == Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY
                ? Constants.PROTECTIONPLAN_STAT_LIST
                : Constants.PROTECTIONPLAN_STAT_REGISTER
            this.setState({ tabStatus });
        } else if (prevState.tabStatus !== this.state.tabStatus) {
            if (this.state.tabStatus === Constants.PROTECTIONPLAN_STAT_REGISTER) {
                this.props.Action.setTabStatus(Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER);
            } else {
                this.props.Action.setTabStatus(Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY);
            }
        }
    }

    setProtectionPlanTabStatus(tabStatus, id = 0) {
        window.scroll({ top: 0 });
        this.setState({ tabStatus, id });
    }

    render() {
        switch (this.state.tabStatus) {
            case Constants.PROTECTIONPLAN_STAT_LIST:
                return <ProtectionPlanList setProtectionPlanTabStatus={this.setProtectionPlanTabStatus} />;

            case Constants.PROTECTIONPLAN_STAT_REGISTER:
                return <ProtectionPlanEdit setProtectionPlanTabStatus={this.setProtectionPlanTabStatus} />;

            case Constants.PROTECTIONPLAN_STAT_VIEW:
                return <ProtectionPlanView setProtectionPlanTabStatus={this.setProtectionPlanTabStatus} id={this.state.id} />;

            default:
                return <div>No content...</div>;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectionPlan);
