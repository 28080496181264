"use strict";

export default class {
    constructor(dataObject = null) {
        this.UserId = '';
        this.UserName = '';
        this.CustomerNumber = '';
        this.CompanyId = '';
        this.AddressLineOne = '';
        this.AddressLineTwo = '';
        this.AddressLineThree = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';
        this.Telephone = '';
        this.Fax = '';
        this.ManagerFlag = false;
        this.SecondDayAirEnable = false;
        this.FirstLogin = false;
        this.RepairCenterFlag = false;

        //Import data if set
        if (dataObject) {
            try {
                this.UserId = dataObject.userId;
                this.UserName = dataObject.userName;
                this.CustomerNumber = dataObject.customerNumber;
                this.CompanyId = dataObject.companyId;
                this.AddressLineOne = dataObject.addressLineOne;
                this.AddressLineTwo = dataObject.addressLineTwo;
                this.AddressLineThree = dataObject.addressLineThree;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
                this.Telephone = dataObject.telephone;
                this.Fax = dataObject.fax;
                this.CustomerName = dataObject.customerName;
                this.ManagerFlag = dataObject.managerFlag;
                this.SecondDayAirEnable = dataObject.secondDayAirEnable;
                this.FirstLogin = dataObject.firstLogin;
                this.RepairCenterFlag = dataObject.repairCenterFlag;
            }
            catch (e) {
                throw 'Data import error @ UserInformation data.';
            }
        }
    }
}
