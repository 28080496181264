"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.WarrantyPeriod = 0;

        this.Rules = [];

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.WarrantyPeriod = dataObject.warrantyPeriod;
            }
            catch (e) {
                throw 'Data import error @ WarrantyMaster data.';
            }
        }
    }
}
