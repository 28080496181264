import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Form, Button, Dimmer, Loader } from 'semantic-ui-react';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js';

import Functions from '../../Functions.js';
import AppConfig from '../../AppConfig.js';
import * as Messages from '../../Resources/Messages.js';
import CustomerEndUser from '../../Models/ViewModels/CustomerEndUser.js';
import ErrorMessage from '../ErrorMessage.js';

export class EndUserEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            endUserName: '',
            contactName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
            telephone: '',
            fax: '',
            note: '',

            validationErrors: {},
            loading: false,
            loadingError: false,
            errorMessage: '',
        };

        this.endUserInsert = this.endUserInsert.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.createSaveData = this.createSaveData.bind(this);
        this.validationCheck = this.validationCheck.bind(this);
    }

    componentDidMount() {
        if (this.props.edit) {
            const EndUser = this.props.Reducer.customerEndUsers.find(x => x.Id == this.props.id);
            this.setState({
                endUserName: EndUser.EndUserName,
                contactName: EndUser.ContactName,
                email: EndUser.Email,
                addressLine1: EndUser.AddressLineOne,
                addressLine2: EndUser.AddressLineTwo,
                addressLine3: EndUser.AddressLineThree,
                city: EndUser.City,
                state: EndUser.State,
                zip: EndUser.Zip,
                country: EndUser.Country,
                telephone: EndUser.Telephone,
                fax: EndUser.Fax,
                note: EndUser.Note,
            });
        }
    }

    // CustomerEndUsersテーブルにデータを追加する
    endUserInsert(saveData) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/endUser', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                this.props.Action.setCustomerEndUsers(endUsers);
                this.setState({ loading: false });
                this.props.setSuccessFlag(true, false);
                this.props.onModalClose();
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    // CustomerEndUsersテーブルのデータを更新する
    endUserUpdate(saveData) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/endUser', {
            mode: 'cors', method: 'put', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                this.props.Action.setCustomerEndUsers(endUsers);
                this.setState({ loading: false });
                this.props.setSuccessFlag(false, true);
                this.props.onModalClose();
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    onValueChange(evn, data) {
        this.setState({ [data.stateName]: data.value });
    }

    onSubmitClick() {
        let saveData = this.createSaveData();
        let validationErrors = this.validationCheck(saveData);
        this.setState({
            ...saveData,
            validationErrors,
            loadingError: false,
            errorMessage: '',
        });

        if (!Object.keys(validationErrors).length) {
            if (this.props.new) {
                this.endUserInsert(saveData);
            } else if (this.props.edit) {
                this.endUserUpdate(saveData);
            }
        }
    }

    createSaveData() {
        return {
            id: Number(this.props.id),
            endUserName: this.state.endUserName.trim(),
            contactName: this.state.contactName.trim(),
            email: this.state.email.trim(),
            addressLine1: this.state.addressLine1.trim(),
            addressLine2: this.state.addressLine2.trim(),
            addressLine3: this.state.addressLine3.trim(),
            city: this.state.city.trim(),
            state: this.state.state.trim(),
            zip: this.state.zip.trim(),
            country: this.state.country.trim(),
            telephone: this.state.telephone.trim(),
            fax: this.state.fax.trim(),
            note: this.state.note.trim(),
        };
    }

    validationCheck(saveData) {
        let errors = {};

        if (!saveData.endUserName) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMENULL;
        } else if (Functions.byteLengthOf(saveData.endUserName) > 100) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMELENGTH;
        }
        if (!saveData.contactName) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMENULL;
        } else if (Functions.byteLengthOf(saveData.contactName) > 100) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMELENGTH;
        }
        if (!saveData.email) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(saveData.email) > 100) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        if (!saveData.addressLine1) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(saveData.addressLine1) > 100
            || Functions.byteLengthOf(saveData.addressLine2) > 100
            || Functions.byteLengthOf(saveData.addressLine3) > 100) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (!saveData.city) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(saveData.city) > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (!saveData.state) {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (!saveData.zip) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(saveData.zip) > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (!saveData.telephone) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(saveData.telephone) > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(saveData.fax) > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (Functions.byteLengthOf(saveData.note) > 1000) {
            errors.note = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTELENGTH;
        }

        return errors;
    }

    render() {
        let stateOptions = this.props.Reducer.stateProvinceCodeMaster.map(x => (
            { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` }
        ));

        let errorMessageComponent;
        if (Object.keys(this.state.validationErrors).length) {
            let content = Object.values(this.state.validationErrors).map(x => <>{x}<br /></>);
            errorMessageComponent = <ErrorMessage content={content} />;
        } else if (this.state.loadingError) {
            errorMessageComponent = <ErrorMessage content={this.state.errorMessage} active />;
        }

        return (
            <>
                <Modal dimmer open={this.props.new || this.props.edit}>
                    <Modal.Header content={this.props.new ? 'Add new end-user' : 'Edit end-user'} />
                    <Modal.Content scrolling>
                        {errorMessageComponent}
                        <Form>
                            <Form.Input label='End-user name' required error={!!this.state.validationErrors.endUserName}
                                value={this.state.endUserName} onChange={this.onValueChange} stateName='endUserName' />
                            <Form.Input label='Contact name' required error={!!this.state.validationErrors.contactName}
                                value={this.state.contactName} onChange={this.onValueChange} stateName='contactName' />
                            <Form.Input label='Email' required error={!!this.state.validationErrors.email}
                                value={this.state.email} onChange={this.onValueChange} stateName='email' />
                            <Form.Input label='Address' required error={!!this.state.validationErrors.addressLine}
                                value={this.state.addressLine1} onChange={this.onValueChange} stateName='addressLine1' />
                            <Form.Input error={!!this.state.validationErrors.addressLine}
                                value={this.state.addressLine2} onChange={this.onValueChange} stateName='addressLine2' />
                            <Form.Input error={!!this.state.validationErrors.addressLine}
                                value={this.state.addressLine3} onChange={this.onValueChange} stateName='addressLine3' />
                            <Form.Group widths='equal'>
                                <Form.Input label='City' required error={!!this.state.validationErrors.city}
                                    value={this.state.city} onChange={this.onValueChange} stateName='city' />
                                <Form.Dropdown label='State' required error={!!this.state.validationErrors.state}
                                    selection search placeholder='Select state' options={stateOptions}
                                    value={this.state.state} onChange={this.onValueChange} stateName='state' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input label='Zip/Postal code' required error={!!this.state.validationErrors.zip}
                                    value={this.state.zip} onChange={this.onValueChange} stateName='zip' />
                                <Form.Input label='Country' value={this.state.country}>
                                    <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                                </Form.Input>

                            </Form.Group>
                            <Form.Input label='Telephone' required error={!!this.state.validationErrors.telephone}
                                value={this.state.telephone} onChange={this.onValueChange} stateName='telephone' />
                            <Form.Input label='Fax' error={!!this.state.validationErrors.fax}
                                value={this.state.fax} onChange={this.onValueChange} stateName='fax' />
                            <Form.TextArea label='Note' error={!!this.state.validationErrors.note}
                                value={this.state.note} onChange={this.onValueChange} stateName='note' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content={this.props.new ? 'Add' : 'Save'} primary onClick={this.onSubmitClick} />
                        <Button content='Close' onClick={this.props.onModalClose} />
                    </Modal.Actions>
                    <Dimmer active={this.state.loading}>
                        <Loader content='Loading...' size='huge' />
                    </Dimmer>
                </Modal>
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EndUserEditModal);
