import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Dimmer, Header, Loader, Segment, Table, Message, Label } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';
import * as Constants from '../../../Constants/Constant.js';
import * as Messages from '../../../Resources/Messages.js';
import Functions from '../../../Functions.js';
import ErrorMessage from '../../ErrorMessage.js';
import BasicInformation from './BasicInformation.js';

export class ProtectionPlanView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proptectionPlan: this.props.Reducer.protectionPlans.find(x => x.Id == this.props.id),
            deletedFlag: false,
        };

        this.onBackToListClick = this.onBackToListClick.bind(this);
    }

    componentDidMount() {
        this.props.Action.fetchProtectionPlan(this.props.Reducer.csrftoken, this.props.id);
    }

    componentDidUpdate(prevProps, prevState) {
        // 選択したProtection planの最新データ取得完了後
        if (prevProps.Reducer.protectionPlanFetching && !this.props.Reducer.protectionPlanFetching && !this.props.Reducer.protectionPlanFetchingError) {
            const proptectionPlan = this.props.Reducer.protectionPlans.find(x => x.Id == this.props.id);
            if (proptectionPlan == null) {
                // 他のユーザがデータを削除した
                this.setState({ deletedFlag: true });
            } else {
                this.setState({ proptectionPlan });
            }
        }
    }

    componentWillUnmount() {
        this.props.Action.protectionPlanFetchingError(false);
        this.props.Action.logoutFetchingError(false);
        this.props.Action.manualDownloadingError(false);
    }

    onBackToListClick() {
        this.props.setProtectionPlanTabStatus(Constants.PROTECTIONPLAN_STAT_LIST);
    }

    render() {
        const pageTitle = <Header as='h2' icon='shield' content='Protection Plan History' />;
        const backToListButton = <Button content='Back to List' onClick={this.onBackToListClick} />;
        const buttons = <div className='flex-floatRight'>{backToListButton}</div>;

        let errorMessage = null;
        if (this.props.Reducer.protectionPlanFetchingError || this.props.Reducer.logoutingError || this.props.Reducer.manualDownloadingError) {
            errorMessage = <ErrorMessage active content={this.props.Reducer.errorMessage} page />;
        }

        if (this.state.deletedFlag) {
            return (
                <>
                    <div style={{ display: 'flex' }}>
                        {pageTitle}
                        {buttons}
                    </div>

                    {errorMessage}
                    <Message error content={Messages.REPAIRREQUEST_MSG_ERROROCCURRED_PROTECTIONPLAN_DELETED} />
                </>
            );
        }

        return (
            <>
                <div style={{ display: 'flex' }}>
                    {pageTitle}
                    {buttons}
                </div>

                {errorMessage}

                <BasicInformation proptectionPlan={this.state.proptectionPlan} />

                <Segment>
                    <Header icon='user' content='End-user information' dividing />
                    <dl>
                        <div style={marginRightStyle}>
                            <dt>End-user name</dt>
                            <dd>{this.state.proptectionPlan.EndUserName}</dd>
                        </div>
                        <div style={marginRightStyle}>
                            <dt>Contact name</dt>
                            <dd>{this.state.proptectionPlan.ContactName}</dd>
                        </div>
                        <div style={marginRightStyle}>
                            <dt>Email</dt>
                            <dd>{this.state.proptectionPlan.Email}</dd>
                        </div>
                    </dl>
                    <dl style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={marginRightStyle}>
                            <dt>Address</dt>
                            <dd>{this.state.proptectionPlan.AddressLineOne}</dd>
                            <dd>{this.state.proptectionPlan.AddressLineTwo ? this.state.proptectionPlan.AddressLineTwo : '-'}</dd>
                            <dd>{this.state.proptectionPlan.AddressLineThree ? this.state.proptectionPlan.AddressLineThree : '-'}</dd>
                            <dd>{this.state.proptectionPlan.City}, {this.state.proptectionPlan.State} {this.state.proptectionPlan.Zip}</dd>
                            <dd>{this.state.proptectionPlan.Country}</dd>
                        </div>
                        <div style={marginRightStyle}>
                            <dt>Telephone</dt>
                            <dd>{this.state.proptectionPlan.Telephone}</dd>
                        </div>
                        <div style={marginRightStyle}>
                            <dt>Fax</dt>
                            <dd>{this.state.proptectionPlan.Fax ? this.state.proptectionPlan.Fax : '-'}</dd>
                        </div>
                    </dl>
                    <dl>
                        <dt>Note</dt>
                        <dd>{this.state.proptectionPlan.Note ? this.state.proptectionPlan.Note : '-'}</dd>
                    </dl>
                </Segment>

                <Segment>
                    <Header icon='cart' content='Purchase information' dividing />
                    <dl>
                        <div style={marginRightStyle}>
                            <dt>PO</dt>
                            <dd>{this.state.proptectionPlan.PO}</dd>
                        </div>
                        <div style={marginRightStyle}>
                            <dt>Purchase date</dt>
                            <dd>{Functions.dateForm(this.state.proptectionPlan.PurchaseDate)}</dd>
                        </div>
                    </dl>
                </Segment>

                <Segment>
                    <Header icon='file text' content='Invoice information' dividing />
                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content='Invoice #' />
                                <Table.HeaderCell content='Invoice date' />
                                <Table.HeaderCell content='Model' />
                                <Table.HeaderCell content='Serial #' />
                                <Table.HeaderCell content='Plan' />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.proptectionPlan.Details
                                .sort((a, b) => {
                                    if (a.InvoiceNumber != b.InvoiceNumber) {
                                        return a.InvoiceNumber < b.InvoiceNumber ? -1 : 1;
                                    }
                                    return 0;
                                }).map(detail => {
                                    const plan = this.props.Reducer.repairServicePlanMaster.find(x => x.Id == detail.PlanId);
                                    return <Table.Row key={detail.Id}>
                                        <Table.Cell content={detail.InvoiceNumber} />
                                        <Table.Cell content={Functions.dateForm(detail.InvoiceDate)} />
                                        <Table.Cell content={detail.Model} />
                                        <Table.Cell>
                                            {detail.IsReturned
                                                ? <Label content='Returned' color='red' horizontal />
                                                : null}
                                            {detail.SerialNumber}
                                        </Table.Cell>
                                        <Table.Cell content={plan ? plan.PlanName : '-'} />
                                    </Table.Row>;
                                })}
                        </Table.Body>
                    </Table>
                </Segment>
                <div style={{ display: 'flex' }}>{buttons}</div>

                <Dimmer active={this.props.Reducer.protectionPlanFetching} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </>
        )
    }
}

export const marginRightStyle = { marginRight: 20, display: 'inline-block' };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectionPlanView);
