import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Button, Form, Segment, Divider, Modal, Table, Message, Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';
import * as Constants from '../../../Constants/Constant.js';
import * as Messages from '../../../Resources/Messages.js';
import ErrorMessage from '../../ErrorMessage.js';
import Functions from '../../../Functions.js';
import ProtectionPlanDetail from '../../../Models/ViewModels/ProtectoinPlanDetail.js';
import ProtectionPlanConfirm from './ProtectionPlanConfirm.js';

const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export class ProtectionPlanEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEndUserId: -1,
            endUserName: '',
            contactName: '',
            email: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressLineThree: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
            telephone: '',
            fax: '',
            note: '',
            isSaveEndUser: true,

            po: '',
            purchaseDate: '',

            searchInvoiceNumber: '',
            invoices: [],

            planModalOpenFlag: false,
            confirmModalOpenFlag: false,
            modalOpenFlag: false,
            validationErrors: {},
        }

        this.onTextChange = this.onTextChange.bind(this);
        this.onSelectEndUserChange = this.onSelectEndUserChange.bind(this);
        this.onSaveEndUserClick = this.onSaveEndUserClick.bind(this);
        this.continueToRegister = this.continueToRegister.bind(this);
        this.onInvoiceSearchClick = this.onInvoiceSearchClick.bind(this);
        this.onInvoiceDeleteClick = this.onInvoiceDeleteClick.bind(this);
        this.backToList = this.backToList.bind(this);
        this.getRepairServicePlans = this.getRepairServicePlans.bind(this);
        this.selectablePlanFiltering = this.selectablePlanFiltering.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.formattedHeader = this.formattedHeader.bind(this);
        this.formattedDetails = this.formattedDetails.bind(this);
    }

    componentDidMount() {
        this.props.Action.fetchCustomerEndUsers(this.props.Reducer.csrftoken);
    }

    componentDidUpdate(prevProps, prevState) {
        // Invoiceデータの取得完了後
        if (prevProps.Reducer.invoiceFetching && !this.props.Reducer.invoiceFetching && !this.props.Reducer.invoiceFetchingError) {
            // 取得したInvoiceを一覧に追加する
            const invoices = this.state.invoices.filter(x => x.InvoiceNumber != this.state.searchInvoiceNumber);
            this.props.Reducer.tempInvoiceMaster
                .forEach(invoice => {
                    // ProtectionPlanMasterから一致するプランを自動選択
                    const repairServicePlans = this.getRepairServicePlans(invoice.Model);

                    const newDetail = new ProtectionPlanDetail();
                    newDetail.Id = 'I-' + invoice.Id;
                    newDetail.CompanyId = invoice.CompanyId;
                    newDetail.InvoiceNumber = invoice.InvoiceNumber;
                    newDetail.Model = invoice.Model;
                    newDetail.SerialNumber = invoice.SerialNumber;
                    newDetail.InvoiceDate = invoice.InvoiceDate;
                    newDetail.PlanId = repairServicePlans.length ? repairServicePlans[0].Id : null;

                    invoices.push(newDetail);
                });
            invoices.sort((a, b) => {
                if (a.InvoiceNumber != b.InvoiceNumber) {
                    return a.InvoiceNumber < b.InvoiceNumber ? -1 : 1;
                }
                if (a.Model != b.Model) {
                    return a.Model < b.Model ? -1 : 1;
                }
                return a.SerialNumber < b.SerialNumber ? -1 : 1;
            });
            this.setState({ invoices, searchInvoiceNumber: '' });
        }
        // 保存の通信が完了後
        else if (prevProps.Reducer.protectionPlanSaving && !this.props.Reducer.protectionPlanSaving && !this.props.Reducer.protectionPlanSavingError) {
            this.setState({ modalOpenFlag: true, confirmModalOpenFlag: false });
        }
        // Purchase dateの値が変更されたとき
        else if (prevState.purchaseDate !== this.state.purchaseDate) {
            // Repair service planを再度適用する
            const invoices = this.state.invoices.map(invoice => {
                const repairServicePlans = this.getRepairServicePlans(invoice.Model);
                invoice.PlanId = repairServicePlans.length ? repairServicePlans[0].Id : null;
                return invoice;
            });
            this.setState({ invoices });
        }
    }

    componentWillUnmount() {
        this.props.Action.setTempInvoiceMaster([]);
        this.props.Action.invoiceFetchingError(false);
        this.props.Action.customerEndUsersFetchingError(false);
    }

    onTextChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onSelectEndUserChange(evn, data) {
        const { value } = data;
        const customerEndUser = this.props.Reducer.customerEndUsers.find(x => x.Id == value);
        this.setState({
            selectedEndUserId: value,
            endUserName: customerEndUser ? customerEndUser.EndUserName : '',
            contactName: customerEndUser ? customerEndUser.ContactName : '',
            email: customerEndUser ? customerEndUser.Email : '',
            addressLineOne: customerEndUser ? customerEndUser.AddressLineOne : '',
            addressLineTwo: customerEndUser ? customerEndUser.AddressLineTwo : '',
            addressLineThree: customerEndUser ? customerEndUser.AddressLineThree : '',
            city: customerEndUser ? customerEndUser.City : '',
            state: customerEndUser ? customerEndUser.State : '',
            zip: customerEndUser ? customerEndUser.Zip : '',
            country: customerEndUser ? customerEndUser.Country : 'US',
            telephone: customerEndUser ? customerEndUser.Telephone : '',
            fax: customerEndUser ? customerEndUser.Fax : '',
            note: customerEndUser ? customerEndUser.Note : '',
        });
    }

    onSaveEndUserClick(evn, data) {
        this.setState({ isSaveEndUser: data.checked });
    }

    continueToRegister() {
        this.props.Action.fetchCustomerEndUsers(this.props.Reducer.csrftoken);
        window.scroll({ top: 0, behavior: 'smooth' });
        this.setState({
            // Formのクリア
            selectedEndUserId: -1,
            endUserName: '',
            contactName: '',
            email: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressLineThree: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
            telephone: '',
            fax: '',
            note: '',
            po: '',
            purchaseDate: '',
            isSaveEndUser: true,
            searchInvoiceNumber: '',
            invoices: [],
            // モーダルを閉じる
            modalOpenFlag: false,
        });
    }

    formattedHeader() {
        // 送信する値の整形
        return {
            endUserName: this.state.endUserName.trim(),
            contactName: this.state.contactName.trim(),
            email: this.state.email.trim(),
            addressLineOne: this.state.addressLineOne.trim(),
            addressLineTwo: this.state.addressLineTwo.trim(),
            addressLineThree: this.state.addressLineThree.trim(),
            city: this.state.city.trim(),
            state: this.state.state,
            zip: this.state.zip.trim(),
            country: this.state.country,
            telephone: this.state.telephone.trim(),
            fax: this.state.fax.trim(),
            note: this.state.note.trim(),
            po: this.state.po.trim(),
            purchaseDate: this.state.purchaseDate.trim(),
        };
    }

    formattedDetails() {
        return this.state.invoices.map(x => {
            return {
                companyId: x.CompanyId,
                invoiceNumber: x.InvoiceNumber,
                invoiceDate: moment(x.InvoiceDate).format('YYYY-MM-DD'),
                model: x.Model,
                serialNumber: x.SerialNumber,
                planId: x.PlanId,
            };
        });
    }

    onConfirmClick() {
        this.props.Action.invoiceFetchingError(false);

        const formattedHeader = this.formattedHeader();
        const formattedDetails = this.formattedDetails();
        // バリデーションチェック
        const validationErrors = this.validate(formattedHeader, formattedDetails);
        // バリデーションエラーがなければ、modalを開く
        this.setState({
            confirmModalOpenFlag: Object.keys(validationErrors).length === 0,
            validationErrors
        });
    }

    backToList() {
        this.props.setProtectionPlanTabStatus(Constants.PROTECTIONPLAN_STAT_LIST);
    }

    onInvoiceSearchClick() {
        let searchInvoiceNumber = this.state.searchInvoiceNumber.trim();

        if (!searchInvoiceNumber) {
            this.props.Action.invoiceFetchingError(false);
            window.alert('Please enter Invoice #.');
        } else {
            this.props.Action.fetchInvoices(this.props.Reducer.csrftoken, searchInvoiceNumber);
        }

        this.setState({ searchInvoiceNumber, validationErrors: {} });
    }

    onInvoiceDeleteClick(evn, data) {
        let invoices = this.state.invoices.filter(x => x.Id !== data.detailId);
        this.setState({ invoices });
    }

    validate(header, details) {
        const errors = {};

        if (header.endUserName == null || header.endUserName == '') {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_EMPTY;
        } else if (Functions.byteLengthOf(header.endUserName) > 100) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_LENGTH;
        }
        if (header.contactName == null || header.contactName == '') {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_EMPTY;
        } else if (Functions.byteLengthOf(header.contactName) > 100) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_LENGTH;
        }
        if (header.email == null || header.email == '') {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(header.email) > 100) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        // Address
        if (header.addressLineOne == null || header.addressLineOne == '') {
            errors.addressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(header.addressLineOne) > 100) {
            errors.addressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(header.addressLineTwo) > 100) {
            errors.addressLineTwo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(header.addressLineThree) > 100) {
            errors.addressLineThree = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (header.city == null || header.city == '') {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(header.city) > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (header.state == null || header.state == '') {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (header.zip == null || header.zip == '') {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(header.zip) > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (header.telephone == null || header.telephone == '') {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(header.telephone) > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(header.fax) > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (Functions.byteLengthOf(header.note) > 1000) {
            errors.note = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTE_LENGTH;
        }

        // PO
        if (header.po == null || header.po == '') {
            errors.po = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PONULL;
        } else if (Functions.byteLengthOf(header.po) > 100) {
            errors.po = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_POLENGTH;
        }
        if (header.purchaseDate == null || header.purchaseDate == '') {
            errors.purchaseDate = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_EMPTY;
        } else if (header.purchaseDate >= moment(tomorrow).format('YYYY-MM-DD')) {
            errors.purchaseDate = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_LATER_THAN_TODAY;
        }

        // Invoice Information
        if (details.length === 0) {
            errors.invoices = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_INVOICES_EMPTY;
        }

        return errors;
    }

    // 渡されたmodelNameに登録可能な保証プランのリストを返す
    getRepairServicePlans(modelName) {
        // ModelRuleMasterから、パターンが一致するModelRuleを洗い出す
        const modelMatchResults = this.props.Reducer.modelRuleMaster.map(modelRule => ({
            id: modelRule.Id,
            ...modelRule.isModelMatch(modelName),
        }));

        // 洗い出したModelRuleが対象となっている保証プランのIdを洗い出す
        const repairServicePlanMasterIdSet = new Set();
        modelMatchResults.forEach(result => {
            if (!result.forward && !result.exact) { return; }
            this.props.Reducer.modelRulesForRepairServicePlanCoverage
                .filter(coverage => {
                    if (coverage.ModelRuleMasterId != result.id) { return false; }
                    if (coverage.isMatchPatternForwardMatching()) { return result.forward; }
                    if (coverage.isMatchPatternExactMatching()) { return result.exact; }
                    return false;
                })
                .forEach(x => repairServicePlanMasterIdSet.add(x.RepairServicePlanMasterId));
        });

        // 有効な保証プランを洗い出して返す
        return this.selectablePlanFiltering().filter(x => repairServicePlanMasterIdSet.has(x.Id));
    };

    selectablePlanFiltering() {
        if (!this.state.purchaseDate) {
            return [];
        }
        const dateFormat = 'YYYY-MM-DD';
        const compareValue = moment(this.state.purchaseDate).format(dateFormat);

        const displayItems = this.props.Reducer.repairServicePlanMaster.filter(x => {
            const effectiveDate = moment(x.EffectiveDate).format(dateFormat);
            if (compareValue < effectiveDate) {
                return false;
            }
            if (x.ExpiryDate == null) {
                return true;
            }
            const expiryDate = moment(x.ExpiryDate).format(dateFormat);
            return compareValue <= expiryDate;
        });
        return displayItems;
    }

    render() {
        const customerEndUserOptions = this.props.Reducer.customerEndUsers
            .sort((a, b) => {
                if (a.EndUserName != b.EndUserName) {
                    return a.EndUserName < b.EndUserName ? -1 : 1;
                }
                if (a.ContactName != b.ContactName) {
                    return a.ContactName < b.ContactName ? -1 : 1;
                }
                return 0;
            }).map(x => {
                return { key: x.Id, value: x.Id, text: `${x.EndUserName} (${x.ContactName})` };
            }).concat([{ key: -1, value: -1, text: 'Other (please specify)' }]);;
        let stateOptions = this.props.Reducer.stateProvinceCodeMaster.map(x => {
            return { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` };
        });

        let errorMessage = null;
        if (Object.keys(this.state.validationErrors).length !== 0) {
            errorMessage = <Message error>
                {Object.values(this.state.validationErrors).map(message => <>{message}<br /></>)}
            </Message>;
        } else if (this.props.Reducer.invoiceFetchingError || this.props.Reducer.customerEndUsersFetchingError) {
            errorMessage = <ErrorMessage active content={this.props.Reducer.errorMessage} />;
        }

        const confirmButton =
            <div style={{ marginLeft: 'auto' }}>
                <Button content='Confirm' icon='check' color='blue' onClick={this.onConfirmClick} />
            </div>;

        const invoiceDetails = (
            <Table striped celled>
                <Table.Header >
                    <Table.Row>
                        <Table.HeaderCell content='Invoice #' />
                        <Table.HeaderCell content='Invoice date' />
                        <Table.HeaderCell content='Model #' />
                        <Table.HeaderCell content='Serial #' />
                        <Table.HeaderCell content='Plan' />
                        <Table.HeaderCell content='Delete' collapsing />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.state.invoices.map(invoice => {
                        const selectedPlan = this.props.Reducer.repairServicePlanMaster.find(x => x.Id == invoice.PlanId);
                        return <Table.Row>
                            <Table.Cell content={invoice.InvoiceNumber} />
                            <Table.Cell content={moment(invoice.InvoiceDate).format('MM/DD/YY')} />
                            <Table.Cell content={invoice.Model} />
                            <Table.Cell content={invoice.SerialNumber} />
                            <Table.Cell content={selectedPlan ? selectedPlan.PlanName : '-'} />
                            <Table.Cell>
                                <Button color='red' icon='x' onClick={this.onInvoiceDeleteClick} detailId={invoice.Id} />
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        );

        return (
            <>
                <Header as='h2' icon='shield' content={`Protection Plan (Register)`} />
                {errorMessage}

                <Segment>
                    <Header icon='user' content='End-user information' />
                    <Divider />
                    <Form>
                        <Form.Dropdown selection search label='Select end-user' width={8} options={customerEndUserOptions}
                            value={this.state.selectedEndUserId} onChange={this.onSelectEndUserChange}
                        />
                        <Form.Group widths='equal'>
                            <Form.Input required label='End-user name' name='endUserName' onChange={this.onTextChange}
                                value={this.state.endUserName} error={!!this.state.validationErrors.endUserName} />
                            <Form.Input required label='Contact name' name='contactName' onChange={this.onTextChange}
                                value={this.state.contactName} error={!!this.state.validationErrors.contactName} />
                            <Form.Input required label='Email' name='email' onChange={this.onTextChange}
                                value={this.state.email} error={!!this.state.validationErrors.email} />
                        </Form.Group>
                        <Form.Input required label='Address' name='addressLineOne' width={8} onChange={this.onTextChange}
                            value={this.state.addressLineOne} className='margin-btm' error={!!this.state.validationErrors.addressLineOne} />
                        <Form.Input name='addressLineTwo' width={8} className='margin-btm' onChange={this.onTextChange}
                            value={this.state.addressLineTwo} error={!!this.state.validationErrors.addressLineTwo} />
                        <Form.Input name='addressLineThree' width={8} onChange={this.onTextChange}
                            value={this.state.addressLineThree} error={!!this.state.validationErrors.addressLineThree} />
                        <Form.Group>
                            <Form.Input required label='City' name='city' width={4} onChange={this.onTextChange}
                                value={this.state.city} error={!!this.state.validationErrors.city} />
                            <Form.Dropdown required search selection label='State' name='state' width={4} placeholder='Select state'
                                options={stateOptions} onChange={this.onTextChange} value={this.state.state}
                                error={!!this.state.validationErrors.state} />
                            <Form.Input required label='Zip/Postal code' name='zip' width={4} onChange={this.onTextChange}
                                value={this.state.zip} error={!!this.state.validationErrors.zip} />
                            <Form.Input label='Country' width={4} value={this.state.country}>
                                <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                            </Form.Input>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input required label='Telephone' name='telephone' width={4} onChange={this.onTextChange}
                                value={this.state.telephone} error={!!this.state.validationErrors.telephone} />
                            <Form.Input label='Fax' name='fax' width={4} onChange={this.onTextChange}
                                value={this.state.fax} error={!!this.state.validationErrors.fax} />
                        </Form.Group>
                        <Form.TextArea label='Note' name='note' onChange={this.onTextChange} value={this.state.note}
                            error={!!this.state.validationErrors.note} />
                        <Form.Checkbox label='Save this end-user' checked={this.state.isSaveEndUser} onChange={this.onSaveEndUserClick} />
                    </Form>
                </Segment>

                <Segment>
                    <Header icon='cart' content='Purchase information' />
                    <Divider />
                    <Form>
                        <Form.Group>
                            <Form.Input required label='PO' width={4}
                                value={this.state.po} onChange={this.onTextChange} name='po'
                                error={!!this.state.validationErrors.po}
                            />
                            <Form.Input required type='date' label='Purchase date' width={4}
                                value={this.state.purchaseDate} onChange={this.onTextChange} name='purchaseDate'
                                error={!!this.state.validationErrors.purchaseDate}
                                max={moment(today).format('YYYY-MM-DD')}
                            />
                        </Form.Group>
                    </Form>
                </Segment>

                <Segment>
                    <Header icon='file text' content='Invoice information' />
                    <Divider />
                    <Form style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Form.Input label='Invoice #' width={4} placeholder='Enter Invoice #'
                            value={this.state.searchInvoiceNumber} onChange={this.onTextChange} name='searchInvoiceNumber'
                            error={this.props.Reducer.invoiceFetchingError}
                            action={
                                <Button primary type='button' content='Search' onClick={this.onInvoiceSearchClick} />
                            }
                        />
                        <Form.Field style={{ marginLeft: 'auto' }}>
                            <Button color='green' type='button'
                                onClick={() => this.setState({ planModalOpenFlag: true })}
                            >View plan list
                            </Button>
                        </Form.Field>
                        <PlanModal
                            modalOpen={this.state.planModalOpenFlag}
                            purchaseDate={this.state.purchaseDate}
                            onCloseClick={() => this.setState({ planModalOpenFlag: false })}
                            selectablePlanFiltering={this.selectablePlanFiltering}
                        />
                    </Form>
                    {this.state.invoices.length ? invoiceDetails : null}
                </Segment>
                <div style={{ display: 'flex' }}>{confirmButton}</div>
                {this.state.confirmModalOpenFlag ?
                    <ProtectionPlanConfirm
                        modalOpen={this.state.confirmModalOpenFlag}
                        onCloseClick={() => this.setState({ confirmModalOpenFlag: false })}
                        header={this.formattedHeader()}
                        invoices={this.formattedDetails()}
                        isSaveEndUser={this.state.isSaveEndUser}
                    /> : null}

                <Dimmer page
                    active={this.props.Reducer.invoiceFetching
                        || this.props.Reducer.customerEndUsersFetching || this.props.Reducer.protectionPlanListFetching}
                >
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
                <SaveCompleteModal
                    modalOpen={this.state.modalOpenFlag}
                    continueToRegister={this.continueToRegister}
                    backToList={this.backToList}
                />
            </>
        )
    }
}

class SaveCompleteModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal dimmer open={this.props.modalOpen} size='tiny'>
                <Modal.Header>
                    <Header icon='check' content='Saving is completed!' />
                </Modal.Header>
                <Modal.Content>
                    <Header as='h3'>Please select the next operation.</Header>
                    <Segment compact basic style={{ margin: 'auto' }}>
                        <Button color='blue' content='Continue to register' onClick={this.props.continueToRegister}
                            className='margin-btm' style={{ display: 'block' }} />
                        <Button color='blue' content='Go to History screen' onClick={this.props.backToList}
                            className='margin-btm' style={{ display: 'block' }} />
                    </Segment>
                </Modal.Content>
            </Modal>
        );
    }
}

class PlanModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal dimmer open={this.props.modalOpen}>
                <Modal.Header>
                    <Header content='Plan list' />
                </Modal.Header>
                <Modal.Content>
                    <Message info content={Messages.REPAIRREQUEST_MSG_PROTECTIONPLAN_PLANLIST} />
                    <Table striped celled>
                        <Table.Header >
                            <Table.Row>
                                <Table.HeaderCell content='Plan name' />
                                <Table.HeaderCell content='Description' />
                                <Table.HeaderCell content='Price' />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.selectablePlanFiltering().map(p => (
                                <Table.Row>
                                    <Table.Cell>{p.PlanName}</Table.Cell>
                                    <Table.Cell>{p.Description}</Table.Cell>
                                    <Table.Cell>${Functions.toNumberToLocaleString(p.getPrice(this.props.purchaseDate), 2)}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={this.props.onCloseClick} />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectionPlanEdit);
