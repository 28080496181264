import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from './Root.js';

import store from './App';
import * as Constant from './Constants/Constant';
import Request from './Components/Shipping/NewShipment/Request.js';
import History from './Components/Shipping/History/History.js';
import ContactUs from './Components/ContactUs/ContactUs';
import UserSetting from './Components/UserSetting/UserSetting.js';
import ProtectionPlan from './Components/ProtectionPlan/ProtectionPlan.js';
import ComponentSelect from './Components/ComponentSelect/ComponentSelect.js';

export class Tab extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.Action.masterDataFetch(this.props.Reducer.csrftoken);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Reducer.masterDataIsLoading && !this.props.Reducer.masterDataIsLoading && !this.props.Reducer.masterDataIsLoadingError) {
            this.props.Action.setDisclaimer(this.props.Reducer.userInformation.FirstLogin);
        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    render() {
        let component;
        switch (this.props.Reducer.tabStatus) {
            case Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT:
                component = <Request />;
                break;
            case Constant.REPAIRREQUEST_STAT_HISTORY:
                component = <History />;
                break;
            case Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY:
            case Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER:
                component = <ProtectionPlan />;
                break;
            case Constant.REPAIRREQUEST_STAT_USERSETTING:
                component = <UserSetting />;
                break;
            case Constant.REPAIRREQUEST_STAT_CONATCTUS:
                component = <ContactUs />;
                break;
            case Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT:
                component = <ComponentSelect />;
                break;
            default:
                component = <div>No content...</div>;
                break;
        }

        return (
            <div className="App">
                {component}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tab);
