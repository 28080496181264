"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.CustomerNumber = '';
        this.EndUserName = '';
        this.ContactName = '';
        this.Email = '';
        this.AddressLineOne = '';
        this.AddressLineTwo = '';
        this.AddressLineThree = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';
        this.Telephone = '';
        this.Fax = '';
        this.Note = '';
        this.CreateDate = null;
        this.CreateByUserId = '';
        this.CreateByUserName = '';
        this.UpdateDate = null;
        this.UpdateByUserId = '';
        this.UpdateByUserName = '';

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CustomerNumber = dataObject.customerNumber;
                this.EndUserName = dataObject.endUserName;
                this.ContactName = dataObject.contactName;
                this.Email = dataObject.email;
                this.AddressLineOne = dataObject.addressLineOne;
                this.AddressLineTwo = dataObject.addressLineTwo;
                this.AddressLineThree = dataObject.addressLineThree;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
                this.Telephone = dataObject.telephone;
                this.Fax = dataObject.fax;
                this.Note = dataObject.note;
                this.CreateDate = new Date(dataObject.createDate);
                this.CreateByUserId = dataObject.createByUserId;
                this.CreateByUserName = dataObject.createByUserName;
                this.UpdateDate = new Date(dataObject.updateDate);
                this.UpdateByUserId = dataObject.updateByUserId;
                this.UpdateByUserName = dataObject.updateByUserName;
            }
            catch (e) {
                throw 'Data import error @ CustomerEndUsers data.';
            }
        }
    }
}
