import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js'

import { Container, Segment, Form, Button, Message, Dimmer, Loader, Header, Icon } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            customerNumber: '',

            validationErrors: {},
            loading: false,
            loadingError: '',
            loadingSuccess: false,
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.getResetParam = this.getResetParam.bind(this);
        this.toLoginScreen = this.toLoginScreen.bind(this);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    onFormChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onSubmitClick() {
        let validationErrors = {};
        if (!this.state.userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_USERID;
        }
        if (!this.state.customerNumber) {
            validationErrors.customerNumber = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CUSTOMERNUMBER;
        }
        this.setState({ validationErrors, loadingError: '' });
        if (!Object.keys(validationErrors).length) {
            this.getResetParam();
        }
    }

    getResetParam() {
        this.setState({ loading: true, loadingSuccess: false });
        fetch(AppConfig.ApiUrlLogin + '/resetParam', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: this.state.userId, customerNumber: this.state.customerNumber })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.setState({ loading: false, loadingSuccess: true });
            })
            .catch(e => {
                this.setState({ loading: false, loadingError: e.message });
            });
    }

    toLoginScreen() {
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_LOGIN);
    }

    render() {
        let errorMessage = '';
        if (Object.keys(this.state.validationErrors).length) {
            let errors = Object.keys(this.state.validationErrors).map(x => <>{this.state.validationErrors[x]}<br /></>);
            errorMessage = <Message error>{errors}</Message>;
        } else if (this.state.loadingError) {
            errorMessage = <ErrorMessage content={this.state.loadingError} active page />;
        }

        return (
            <Container style={{ 'minHeight': '50vh' }}>
                <Segment style={{ maxWidth: 562.5, margin: 'auto' }}>
                    {errorMessage}
                    <Form size='big'>
                        <Form.Input fluid label='User ID' icon='user' iconPosition='left' name='userId' onChange={this.onFormChange} value={this.state.userId} error={!!this.state.validationErrors.userId} />
                        <Form.Input fluid label='Customer number' icon='building' iconPosition='left' name='customerNumber' onChange={this.onFormChange} value={this.state.customerNumber} error={!!this.state.validationErrors.customerNumber} />
                        <Button fluid color='blue' content='Submit' size='huge' onClick={this.onSubmitClick} />
                    </Form>
                    <Segment basic size='big'>
                        <a className='link_style' onClick={this.toLoginScreen}>Back to login</a>
                    </Segment>
                </Segment>
                <Dimmer active={this.state.loading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
                <Dimmer active={this.state.loadingSuccess} page>
                    <div>
                        <Header as='h2' icon inverted>
                            <Icon name='mail' />
                            {Messages.REPAIRREQUEST_MSG_SUCCESSHEADER_FORGOTPASSWORD}
                            <Header.Subheader>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_FORGOTPASSWORD}</Header.Subheader>
                        </Header>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={this.toLoginScreen}>
                            <Icon name='check' />
                            OK
                        </Button>
                    </div>
                </Dimmer>
            </Container>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
