import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Dimmer, Loader, Segment } from 'semantic-ui-react';

import * as Constant from '../../Constants/Constant';
import { mapDispatchToProps, mapStateToProps } from '../../Root';
import ErrorMessage from '../ErrorMessage';
import SelectItem from './SelectItem';

export class ComponentSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let errorMessage = '';
        if (this.props.Reducer.masterDataIsLoadingError || this.props.Reducer.logoutingError || this.props.Reducer.manualDownloadingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        return (
            <>
                {errorMessage}
                <Segment placeholder basic>
                    <Card.Group centered>
                        <SelectItem
                            icon='box'
                            header='Shipping'
                            subheader='Request shipping label for repair'
                            nextTabStatus={Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT}
                        />
                        <SelectItem
                            icon='shield'
                            header='Protection Plan'
                            subheader='Register protection plan for sales product'
                            nextTabStatus={Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER}
                        />
                    </Card.Group>
                </Segment>
                <Dimmer active={this.props.Reducer.masterDataIsLoading || this.props.Reducer.fileDownloading || this.props.Reducer.customerAddressBookFetching} page>
                    <Loader content="Loading..." size="huge"></Loader>
                </Dimmer>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentSelect);
