import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';

import { Modal, Button, Segment, Header, Table, Grid, Dimmer, Loader } from 'semantic-ui-react';

import Functions from '../../../Functions';
import * as Constant from '../../../Constants/Constant.js';
import * as Messages from '../../../Resources/Messages';
import ErrorMessage from '../../ErrorMessage.js';

export class HistoryDetail extends React.Component {
    constructor(props) {
        super(props);

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onFileDownloadClick = this.onFileDownloadClick.bind(this);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    componentWillUnmount() {
        this.props.Action.fileDownloadingError(false);
    }

    onCloseClick(evn, data) {
        if (!this.props.Reducer.fileDownloading) {
            this.props.onModalButtonClick(evn, data);
        }
    }

    onFileDownloadClick(evn, data) {
        this.props.Action.fileDownload(this.props.Reducer.csrftoken, data.labelType, this.props.id);
    }

    render() {
        let segmentStyle = { height: '100%' };
        let marginRight = { marginRight: 20, display: 'inline-block' };

        let errorMessage = '';
        if (this.props.Reducer.fileDownloadingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active />;
        }

        let repairRequest = this.props.Reducer.repairRequests.find(x => x.Id == this.props.id);

        return (
            <Modal open size='fullscreen' id='fullscreenModal' onClose={this.onCloseClick}>
                <Modal.Content scrolling style={{ maxHeight: '80vh' }}>
                    {errorMessage}
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='info' content='Basic information' dividing />
                                    <dl>
                                        <div style={marginRight}>
                                            <dt>No.</dt>
                                            <dd>{repairRequest.Id}</dd>
                                        </div>
                                        <div style={marginRight}>
                                            <dt>Requested date</dt>
                                            <dd>{Functions.dateForm(repairRequest.RequestedDate)}</dd>
                                        </div>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='download' content='File download' dividing />
                                    <Button primary icon='file image outline' content='Shipping label download' labelType={Constant.FILEDOWNLOAD_SHIPPINGLABEL} onClick={this.onFileDownloadClick} />
                                    <Button primary icon='file pdf outline' content='Packing slip download' labelType={Constant.FILEDOWNLOAD_PACKINGSLIP} onClick={this.onFileDownloadClick} />
                                    <p className='red'>{Messages.REPAIRREQUEST_MSG_FILEDOWNLOAD_PACKINGSLIP}</p>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='building' content='Customer information' dividing />
                                    <dl>
                                        <div style={marginRight}>
                                            <dt>Contact name</dt>
                                            <dd>{repairRequest.ContactName}</dd>
                                        </div>
                                        <div style={marginRight}>
                                            <dt>Email</dt>
                                            <dd>{repairRequest.Email}</dd>
                                        </div>
                                        <dt>Address</dt>
                                        <dd>{repairRequest.AddressLineOne}</dd>
                                        <dd>{repairRequest.AddressLineTwo != '' ? repairRequest.AddressLineTwo : '-'}</dd>
                                        <dd>{repairRequest.AddressLineThree != '' ? repairRequest.AddressLineThree : '-'}</dd>
                                        <dd>{repairRequest.City}, {repairRequest.State} {repairRequest.Zip}</dd>
                                        <dd>{repairRequest.Country}</dd>
                                        <div style={marginRight}>
                                            <dt>Telephone</dt>
                                            <dd>{repairRequest.Telephone}</dd>
                                        </div>
                                        <div style={marginRight}>
                                            <dt>Fax</dt>
                                            <dd>{repairRequest.Fax != '' ? repairRequest.Fax : '-'}</dd>
                                        </div>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment style={segmentStyle}>
                                    <Header icon='shipping' content='Shipping information' dividing />
                                    <dl>
                                        <dt>Shipping option</dt>
                                        <dd>{this.props.Reducer.upsServiceCodes.find(x => x.value == repairRequest.UpsServiceCode).text}</dd>
                                        <dt>Ship to</dt>
                                        <dd>{repairRequest.ShipToDescription}</dd>
                                        <dt>Address</dt>
                                        <dd>{repairRequest.ShipToAddress}</dd>
                                        <dd>{repairRequest.ShipToCity}, {repairRequest.ShipToState} {repairRequest.ShipToZip}</dd>
                                        <dd>{repairRequest.ShipToCountry}</dd>
                                        <dt>Weight & Ship tracking #</dt>
                                        <dd>
                                            <ol class='weight-shipTrackingNumber'>
                                                {repairRequest.TrackingNumberDetails.map(x => {
                                                    return <li>{x.Weight} lbs ({x.TrackingNumber})</li>
                                                })}
                                            </ol>
                                        </dd>
                                    </dl>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header icon='tag' content='Repair details' dividing />
                                    <Table compact celled style={{ whiteSpace: 'nowarp' }}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell content='Category' collapsing />
                                                <Table.HeaderCell content='Model' collapsing />
                                                <Table.HeaderCell content='Serial #' collapsing />
                                                <Table.HeaderCell content='Problem description' />
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {repairRequest.RepairDetails.sort((a, b) => {
                                                if (a.Category == b.Category) {
                                                    if (a.Model == b.Model) {
                                                        return a.SerialNumber < b.SerialNumber ? -1 : 1;
                                                    }
                                                    return a.Model < b.Model ? -1 : 1;
                                                }
                                                return a.Category < b.Category ? -1 : 1;
                                            }).map(detail => {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell content={detail.Category} />
                                                        <Table.Cell content={detail.Model} />
                                                        <Table.Cell content={detail.SerialNumber} />
                                                        <Table.Cell>
                                                            <pre style={{ whiteSpace: 'pre-line' }}>{detail.ProblemDescription}</pre>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Close' onClick={this.onCloseClick} />
                </Modal.Actions>
                <Dimmer active={this.props.Reducer.fileDownloading}>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryDetail);
