"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.Price = 0;
        this.EffectiveDate = null;
        this.ExpiryDate = null;

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.Price = dataObject.price;
                this.EffectiveDate = dataObject.effectiveDate ? new Date(dataObject.effectiveDate) : null;
                this.ExpiryDate = dataObject.expiryDate ? new Date(dataObject.expiryDate) : null;
            }
            catch (e) {
                throw "Data import error @ RepairServicePlanAmount data.";
            }
        }
    }
}