import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Segment, Button, Input, Table, Dimmer, Loader, Pagination, Form, Checkbox } from 'semantic-ui-react';
import { saveAs } from 'file-saver';

import moment from 'moment';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';
import * as Constants from '../../../Constants/Constant.js';
import ErrorMessage from '../../ErrorMessage.js';
import Functions from '../../../Functions.js';

const defaultSearchConditions = {
    customer: '',
    endUserName: '',
    createdDateFrom: '',
    createdDateTo: '',
    po: '',
    purchaseDateFrom: '',
    purchaseDateTo: '',
    invoiceNumber: '',
    model: '',
    serialNumber: '',
    showReturnedItems: false,
};

export class ProtectionPlanList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchConditions: defaultSearchConditions,
            currentPage: 1,
        }

        this.onSearchConditionChange = this.onSearchConditionChange.bind(this);
        this.searchConditionClear = this.searchConditionClear.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onViewDetailClick = this.onViewDetailClick.bind(this);
        this.protectionPlanFiltering = this.protectionPlanFiltering.bind(this);
        this.onShowReturnedItemsClick = this.onShowReturnedItemsClick.bind(this);
        this.onCsvOutputClick = this.onCsvOutputClick.bind(this);
    }

    componentDidMount() {
        const isAllItem = this.props.Reducer.protectionPlans.length === 0;
        this.props.Action.fetchProtectionPlanList(this.props.Reducer.csrftoken, isAllItem);
    }

    componentWillUnmount() {
        this.props.Action.protectionPlanListFetchingError(false);
    }

    onSearchConditionChange(evn, data) {
        const searchConditions = Object.assign({}, this.state.searchConditions);
        searchConditions[data.name] = data.value;
        this.setState({ searchConditions, currentPage: 1 });
    }

    searchConditionClear() {
        this.setState({ searchConditions: defaultSearchConditions, currentPage: 1 });
    }

    onPageChange(evn, data) {
        this.setState({ currentPage: data.activePage });
    }

    onViewDetailClick(evn, data) {
        this.props.setProtectionPlanTabStatus(Constants.PROTECTIONPLAN_STAT_VIEW, data.headerId);
    }

    onShowReturnedItemsClick() {
        const searchConditions = Object.assign({}, this.state.searchConditions);
        searchConditions.showReturnedItems = !this.state.searchConditions.showReturnedItems;
        this.setState({ searchConditions, currentPage: 1 });
    }

    protectionPlanFiltering() {
        let filteredItems = this.props.Reducer.protectionPlans.sort((a, b) => b.Id - a.Id);

        const {
            customer, endUserName, createdDateFrom, createdDateTo,
            po, purchaseDateFrom, purchaseDateTo,
            invoiceNumber, model, serialNumber, showReturnedItems
        } = this.state.searchConditions;
        if (customer) {
            const lowercase_customer = customer.toLowerCase();
            filteredItems = filteredItems.filter(
                x => x.CustomerName.toLowerCase().includes(lowercase_customer)
                    || x.CustomerNumber.toLowerCase().includes(lowercase_customer));
        }
        if (endUserName) {
            const lowercase_endUserName = endUserName.toLowerCase();
            filteredItems = filteredItems.filter(x => x.EndUserName.toLowerCase().includes(lowercase_endUserName));
        }
        if (createdDateFrom) {
            const dateFrom = new Date(createdDateFrom).setHours(0, 0, 0, 0);
            filteredItems = filteredItems.filter(x => dateFrom <= x.CreatedDate);
        }
        if (createdDateTo) {
            const dateTo = new Date(createdDateTo).setHours(23, 59, 59, 999);
            filteredItems = filteredItems.filter(x => x.CreatedDate <= dateTo);
        }
        if (po) {
            const lowercase_po = po.toLowerCase();
            filteredItems = filteredItems.filter(x => x.PO.toLowerCase().includes(lowercase_po));
        }
        if (purchaseDateFrom) {
            const dateFrom = new Date(purchaseDateFrom).setHours(0, 0, 0, 0);
            filteredItems = filteredItems.filter(x => dateFrom <= x.PurchaseDate);
        }
        if (purchaseDateTo) {
            const dateTo = new Date(purchaseDateTo).setHours(23, 59, 59, 999);
            filteredItems = filteredItems.filter(x => x.PurchaseDate <= dateTo);
        }
        if (invoiceNumber) {
            filteredItems = filteredItems
                .map(header => {
                    const newHeader = Object.assign({}, header);
                    newHeader.Details = newHeader.Details.filter(x => x.InvoiceNumber.includes(invoiceNumber));
                    return newHeader;
                })
                .filter(x => x.Details.length);
        }
        if (model) {
            const lowercase_model = model.toLowerCase();
            filteredItems = filteredItems
                .map(header => {
                    const newHeader = Object.assign({}, header);
                    newHeader.Details = newHeader.Details.filter(x => x.Model.toLowerCase().includes(lowercase_model));
                    return newHeader;
                })
                .filter(x => x.Details.length);
        }
        if (serialNumber) {
            const lowercase_serialNumber = serialNumber.toLowerCase();
            filteredItems = filteredItems
                .map(header => {
                    const newHeader = Object.assign({}, header);
                    newHeader.Details = newHeader.Details.filter(x => x.SerialNumber.toLowerCase().includes(lowercase_serialNumber));
                    return newHeader;
                })
                .filter(x => x.Details.length);
        }

        // 返品/削除されたInvoiceは表示しない
        if (!showReturnedItems) {
            filteredItems = filteredItems
                .map(header => {
                    const newHeader = Object.assign({}, header);
                    newHeader.Details = newHeader.Details.filter(x => !x.IsReturned);
                    return newHeader;
                })
                .filter(x => x.Details.length);
        }

        return filteredItems;
    }

    onCsvOutputClick() {
        const dateFormat = 'MM/DD/YYYY';
        const emptyLine = ',,,,,,,,,,,,,,,,,,,,,,,,,,,';

        const searchCustomerValue = this.state.searchConditions.customer ? this.state.searchConditions.customer : '-';
        const searchEndUserNameValue = this.state.searchConditions.endUserName ? this.state.searchConditions.endUserName : '-';
        const searchInvoiceNumberValue = this.state.searchConditions.invoiceNumber ? this.state.searchConditions.invoiceNumber : '-';
        const searchRegisteredDateFromValue = this.state.searchConditions.createdDateFrom
            ? moment(this.state.searchConditions.createdDateFrom).format(dateFormat)
            : '';
        const searchRegisteredDateToValue = this.state.searchConditions.createdDateTo
            ? moment(this.state.searchConditions.createdDateTo).format(dateFormat)
            : '';
        const searchModelValue = this.state.searchConditions.model ? this.state.searchConditions.model : '-';
        const searchSerialNumberValue = this.state.searchConditions.serialNumber ? this.state.searchConditions.serialNumber : '-';
        const showReturnedItems = this.state.searchConditions.showReturnedItems ? 'Yes' : 'No';
        const searchConditions = [
            'Search conditions,,,,,,,,,,,,,,,,,,,,,,,,,,,',
            `Customer,${Functions.csvString(searchCustomerValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `End-user name,${Functions.csvString(searchEndUserNameValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `Invoice #,${Functions.csvString(searchInvoiceNumberValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `Model,${Functions.csvString(searchModelValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `Serial #,${Functions.csvString(searchSerialNumberValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `Registered date,${Functions.csvString(searchRegisteredDateFromValue + '-' + searchRegisteredDateToValue)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
            `Show returned items,${Functions.csvString(showReturnedItems)},,,,,,,,,,,,,,,,,,,,,,,,,,`,
        ];

        const protectionPlanData = [
            'Basic information,,,,,,End-user information,,,,,,,,,,,,,Purchase information,,Invoice information,,,,,,',
            'No.,Customer #,Customer name,Registered by,Registered date,Updated date,End-user name,Contact name,Email,Address line 1,Address line 2,Address line 3,City,State,Zip/Postal code,Country,Telephone,Fax,Note,PO,Purchase date,Returned?,Invoice #,Invoice date,Model,Serial #,Plan name,Plan amount'
        ];
        this.protectionPlanFiltering().forEach(protectionPlan => {
            const basicInformation = [
                protectionPlan.Id, // No.
                protectionPlan.CustomerNumber, // Customer #
                protectionPlan.CustomerName, // Customer name
                `${protectionPlan.CreatedByUserName} (${protectionPlan.CreatedByUserId})`, // Registered by
                moment(protectionPlan.CreatedDate).format(dateFormat), // Registered date
                moment(protectionPlan.UpdatedDate).format(dateFormat), // Updated date
            ];

            const stateProvinceCode = this.props.Reducer.stateProvinceCodeMaster.find(x => x.Code == protectionPlan.State);
            const endUserInformation = [
                protectionPlan.EndUserName, // End-user name
                protectionPlan.ContactName, // Contact name
                protectionPlan.Email, // Email
                protectionPlan.AddressLineOne, // Address line 1
                protectionPlan.AddressLineTwo ? protectionPlan.AddressLineTwo : '-', // Address line 2
                protectionPlan.AddressLineThree ? protectionPlan.AddressLineThree : '-', // Address line 3
                protectionPlan.City, // City
                stateProvinceCode.State, // State
                protectionPlan.Zip, // Zip/Postal code
                protectionPlan.Country, // Country
                protectionPlan.Telephone, // Telephone
                protectionPlan.Fax ? protectionPlan.Fax : '-', // Fax
                protectionPlan.Note ? protectionPlan.Note : '-', // Note
            ];

            const purchaseInformation = [
                protectionPlan.PO,  // PO
                moment(protectionPlan.PurchaseDate).format(dateFormat), //Purchase date
            ];

            const invoiceInformation = protectionPlan.Details.map(invoice => {
                const plan = this.props.Reducer.repairServicePlanMaster.find(x => x.Id == invoice.PlanId);
                return [
                    invoice.IsReturned ? 'Yes' : 'No', //ReturnedFlag
                    invoice.InvoiceNumber, // Invoice #
                    moment(invoice.InvoiceDate).format(dateFormat), // Invoice date
                    invoice.Model, // Model
                    invoice.SerialNumber, // Serial #
                    plan ? plan.PlanName : '-', // Plan name
                    plan ? plan.getPrice(protectionPlan.CreatedDate).toString() : '0', // Plan amount
                ];
            });

            // Invoiceの数だけ行を増やす
            const commonPart = [].concat(basicInformation, endUserInformation, purchaseInformation).map(x => Functions.csvString(x)).join(',');
            invoiceInformation.forEach(invoice => {
                const invoicePart = invoice.map(x => Functions.csvString(x)).join(',');
                protectionPlanData.push([commonPart, invoicePart].join(','));
            });
        });

        const contents = [...searchConditions, emptyLine, ...protectionPlanData].join('\n');

        // File download
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, contents], { type: "text/csv" });
        const filename = `Extended Services Portal_Protection plan_${moment().format('MMDDYYYY')}.csv`;
        saveAs(blob, filename);
    }

    render() {
        let errorMessage = null;
        if (this.props.Reducer.protectionPlanListFetchingError) {
            errorMessage = <ErrorMessage active page content={this.props.Reducer.errorMessage} />
        }

        const protectionPlanRows = [];
        this.protectionPlanFiltering().forEach(header => {
            const uniqueInvoiceMap = new Map(header.Details.map(x => [x.InvoiceNumber, x]));
            const uniqueInvoices = [...uniqueInvoiceMap.values()];

            uniqueInvoices.sort((a, b) => a.InvoiceNumber < b.InvoiceNumber ? -1 : 1).forEach(detail => {
                protectionPlanRows.push(
                    <Table.Row key={`${header.Id}-${detail.InvoiceNumber}`}>
                        <Table.Cell content={header.Id} />
                        {this.props.Reducer.userInformation.RepairCenterFlag ? <Table.Cell content={`${header.CustomerName} (#${header.CustomerNumber})`} /> : null}
                        <Table.Cell content={header.EndUserName} />
                        {this.props.Reducer.userInformation.RepairCenterFlag ? null : <Table.Cell content={header.PO} />}
                        {this.props.Reducer.userInformation.RepairCenterFlag ? null : <Table.Cell content={Functions.dateForm(header.PurchaseDate)} />}
                        <Table.Cell content={detail.InvoiceNumber} />
                        <Table.Cell content={Functions.dateForm(header.CreatedDate)} />
                        {this.props.Reducer.userInformation.RepairCenterFlag ? <Table.Cell content={Functions.dateForm(header.UpdatedDate)} /> : null}
                        <Table.Cell collapsing>
                            <Button icon='file' color='blue' headerId={header.Id} onClick={this.onViewDetailClick} />
                        </Table.Cell>
                    </Table.Row>
                );
            })
        });

        return (
            <>
                <Header as='h2' icon='shield' content='Protection Plan History' />
                {errorMessage}
                <Segment>
                    <Header content='Search Condition' icon='filter' />
                    <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Form.Group>
                            {this.props.Reducer.userInformation.RepairCenterFlag
                                ? <Form.Field inline>
                                    <label>Customer</label>
                                    <Input placeholder='Number / Name' value={this.state.searchConditions.customer} name='customer' onChange={this.onSearchConditionChange} />
                                </Form.Field>
                                : null
                            }
                            <Form.Field inline>
                                <label>End-user name</label>
                                <Input value={this.state.searchConditions.endUserName} name='endUserName' onChange={this.onSearchConditionChange} />
                            </Form.Field>
                            {this.props.Reducer.userInformation.RepairCenterFlag
                                ? null
                                : <Form.Field inline>
                                    <label>PO</label>
                                    <Input value={this.state.searchConditions.po} name='po' onChange={this.onSearchConditionChange} />
                                </Form.Field>
                            }
                            {this.props.Reducer.userInformation.RepairCenterFlag
                                ? null
                                : <Form.Field inline>
                                    <label>Purchase date</label>
                                    <Input type='date' value={this.state.searchConditions.purchaseDateFrom}
                                        name='purchaseDateFrom' onChange={this.onSearchConditionChange} />
                                    <span style={{ margin: 'auto 7px' }}>-</span>
                                    <Input type='date' value={this.state.searchConditions.purchaseDateTo}
                                        name='purchaseDateTo' onChange={this.onSearchConditionChange} />
                                </Form.Field>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline>
                                <label>Invoice #</label>
                                <Input value={this.state.searchConditions.invoiceNumber} name='invoiceNumber' onChange={this.onSearchConditionChange} />
                            </Form.Field>
                            <Form.Field inline>
                                <label>Model</label>
                                <Input value={this.state.searchConditions.model} name='model' onChange={this.onSearchConditionChange} />
                            </Form.Field>
                            <Form.Field inline>
                                <label>Serial #</label>
                                <Input value={this.state.searchConditions.serialNumber} name='serialNumber' onChange={this.onSearchConditionChange} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Button icon='x' color='red' onClick={this.searchConditionClear} />
                            <Form.Field inline>
                                <label>Registered date</label>
                                <Input type='date' value={this.state.searchConditions.createdDateFrom}
                                    name='createdDateFrom' onChange={this.onSearchConditionChange} />
                                <span style={{ margin: 'auto 7px' }}>-</span>
                                <Input type='date' value={this.state.searchConditions.createdDateTo}
                                    name='createdDateTo' onChange={this.onSearchConditionChange} />
                            </Form.Field>
                        </Form.Group>
                        {this.props.Reducer.userInformation.RepairCenterFlag
                            ? <Form.Group>
                                <Form.Field inline>
                                    <Checkbox label='Show returned items'
                                        checked={this.state.searchConditions.showReturnedItems} onClick={this.onShowReturnedItemsClick} />
                                </Form.Field>
                            </Form.Group>
                            : null}
                    </Form>
                </Segment >

                <div className='flex'>
                    <Pagination firstItem={null} lastItem={null} activePage={this.state.currentPage}
                        totalPages={Math.ceil(protectionPlanRows.length / 30)} onPageChange={this.onPageChange} />
                    {this.props.Reducer.userInformation.RepairCenterFlag
                        ? <>
                            <Button icon='refresh' content='Refresh' color='green' className='flex-floatRight'
                                onClick={() => this.props.Action.fetchProtectionPlanList(this.props.Reducer.csrftoken, false)} />
                            <Button content='CSV output' color='green' onClick={this.onCsvOutputClick} />
                        </>
                        : null}
                </div>

                <Table striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='No.' />
                            {this.props.Reducer.userInformation.RepairCenterFlag ? <Table.HeaderCell content='Customer' /> : null}
                            <Table.HeaderCell content='End-user name' />
                            {this.props.Reducer.userInformation.RepairCenterFlag ? null : <Table.HeaderCell content='PO' />}
                            {this.props.Reducer.userInformation.RepairCenterFlag ? null : <Table.HeaderCell content='Purchase date' />}
                            <Table.HeaderCell content='Invoice #' />
                            <Table.HeaderCell content='Registered date' />
                            {this.props.Reducer.userInformation.RepairCenterFlag ? <Table.HeaderCell content='Updated date' /> : null}
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {protectionPlanRows.slice((this.state.currentPage - 1) * 30, this.state.currentPage * 30)}
                    </Table.Body>
                </Table>
                <Dimmer active={this.props.Reducer.protectionPlanListFetching} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectionPlanList);
