import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../../Root.js';
import { Button, Dropdown, Form, Table, Segment, Modal, Icon, Header, Input, Divider, TextArea, Dimmer, Loader, Message, Popup, Grid } from 'semantic-ui-react';

import RepairRequest from '../../../Models/ViewModels/RepairRequest.js';
import RepairRequestDetail from '../../../Models/ViewModels/RepairRequestDetail.js';
import TrackingNumberDetail from '../../../Models/ViewModels/TrackingNumberDetail.js';
import Functions from '../../../Functions.js';
import * as Messages from '../../../Resources/Messages.js';
import RequestConfirm from './RequestConfirm.js';
import * as Constant from '../../../Constants/Constant.js';
import ErrorMessage from '../../ErrorMessage.js';

export class Request extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            repairRequest: new RepairRequest(),
            numberOfPackingBox: 1,
            selectedCustomerAddressBookId: 0,
            isSaveAddressBook: true,
            selectedShipToMasterId: 0,
            isConfirmModalOpen: false,

            errors: {},
        };

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onAddLineClick = this.onAddLineClick.bind(this);
        this.onModelDeleteClick = this.onModelDeleteClick.bind(this);
        this.onDetailTextChange = this.onDetailTextChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);
        this.initialization = this.initialization.bind(this);
        this.validate = this.validate.bind(this);
        this.onContactUsClick = this.onContactUsClick.bind(this);
        this.onFileDownloadClick = this.onFileDownloadClick.bind(this);
        this.onCalcButtonClick = this.onCalcButtonClick.bind(this);
        this.onWeightChange = this.onWeightChange.bind(this);
        this.weightDigitNumberValidation = this.weightDigitNumberValidation.bind(this);
        this.getSaveData = this.getSaveData.bind(this);
        this.confirmModalClose = this.confirmModalClose.bind(this);
        this.onShipToChange = this.onShipToChange.bind(this);
        this.onCustomerAddressBookChange = this.onCustomerAddressBookChange.bind(this);
        this.onSaveAddressBookChange = this.onSaveAddressBookChange.bind(this);
    }

    componentDidMount() {
        this.props.Action.customerAddressBookFetch(this.props.Reducer.csrftoken);
        this.initialization();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Reducer.requestSubmitting && !this.props.Reducer.requestSubmitting && !this.props.Reducer.requestSubmittingError) {
            this.setState({ isConfirmModalOpen: false });
        }
        else if (
            (!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading)
            || (!prevProps.Reducer.logouting && this.props.Reducer.logouting)
        ) {
            this.setState({ errors: {} });
        }
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization();
    }

    loadingErrorFlagInitialization() {
        this.props.Action.masterDataFetchingError(false);
        this.props.Action.logoutFetchingError(false);
        this.props.Action.fileDownloadingError(false);
        this.props.Action.completeRequestModal(false);
        this.props.Action.manualDownloadingError(false);
        this.props.Action.customerAddressBookFetchingError(false);
    }

    onAddLineClick() {
        let repairDetail = new RepairRequestDetail();
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        repairRequest.RepairDetails.push(repairDetail);
        this.setState({
            repairRequest: repairRequest,
        });
    }

    onModelDeleteClick(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        const { idx } = data;
        repairRequest.RepairDetails = repairRequest.RepairDetails.filter((x, index) => index != idx);
        this.setState({
            repairRequest: repairRequest,
        });
    }

    onDetailTextChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        const { name, idx, value } = data;
        repairRequest.RepairDetails[idx][name] = value;
        this.setState({
            repairRequest: repairRequest,
        });
    }

    onTextChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        const { name, value } = data;
        repairRequest[name] = value;
        this.setState({
            repairRequest: repairRequest,
        })
    }

    initialization() {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        let userInformation = this.props.Reducer.userInformation;

        repairRequest.UserName = userInformation.UserName;
        repairRequest.CustomerNumber = userInformation.CustomerNumber;
        repairRequest.ContactName = userInformation.UserName;
        repairRequest.Email = userInformation.UserId;
        repairRequest.AddressLineOne = userInformation.AddressLineOne;
        repairRequest.AddressLineTwo = userInformation.AddressLineTwo;
        repairRequest.AddressLineThree = userInformation.AddressLineThree;
        repairRequest.City = userInformation.City;
        if (this.props.Reducer.stateProvinceCodeMaster.some(x => x.Code == userInformation.State)) {
            repairRequest.State = userInformation.State;
        }
        repairRequest.Zip = userInformation.Zip;
        repairRequest.Country = "US";
        repairRequest.Telephone = userInformation.Telephone;
        repairRequest.Fax = userInformation.Fax;
        repairRequest.RepairDetails.push(new RepairRequestDetail());
        repairRequest.TrackingNumberDetails.push(new TrackingNumberDetail());

        this.setState({ repairRequest });
    }

    validate(request) {
        let errors = {};
        if (request.ContactName == null || request.ContactName == '') {
            errors.ContactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMENULL;
        } else if (Functions.byteLengthOf(request.ContactName) > 100) {
            errors.ContactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMELENGTH;
        }
        if (request.Email == null || request.Email == '') {
            errors.Email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(request.Email) > 100) {
            errors.Email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        if (request.AddressLineOne == null || request.AddressLineOne == '') {
            errors.AddressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(request.AddressLineOne) > 100) {
            errors.AddressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(request.AddressLineTwo) > 100) {
            errors.AddressLineTwo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(request.AddressLineThree) > 100) {
            errors.AddressLineThree = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (request.City == null || request.City == '') {
            errors.City = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(request.City) > 100) {
            errors.City = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (request.State == null || request.State == '') {
            errors.State = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (request.Zip == null || request.Zip == '') {
            errors.Zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(request.Zip) > 100) {
            errors.Zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (request.Telephone == null || request.Telephone == '') {
            errors.Telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(request.Telephone) > 100) {
            errors.Telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(request.Fax) > 100) {
            errors.Fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (request.UpsServiceCode == null || request.UpsServiceCode == '') {
            errors.UpsServiceCode = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_UPSSERVICECODENULL;
        }
        if (this.state.selectedShipToMasterId == null || this.state.selectedShipToMasterId == 0) {
            errors.ShipTo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SHIPTONULL;
        }
        request.TrackingNumberDetails.forEach((detail, idx) => {
            if (detail.Weight == null || detail.Weight == 0) {
                errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTNULL;
            } else if (detail.Weight <= 0 || detail.Weight > 150) {
                errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTNULL;
            } else if (!Number.isInteger(detail.Weight)) {
                // 少数だったら
                if (this.weightDigitNumberValidation(detail.Weight)) {
                    // 後ろから二文字目が「.」じゃなかったら少数が1桁じゃない
                    errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTDIGITNUMBERLIMIT;
                    errors.isDigitNumberLimit = idx;
                }
            }
        });
        if (request.RepairDetails.length == 0) {
            errors.RepairDetailIsNull = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_REPAIRDETAILSNULL;
        } else {
            request.RepairDetails.forEach(detail => {
                if (detail.Category == null || detail.Category == '') {
                    errors.Category = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYNULL;
                }
                if (detail.Model == null || detail.Model == '') {
                    errors.Model = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_MODELNULL;
                }
                if (detail.SerialNumber != null && detail.SerialNumber != '') {
                    if (Functions.byteLengthOf(detail.SerialNumber) > 255) {
                        errors.SerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERLENGTH;
                    } else if (!detail.SerialNumber.match(/^[A-Za-z0-9]*$/)) {
                        errors.SerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBER;
                    }
                }
                if (detail.ProblemDescription != null || detail.ProblemDescription != '') {
                    if (Functions.byteLengthOf(detail.ProblemDescription) > 255) {
                        errors.ProblemDescription = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONLENGTH;
                    }
                }
                if (!(detail.Category == null || detail.Category == '') && detail.Category == Constant.REQUOEST_CATEGORY_RADIO) {
                    if (detail.SerialNumber == null || detail.SerialNumber == '') {
                        errors.RadioSerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERNULL;
                    } else if (Functions.byteLengthOf(detail.SerialNumber) != 8) {
                        errors.RadioSerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERINCORRECT;
                    }
                    if (detail.ProblemDescription == null || detail.ProblemDescription == '') {
                        errors.RadioProblemDescription = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONNULL;
                    }
                }
            });
            if (!request.RepairDetails.some(x => x.Category == Constant.REQUOEST_CATEGORY_RADIO)) {
                // CategoryがRadioのモデルが一つも無かったら
                errors.Category = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYLIMIT;
            }
        }

        return errors;
    }

    weightDigitNumberValidation(weight) {
        // 後ろから二文字目が「.」じゃなかったら少数が1桁じゃない
        return weight.toString().slice(-2, -1) != '.';
    }

    onConfirmClick() {
        let repairRequest = this.getSaveData();
        let errors = this.validate(repairRequest);
        this.setState({
            errors,
            repairRequest,
            isConfirmModalOpen: Object.keys(errors).length == 0,
        });
        this.loadingErrorFlagInitialization();
    }

    getSaveData() {
        let saveData = {
            Id: Number(this.state.repairRequest.Id),
            ContactName: this.state.repairRequest.ContactName.trim(),
            Email: this.state.repairRequest.Email.trim().replace(/\s+/g, ''),
            AddressLineOne: this.state.repairRequest.AddressLineOne.trim(),
            AddressLineTwo: this.state.repairRequest.AddressLineTwo.trim(),
            AddressLineThree: this.state.repairRequest.AddressLineThree.trim(),
            City: this.state.repairRequest.City.trim(),
            State: this.state.repairRequest.State,
            Zip: this.state.repairRequest.Zip.trim(),
            Country: this.state.repairRequest.Country.trim(),
            Telephone: this.state.repairRequest.Telephone.trim(),
            Fax: this.state.repairRequest.Fax.trim(),
            ShipToDescription: this.state.repairRequest.ShipToDescription,
            ShipToAddress: this.state.repairRequest.ShipToAddress,
            ShipToCity: this.state.repairRequest.ShipToCity,
            ShipToState: this.state.repairRequest.ShipToState,
            ShipToZip: this.state.repairRequest.ShipToZip,
            ShipToCountry: this.state.repairRequest.ShipToCountry,
            UpsServiceCode: this.state.repairRequest.UpsServiceCode,

            RepairDetails: this.state.repairRequest.RepairDetails.map(x => Object.assign(x)),
            TrackingNumberDetails: this.state.repairRequest.TrackingNumberDetails.map(x => Object.assign(x)),
        };

        saveData.TrackingNumberDetails.forEach(x => {
            x.Weight = Number(x.Weight);
        });
        saveData.RepairDetails.forEach(detail => {
            detail.SerialNumber = detail.SerialNumber.trim().toUpperCase();
        });

        return saveData;
    }

    onCloseCompleteModal() {
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_HISTORY);
    }

    onContactUsClick() {
        this.props.Action.setTabStatus(Constant.REPAIRREQUEST_STAT_CONATCTUS);
    }

    onFileDownloadClick(evn, data) {
        this.props.Action.fileDownload(this.props.Reducer.csrftoken, data.labelType, this.props.Reducer.savedRequest.Id);
    }

    buttonForNumberOfPackingBox(calcType, num) {
        let content = '';
        if (calcType == 'addition') {
            content = '+' + num.toString();
        } else if (calcType == 'subtraction') {
            content = '-' + num.toString();
        }
        return <Form.Button circular content={content} calcType={calcType} num={num} onClick={this.onCalcButtonClick} />
    }

    onCalcButtonClick(evn, data) {
        if (evn.clientX == 0 && evn.clientY == 0) { return; }
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        let numberOfPackingBox = this.state.numberOfPackingBox;
        const { calcType, num } = data;
        if (calcType == 'addition') {
            numberOfPackingBox += num;
            if (numberOfPackingBox > this.props.Reducer.packageMaxCount) {
                numberOfPackingBox = this.props.Reducer.packageMaxCount;
            }
            while (repairRequest.TrackingNumberDetails.length < numberOfPackingBox) {
                repairRequest.TrackingNumberDetails.push(new TrackingNumberDetail());
            }
        } else if (calcType == 'subtraction') {
            numberOfPackingBox -= num;
            if (numberOfPackingBox <= 0) {
                numberOfPackingBox = 1;
            }
            repairRequest.TrackingNumberDetails = repairRequest.TrackingNumberDetails.slice(0, numberOfPackingBox);
        }
        this.setState({ repairRequest, numberOfPackingBox });
    }

    onWeightChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        let value = data.value.match(/[0-9\.]/g); // 0-9と"."だけを抜き出す
        let weight = '';
        if (data.value.match(/[\.]/g)?.length > 1) {
            // "."が2つ以上ある場合。2つめ以降の"."は削除
            let array = [];
            let dotFlag = false;
            value.forEach(x => {
                if (x != '.') {
                    array.push(x);
                } else {
                    if (!dotFlag) {
                        array.push(x);
                        dotFlag = true;
                    }
                }
            })
            weight = array.join('');
        } else {
            weight = value ? value.join('') : '';
        }
        repairRequest.TrackingNumberDetails[data.idx].Weight = weight;
        this.setState({ repairRequest });
    }

    confirmModalClose() {
        this.setState({ isConfirmModalOpen: false });
    }

    onShipToChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        let selectedShipToMasterId = data.value;
        let shipTo = this.props.Reducer.shipToMaster.find(x => x.ShipToId == selectedShipToMasterId);

        repairRequest.ShipToDescription = shipTo.ShipToDescription;
        repairRequest.ShipToAddress = shipTo.Address;
        repairRequest.ShipToCity = shipTo.City;
        repairRequest.ShipToState = shipTo.State;
        repairRequest.ShipToZip = shipTo.Zip;
        repairRequest.ShipToCountry = shipTo.Country;

        this.setState({
            repairRequest,
            selectedShipToMasterId,
        });
    }

    onCustomerAddressBookChange(evn, data) {
        if (this.state.selectedCustomerAddressBookId == data.value) { return; }

        let repairRequest = JSON.parse(JSON.stringify(this.state.repairRequest));
        const selectedCustomerAddressBookId = data.value;
        if (selectedCustomerAddressBookId === -1) {
            repairRequest.AddressLineOne = '';
            repairRequest.AddressLineTwo = '';
            repairRequest.AddressLineThree = '';
            repairRequest.City = '';
            repairRequest.State = '';
            repairRequest.Zip = '';
            repairRequest.Country = 'US';
            repairRequest.Telephone = '';
            repairRequest.Fax = '';
        } else {
            const address = selectedCustomerAddressBookId === 0
                ? this.props.Reducer.userInformation
                : this.props.Reducer.customerAddressBook.find(x => x.Id == selectedCustomerAddressBookId);

            repairRequest.AddressLineOne = address.AddressLineOne;
            repairRequest.AddressLineTwo = address.AddressLineTwo;
            repairRequest.AddressLineThree = address.AddressLineThree;
            repairRequest.City = address.City;
            repairRequest.State = address.State;
            repairRequest.Zip = address.Zip;
            repairRequest.Country = address.Country;
            repairRequest.Telephone = address.Telephone;
            repairRequest.Fax = address.Fax;
        }

        this.setState({
            repairRequest,
            selectedCustomerAddressBookId,
        });
    }

    onSaveAddressBookChange(evn, data) {
        this.setState({ isSaveAddressBook: data.checked });
    }

    render() {
        let defaultBtnColor = 'blue';
        let styleCell = { padding: '0.3em 0.5em' };
        let styleClear = { clear: 'both' };

        let customerAddressBookOptions = [{
            key: 0, value: 0,
            text: `${this.props.Reducer.userInformation.AddressLineOne} ${this.props.Reducer.userInformation.City}, ${this.props.Reducer.userInformation.State}`,
        }].concat(this.props.Reducer.customerAddressBook.map((x) => ({
            key: x.Id, value: x.Id, text: `${x.AddressLineOne} ${x.City}, ${x.State}`,
        })), [{ key: -1, value: -1, text: 'Other (please specify)' }]);
        let shipToOptions = this.props.Reducer.shipToMaster.map(shipTo => {
            return { text: shipTo.ShipToDescription, value: shipTo.ShipToId, key: shipTo.ShipToId };
        });
        let categoryOptions = this.props.Reducer.categoryMaster.map(x => {
            return { key: x.value, value: x.value, text: x.value };
        });
        let modelOptions = this.props.Reducer.tempModelMaster.map((x, idx) => {
            return { key: idx, value: x.Name, text: x.Name };
        });
        let stateOptions = this.props.Reducer.stateProvinceCodeMaster.map(x => {
            return { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` };
        });

        let repairRequest = this.state.repairRequest;
        let shippingOptions = [];
        this.props.Reducer.upsServiceCodes.forEach(x => {
            if (x.value == '02' && !this.props.Reducer.userInformation.SecondDayAirEnable) {
                return;
            }
            shippingOptions.push({ key: x.value, value: x.value, text: x.text });
        });

        let errorMessage = '';
        if (Object.keys(this.state.errors).length) {
            errorMessage = (
                <Message negative style={styleClear}>
                    {Object.keys(this.state.errors).map(k => {
                        if (k == 'isDigitNumberLimit') { return ''; }
                        return <>{this.state.errors[k]}<br /></>;
                    })}
                </Message>
            );
        } else if (this.props.Reducer.logoutingError || this.props.Reducer.manualDownloadingError || this.props.Reducer.customerAddressBookFetchingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        let completeModalErrorMessage = '';
        if (this.props.Reducer.fileDownloadingError) {
            completeModalErrorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active />;
        }

        let repairDetails = repairRequest.RepairDetails.map((x, idx) => {
            // Serial#とProblemDescriptionが必須かどうか
            let required = x.Category == Constant.REQUOEST_CATEGORY_RADIO;

            let serialNumberErrorFlag = false;
            if (this.state.errors.SerialNumber) {
                if (Functions.byteLengthOf(x.SerialNumber) > 255) {
                    serialNumberErrorFlag = true;
                }
            }
            if (this.state.errors.RadioSerialNumber && required) {
                if (x.SerialNumber == "" || x.SerialNumber == null || Functions.byteLengthOf(x.SerialNumber) != 8) {
                    serialNumberErrorFlag = true;
                }
            }

            let descriptionErrorFlag = false;
            if (this.state.errors.ProblemDescription) {
                if (Functions.byteLengthOf(x.ProblemDescription) > 255) {
                    descriptionErrorFlag = true;
                }
            }
            if (this.state.errors.RadioProblemDescription && required) {
                if (x.ProblemDescription == "" || x.ProblemDescription == null) {
                    descriptionErrorFlag = true;
                }
            }
            return (
                <Table.Row>
                    <Table.Cell>
                        <Dropdown
                            name='Category'
                            placeholder='Select category'
                            search selection
                            options={categoryOptions}
                            idx={idx}
                            onChange={this.onDetailTextChange}
                            value={x.Category}
                            error={this.state.errors.Category && (x.Category == "" || x.Category == null)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Dropdown
                            name='Model'
                            placeholder='Select model'
                            search selection
                            options={modelOptions}
                            idx={idx}
                            onChange={this.onDetailTextChange}
                            value={x.Model}
                            error={this.state.errors.Model && (x.Model == "" || x.Model == null)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Input
                            name='SerialNumber'
                            value={x.SerialNumber}
                            idx={idx}
                            onChange={this.onDetailTextChange}
                            error={serialNumberErrorFlag}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Form>
                            <TextArea
                                name='ProblemDescription'
                                style={{ width: '100%' }} rows={2}
                                className={descriptionErrorFlag ? 'Error-Zone' : null}
                                value={x.ProblemDescription}
                                idx={idx}
                                onChange={this.onDetailTextChange}
                            />
                        </Form>
                    </Table.Cell>
                    <Table.Cell>
                        <Button color='red' size='mini' icon='delete' idx={idx} onClick={this.onModelDeleteClick} />
                    </Table.Cell>
                </Table.Row>
            );
        });

        let requiredPopup = <Popup content={Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_REQUIRED} trigger={<Button icon='exclamation' circular size='mini' style={{ marginLeft: 10 }} />} />;

        let completeModal = this.props.Reducer.isCompleteModalOpen ?
            <Modal open>
                <Modal.Header>
                    <Header><Icon name='check' />Submit completed!</Header>
                </Modal.Header>
                <Modal.Content>
                    {completeModalErrorMessage}
                    <Segment basic>
                        <Grid>
                            <Grid.Column width={1} />
                            <Grid.Column width={6}>
                                <dl style={{ marginTop: 0 }}>
                                    <dt>No.</dt>
                                    <dd>{this.props.Reducer.savedRequest.Id}</dd>
                                    <dt>Weight & Ship tracking #</dt>
                                    <dd>
                                        <ol class='weight-shipTrackingNumber'>
                                            {this.props.Reducer.savedRequest.TrackingNumberDetails.map(x => {
                                                return <li>{x.Weight} lbs ({x.TrackingNumber})</li>
                                            })}
                                        </ol>
                                    </dd>
                                </dl>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Header>
                                    Please download shipping label and packing slip
                                    <Header.Subheader>(You can also download these from the History screen)</Header.Subheader>
                                </Header>
                                <Button primary icon='file image outline' content='Shipping label download' labelType={Constant.FILEDOWNLOAD_SHIPPINGLABEL} onClick={this.onFileDownloadClick} />
                                <Button primary icon='file pdf outline' content='Packing slip download' labelType={Constant.FILEDOWNLOAD_PACKINGSLIP} onClick={this.onFileDownloadClick} />
                                <p className='red'>{Messages.REPAIRREQUEST_MSG_FILEDOWNLOAD_PACKINGSLIP}</p>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.onCloseCompleteModal}>Close</Button>
                </Modal.Actions>
            </Modal> : "";

        return (
            <div>
                <Header as='h2' icon='box' content='New Shipping' />
                {errorMessage}
                <Segment>
                    <Header>
                        <Icon name='building' />
                        Customer information
                    </Header>
                    <Divider />
                    <Form size='small'>
                        <Form.Group>
                            <Form.Input required label='Contact name' name='ContactName' width={6} onChange={this.onTextChange} value={repairRequest.ContactName} error={this.state.errors.ContactName ? true : false} />
                            <Form.Input required label='Email' name='Email' width={6} onChange={this.onTextChange} value={repairRequest.Email} error={this.state.errors.Email ? true : false} />
                        </Form.Group>
                        <Form.Dropdown options={customerAddressBookOptions} selection search label='Select address' width={8}
                            value={this.state.selectedCustomerAddressBookId} onChange={this.onCustomerAddressBookChange} />
                        <Form.Input required label='Address' name='AddressLineOne' width={8} onChange={this.onTextChange} value={repairRequest.AddressLineOne} error={this.state.errors.AddressLineOne ? true : false} className='margin-btm'>
                            <input />
                        </Form.Input>
                        <Form.Input name='AddressLineTwo' width={8} onChange={this.onTextChange} value={repairRequest.AddressLineTwo} error={this.state.errors.AddressLineTwo ? true : false} className='margin-btm'>
                            <input />
                        </Form.Input>
                        <Form.Input name='AddressLineThree' width={8} onChange={this.onTextChange} value={repairRequest.AddressLineThree} error={this.state.errors.AddressLineThree ? true : false} >
                            <input />
                        </Form.Input>
                        <Form.Group>
                            <Form.Input required label='City' name='City' width={4} onChange={this.onTextChange} value={repairRequest.City} error={this.state.errors.City ? true : false} />
                            <Form.Dropdown required search selection label='State' name='State' width={4} onChange={this.onTextChange} value={repairRequest.State} options={stateOptions} placeholder='Select state' error={!!this.state.errors.State} />
                            <Form.Input required label='Zip/Postal code' name='Zip' width={4} onChange={this.onTextChange} value={repairRequest.Zip} error={this.state.errors.Zip ? true : false} />
                            <Form.Input label='Country' width={4} value={repairRequest.Country}>
                                <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                            </Form.Input>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input required label='Telephone' name='Telephone' width={4} onChange={this.onTextChange} value={repairRequest.Telephone} error={this.state.errors.Telephone ? true : false} />
                            <Form.Input label='Fax' name='Fax' width={4} onChange={this.onTextChange} value={repairRequest.Fax} error={this.state.errors.Fax ? true : false} />
                        </Form.Group>
                        <Form.Checkbox label='Save this address'
                            checked={this.state.isSaveAddressBook} onChange={this.onSaveAddressBookChange} />
                    </Form>
                </Segment>
                <Segment>
                    <Header>
                        <Icon name='shipping' />
                        Shipping information
                    </Header>
                    <Divider />
                    <Form>
                        <Form.Dropdown search selection required
                            label='Shipping option' name='UpsServiceCode'
                            options={shippingOptions}
                            value={repairRequest.UpsServiceCode}
                            onChange={this.onTextChange}
                            placeholder='Select shipping option'
                            width={4}
                            error={!!this.state.errors.UpsServiceCode}
                        />
                        <Form.Dropdown search selection required
                            label='Ship to'
                            options={shipToOptions}
                            value={this.state.selectedShipToMasterId}
                            onChange={this.onShipToChange}
                            placeholder='Select ship to'
                            width={8}
                            error={!!this.state.errors.ShipTo}
                        />
                        <Form.Field>
                            <label>Address</label>
                            <p style={{ marginLeft: 14 }}>
                                {repairRequest?.ShipToAddress}<br />
                                {repairRequest?.ShipToCity
                                    ? `${repairRequest.ShipToCity}, ${repairRequest.ShipToState} ${repairRequest.ShipToZip}`
                                    : ''}<br />
                                {repairRequest?.ShipToCountry}
                            </p>
                        </Form.Field>
                        <Form.Field>
                            <label>Number of packing boxes<span style={{ fontWeight: 'normal' }}> (Min: 1, Max: {this.props.Reducer.packageMaxCount})</span></label>
                            <Form.Group>
                                {this.buttonForNumberOfPackingBox('subtraction', 5)}
                                {this.buttonForNumberOfPackingBox('subtraction', 1)}
                                <Form.Input width={1} value={this.state.numberOfPackingBox}>
                                    <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                                </Form.Input>
                                {this.buttonForNumberOfPackingBox('addition', 1)}
                                {this.buttonForNumberOfPackingBox('addition', 5)}
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <label>
                                Weight (lbs)<span class='required' />
                                <span style={{ fontWeight: 'normal' }}> {Messages.REPAIRREQUEST_MSG_RQUEST_WEIGHT}</span>
                            </label>
                            <Grid columns={6}>
                                {this.state.repairRequest.TrackingNumberDetails.map((x, idx) => {
                                    return (
                                        <Grid.Column>
                                            <Form.Input error={this.state.errors.Weight && (x.Weight == null || x.Weight <= 0 || x.Weight > 150 || (this.state.errors.isDigitNumberLimit == idx))}>
                                                <Input fluid label={`Box ${idx + 1}`} value={x.Weight} idx={idx} onChange={this.onWeightChange} />
                                            </Form.Input>
                                        </Grid.Column>
                                    );
                                })}
                            </Grid>
                        </Form.Field>
                    </Form>
                </Segment>
                <Segment>
                    <Header>
                        <Icon name='tag' />
                        Repair details
                    </Header>
                    <Divider />
                    <Table celled striped size='small' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={styleCell} width={2}>Category<span style={{ color: 'red' }}> *</span></Table.HeaderCell>
                                <Table.HeaderCell style={styleCell} width={2}>Model<span style={{ color: 'red' }}> *</span></Table.HeaderCell>
                                <Table.HeaderCell style={styleCell} width={2}>Serial #{requiredPopup}</Table.HeaderCell>
                                <Table.HeaderCell width={10}>Problem description{requiredPopup}</Table.HeaderCell>
                                <Table.HeaderCell width={0}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {repairDetails}
                        </Table.Body>
                    </Table>
                    <Button primary onClick={this.onAddLineClick} color={defaultBtnColor}>
                        <Icon name="plus" />Add line
                    </Button>
                </Segment>
                <Segment clearing vertical>
                    <Button icon='check' floated='right' color={defaultBtnColor} onClick={this.onConfirmClick}>
                        <Icon name='check' />Confirm
                    </Button>
                </Segment>
                {this.state.isConfirmModalOpen ? <RequestConfirm repairRequest={this.state.repairRequest} confirmModalClose={this.confirmModalClose} isSaveAddressBook={this.state.isSaveAddressBook} selectedShipToMasterId={this.state.selectedShipToMasterId} /> : ''}
                {completeModal}
                <Dimmer active={this.props.Reducer.fileDownloading || this.props.Reducer.customerAddressBookFetching} page>
                    <Loader content="Loading..." size="huge"></Loader>
                </Dimmer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Request);
