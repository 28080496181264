"use strict";

export default class {
    constructor(dataObject = null) {
        this.Category = '';
        this.Model = '';
        this.SerialNumber = '';
        this.ProblemDescription = '';

        // Import data if set
        if (dataObject) {
            try {
                this.Category = dataObject.category;
                this.Model = dataObject.model;
                this.SerialNumber = dataObject.serialNumber;
                this.ProblemDescription = dataObject.problemDescription;
            }
            catch (e) {
                throw "Data import error @ RepairRequestDetails data.";
            }
        }
    }
}