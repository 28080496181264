import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js';

import { Tab, Table, Button, Message, Dimmer, Loader } from 'semantic-ui-react';
import AddressEditModal from './AddressEditModal.js';
import AppConfig from '../../AppConfig.js';
import CustomerAddressBook from '../../Models/ViewModels/CustomerAddressBook.js';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage.js';

const MODAL_STAT_ADDRESSBOOK_NEW = 'MODAL_STAT_ADDRESSBOOK_NEW';
const MODAL_STAT_ADDRESSBOOK_EDIT = 'MODAL_STAT_ADDRESSBOOK_EDIT';

export class AddressBookTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: '',
            customerAddressId: 0,

            loading: false,
            loadingError: false,
            errorMessage: '',

            isAdditionSuccessFlag: false,
            isUpdateSuccessFlag: false,
            isDeleteSuccessFlag: false,
        };

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.addressDelete = this.addressDelete.bind(this);
        this.onModalStatusChange = this.onModalStatusChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.setSuccessFlag = this.setSuccessFlag.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // The function is execute when the user clicks the "Manual Download" button
        if (!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading) {
            this.setState({
                loadingError: false,
                errorMessage: '',
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
            });
        }
        // Address edit modal has been opened
        else if (!prevState.modalStatus && this.state.modalStatus) {
            this.loadingErrorFlagInitialization();
        }
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization(true);
    }

    loadingErrorFlagInitialization(isUnmount = false) {
        if (!isUnmount || !this.props.Reducer.userMasterFetchingError) {
            this.props.Action.manualDownloadingError(false);
            this.props.Action.masterDataFetchingError(false);
            this.props.Action.userMasterFetchError(false);
            this.props.Action.logoutFetchingError(false);
            this.setState({
                loadingError: false,
                errorMessage: '',
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
            });
        }
    }

    addressDelete(id) {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook/' + id, {
            mode: 'cors', method: 'delete', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                this.props.Action.setCustomerAddressBook(customerAddressBook);
                this.setState({
                    loading: false,
                    isDeleteSuccessFlag: true,
                });
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    onModalStatusChange(modalStatus, customerAddressId = 0) {
        this.setState({ modalStatus, customerAddressId });
    }

    onDeleteClick(evn, data) {
        this.loadingErrorFlagInitialization();
        if (window.confirm(Messages.REPAIRREQUEST_MSG_CONFIRMMESSAGE_DELETE)) {
            this.addressDelete(data.id);
        }
    }

    setSuccessFlag(isAdditionSuccessFlag, isUpdateSuccessFlag) {
        this.setState({
            isAdditionSuccessFlag,
            isUpdateSuccessFlag,
        });
    }

    render() {
        let managerFlag = this.props.Reducer.userInformation.ManagerFlag;

        let errorMessage;
        if (this.state.loadingError) {
            errorMessage = <ErrorMessage active content={this.state.errorMessage} page />;
        } else if (this.props.Reducer.userMasterFetchingError || this.props.Reducer.manualDownloadingError || this.props.Reducer.logoutingError) {
            errorMessage = <ErrorMessage active content={this.props.Reducer.errorMessage} page />;
        }

        let successMessage;
        if (this.state.isAdditionSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_ADDITION} />;
        } else if (this.state.isUpdateSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_UPDATE} />;
        } else if (this.state.isDeleteSuccessFlag) {
            successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_DELETE} />;
        }

        let addressRows = this.props.Reducer.customerAddressBook.map(x => {
            return (
                <Table.Row key={x.Id}>
                    <Table.Cell>
                        <p>
                            {x.AddressLineOne}<br />
                            {x.AddressLineTwo ? x.AddressLineTwo : '-'}<br />
                            {x.AddressLineThree ? x.AddressLineThree : '-'}
                        </p>
                    </Table.Cell>
                    <Table.Cell content={x.City} />
                    <Table.Cell content={x.State} />
                    <Table.Cell content={x.Zip} />
                    <Table.Cell content={x.Country} />
                    <Table.Cell content={x.Telephone} />
                    <Table.Cell content={x.Fax} />
                    {managerFlag
                        ? <Table.Cell collapsing>
                            <Button color='green' content='Edit' disabled={this.props.Reducer.userMasterFetchingError}
                                onClick={() => this.onModalStatusChange(MODAL_STAT_ADDRESSBOOK_EDIT, x.Id)} />
                            <Button color='red' content='Delete' onClick={this.onDeleteClick} id={x.Id}
                                disabled={this.props.Reducer.userMasterFetchingError} />
                        </Table.Cell>
                        : ''}
                </Table.Row>
            )
        });

        return (
            <Tab.Pane>
                {successMessage}
                {errorMessage}
                {managerFlag
                    ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button primary content='Add new address' icon='plus' disabled={this.props.Reducer.userMasterFetchingError}
                            onClick={() => this.onModalStatusChange(MODAL_STAT_ADDRESSBOOK_NEW)} />
                    </div>
                    : ''}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='Address' />
                            <Table.HeaderCell content='City' />
                            <Table.HeaderCell content='State' />
                            <Table.HeaderCell content='Zip/Postal code' />
                            <Table.HeaderCell content='Country' />
                            <Table.HeaderCell content='Telephone' />
                            <Table.HeaderCell content='Fax' />
                            {managerFlag ? <Table.HeaderCell /> : ''}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {addressRows}
                    </Table.Body>
                </Table>

                {this.state.modalStatus
                    ? <AddressEditModal id={this.state.customerAddressId}
                        new={this.state.modalStatus == MODAL_STAT_ADDRESSBOOK_NEW}
                        edit={this.state.modalStatus == MODAL_STAT_ADDRESSBOOK_EDIT}
                        onModalClose={() => this.onModalStatusChange('')}
                        setSuccessFlag={this.setSuccessFlag} />
                    : ''}

                <Dimmer active={this.state.loading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Tab.Pane>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressBookTab);
