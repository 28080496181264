import * as Constant from '../Constants/Constant';
import ShipToMaster from '../Models/ViewModels/ShipToMaster';
import StateProvinceCodeMaster from '../Models/EntityModels/StateProvinceCodeMaster';
import UserInfornation from '../Models/ViewModels/UserInformation';
import RepairRequest from '../Models/ViewModels/RepairRequest';
import RepairRequestDetail from '../Models/ViewModels/RepairRequestDetail';
import TrackingNumberDetail from '../Models/ViewModels/TrackingNumberDetail';
import WarrantyMaster from '../Models/ViewModels/WarrantyMaster';
import WarrantyRuleMaster from '../Models/ViewModels/WarrantyRuleMaster';
import ModelFromJbase from '../Models/EntityModels/ModelFromJbase';
import InvoiceFromJbase from '../Models/ViewModels/InvoiceFromJbase';
import ProtectionPlan from '../Models/ViewModels/ProtectionPlan';
import ProtectoinPlanDetail from '../Models/ViewModels/ProtectoinPlanDetail';
import CustomerEndUser from '../Models/ViewModels/CustomerEndUser';
import CustomerAddressBook from '../Models/ViewModels/CustomerAddressBook';
import RepairServicePlanMaster from '../Models/ViewModels/RepairServicePlanMaster';
import RepairServicePlanPrice from '../Models/ViewModels/RepairServicePlanPrice';
import ModelRuleMaster from '../Models/ViewModels/ModelRuleMaster';
import ModelRulesForRepairServicePlanCoverage from '../Models/ViewModels/ModelRulesForRepairServicePlanCoverage';

const initialState = {
    tabStatus: (findGetParam("recepid") != null ? Constant.REPAIRREQUEST_STAT_RESETPASSWORD : Constant.REPAIRREQUEST_STAT_LOGIN),
    csrftoken: '',

    userInformation: {},
    customerAddressBook: [],
    customerEndUsers: [],
    shipToMaster: [],
    repairRequests: [],
    warrantyMaster: [],
    stateProvinceCodeMaster: [],
    categoryMaster: [],
    upsServiceCodes: [],
    comparedPhrase: ['Please reload', 'please contact'],
    userMaster: [],
    tempModelMaster: [],
    tempInvoiceMaster: [],
    protectionPlans: [],
    repairServicePlanMaster: [],
    modelRuleMaster: [],
    modelRulesForRepairServicePlanCoverage: [],

    // loading flag, loading error flag, error message
    errorMessage: '',
    sessionChecking: true,
    logouting: false,
    logoutingError: false,
    loginChecking: false,
    loginCheckingError: false,
    informationIsLoading: false,
    informationIsLoadingError: false,
    requestFetching: false,
    requestFetchingError: false,
    fileDownloading: false,
    fileDownloadingError: false,
    masterDataIsLoading: false,
    masterDataIsLoadingError: false,
    requestSubmitting: false,
    requestSubmittingError: false,
    userMasterFetching: false,
    userMasterFetchingError: false,
    userSettingUpdating: false,
    userSettingUpdatingError: false,
    customerUserAdding: false,
    customerUserAddingError: false,
    manualDownloading: false,
    manualDownloadingError: false,
    customerAddressBookFetching: false,
    customerAddressBookFetchingError: false,
    invoiceFetching: false,
    invoiceFetchingError: false,
    protectionPlanSaving: false,
    protectionPlanSavingError: false,
    protectionPlanListFetching: false, // for list screen
    protectionPlanListFetchingError: false,
    protectionPlanFetching: false, // for view screen
    protectionPlanFetchingError: false,
    customerEndUsersFetching: false,
    customerEndUsersFetchingError: false,

    // Request
    isDisclaimerOpen: false,
    savedRequest: null,
    isCompleteModalOpen: false,
    packageMaxCount: 1, // Number of Packing boxの最大値
    gracePeriod: 0, // 保証期間の猶予期間（単位：月）

    // Login
    information: {},
    firstLogin: false,

    // resetPassword
    recepid: (findGetParam("recepid") != null ? findGetParam("recepid") : null),

    // UserSetting
    isChangeOwnUserId: false,
};

export default function Reducer(state = initialState, action) {
    switch (action.type) {
        // Root
        case Constant.ROOT_ACT_SESSIONCHECK_FETCHING:
            return Object.assign({}, state, {
                sessionChecking: action.bool,
            });
        case Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHINGERROR:
            return Object.assign({}, state, {
                customerAddressBookFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHING:
            return Object.assign({}, state, {
                customerAddressBookFetching: action.bool,
            });


        // Log out
        case Constant.ROOT_ACT_LOGOUT_FETCHING:
            return Object.assign({}, state, {
                logouting: action.bool,
            });
        case Constant.ROOT_ACT_LOGOUT_FETCHINGERROR:
            return Object.assign({}, state, {
                logoutingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.ROOT_ACT_LOGOUT_SUCCESS:
            return Object.assign({}, initialState, {
                information: state.information,
                sessionChecking: false,
                recepid: null,
            });

        // Login
        case Constant.LOGIN_ACT_LOGINCHECK_FETCHING:
            return Object.assign({}, state, {
                loginChecking: action.bool,
            });
        case Constant.LOGIN_ACT_LOGINCHECK_FETCHINGERROR:
            return Object.assign({}, state, {
                loginCheckingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHING:
            return Object.assign({}, state, {
                informationIsLoading: action.bool,
            });
        case Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHINGERROR:
            return Object.assign({}, state, {
                informationIsLoadingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.LOGIN_ACT_INFORMATIONFETCH_SUCCESS:
            return Object.assign({}, state, {
                information: action.information,
            });

        // Request
        case Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHING:
            return Object.assign({}, state, {
                masterDataIsLoading: action.bool,
            });
        case Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHINGERROR:
            return Object.assign({}, state, {
                masterDataIsLoadingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.REQUEST_ACT_MASTERDATAFETCH_SUCCESS:
            let shipToMaster = action.shipToMaster.map(x => new ShipToMaster(x));
            let warrantyMaster = [];
            action.warrantyMaster.forEach(warranty => {
                let newWarrantyMaster = new WarrantyMaster(warranty);
                newWarrantyMaster.Rules = action.warrantyRuleMaster.filter(x => x.warrantyMasterId == warranty.id).sort((a, b) => a.digit - b.digit).map(x => new WarrantyRuleMaster(x));
                warrantyMaster.push(newWarrantyMaster);
            })
            let stateProvinceCodeMaster = action.stateProvinceCodeMaster.map(x => new StateProvinceCodeMaster(x));
            let upsServiceCodes = action.upsServiceCodes.map(x => ({ key: x.value, value: x.value, text: x.text }));
            let categoryMaster = action.categoryMaster.map(x => {
                return { value: x.value, required: x.required };
            });
            let tempModelMaster = action.models.map(x => new ModelFromJbase(x));
            return Object.assign({}, state, {
                shipToMaster,
                warrantyMaster,
                stateProvinceCodeMaster,
                upsServiceCodes,
                packageMaxCount: action.packageMaxCount,
                gracePeriod: action.gracePeriod,
                categoryMaster,
                tempModelMaster,
            });
        case Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHING:
            return Object.assign({}, state, {
                requestSubmitting: action.bool,
            });
        case Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHINGERROR:
            return Object.assign({}, state, {
                requestSubmittingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.REQUEST_ACT_REQUESTSUBMIT_SUCCESS: {
            let savedRequest = new RepairRequest();
            savedRequest.Id = action.id;
            savedRequest.TrackingNumberDetails = action.trackingNumberDetails.map(x => new TrackingNumberDetail(x));
            return Object.assign({}, state, {
                savedRequest,
                isCompleteModalOpen: true,
            });
        }
        case Constant.REQUEST_STAT_COMPLETEMODAL: {
            let savedRequest = action.bool ? state.savedRequest : null;
            return Object.assign({}, state, {
                savedRequest,
                isCompleteModalOpen: action.bool,
            });
        }

        // History
        case Constant.HISTORY_ACT_REQUESTFETCH_LOADING:
            return Object.assign({}, state, {
                requestFetching: action.bool,
            });
        case Constant.HISTORY_ACT_REQUESTFETCH_LOADINGERROR:
            return Object.assign({}, state, {
                requestFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.HISTORY_ACT_REQUESTFETCH_SUCCESS:
            let repairRequests = JSON.parse(JSON.stringify(state.repairRequests));
            action.repairRequestHeaders.forEach(header => {
                repairRequests = repairRequests.filter(x => x.Id != header.id);
                let request = new RepairRequest(header);
                request.RepairDetails = action.repairRequestDetails.filter(x => x.repairRequestHeaderId == header.id).map(x => new RepairRequestDetail(x));
                request.TrackingNumberDetails = action.trackingNumberDetails.filter(x => x.repairRequestHeaderId == header.id).map(x => new TrackingNumberDetail(x));
                repairRequests.push(request);
            });
            return Object.assign({}, state, {
                repairRequests
            });

        // User Setting
        case Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHING:
            return Object.assign({}, state, {
                userMasterFetching: action.bool,
            });
        case Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHINGERROR:
            return Object.assign({}, state, {
                userMasterFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.USERSETTING_ACT_USERMASTERFETCH_SUCCESS:
            return Object.assign({}, state, {
                userMaster: action.userMaster,
            });
        case Constant.USERSETTING_ACT_UPDATE_FETCHING:
            return Object.assign({}, state, {
                userSettingUpdating: action.bool,
            });
        case Constant.USERSETTING_ACT_UPDATE_FETCHINGERROR:
            return Object.assign({}, state, {
                userSettingUpdatingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.USERSETTING_ACT_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                userMaster: action.userMaster,
                isChangeOwnUserId: action.isChangeOwnUserId,
            });
        case Constant.USERSETTING_ACT_INSERT_FETCHING:
            return Object.assign({}, state, {
                customerUserAdding: action.bool,
            });
        case Constant.USERSETTING_ACT_INSERT_FETCHINGERROR:
            return Object.assign({}, state, {
                customerUserAddingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.USERSETTING_ACT_INSERT_SUCCESS:
            return Object.assign({}, state, {
                userMaster: action.userMaster,
            });

        // Protectoin plan
        case Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHING:
            return Object.assign({}, state, {
                invoiceFetching: action.bool,
            });
        case Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHINGERROR:
            return Object.assign({}, state, {
                invoiceFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.PROTECTIONPLAN_ACT_SAVING:
            return Object.assign({}, state, {
                protectionPlanSaving: action.bool,
            });
        case Constant.PROTECTIONPLAN_ACT_SAVINGERROR:
            return Object.assign({}, state, {
                protectionPlanSavingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHING:
            return Object.assign({}, state, {
                protectionPlanListFetching: action.bool,
            });
        case Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHINGERROR:
            return Object.assign({}, state, {
                protectionPlanListFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHING:
            return Object.assign({}, state, {
                protectionPlanFetching: action.bool,
            });
        case Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHINGERROR:
            return Object.assign({}, state, {
                protectionPlanFetchingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHING:
            return Object.assign({}, state, {
                customerEndUsersFetching: action.bool,
            });
        case Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHINGERROR:
            return Object.assign({}, state, {
                customerEndUsersFetchingError: action.bool,
                errorMessage: action.message,
            });

        // File download
        case Constant.FILEDOWNLOAD_ACT_DOWNLOADING:
            return Object.assign({}, state, {
                fileDownloading: action.bool,
            });
        case Constant.FILEDOWNLOAD_ACT_DOWNLOADINGERROR:
            return Object.assign({}, state, {
                fileDownloadingError: action.bool,
                errorMessage: action.message,
            });
        case Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADING:
            return Object.assign({}, state, {
                manualDownloading: action.bool,
            });
        case Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADINGERROR:
            return Object.assign({}, state, {
                manualDownloadingError: action.bool,
                errorMessage: action.message,
            });

        // set state
        case Constant.LOGIN_ACT_SET_USERINFORMATION:
            let userInformation = new UserInfornation(action.userInformation);
            return Object.assign({}, state, {
                userInformation,
            });
        case Constant.LOGIN_ACT_SET_CSRFTOKEN:
            return Object.assign({}, state, {
                csrftoken: action.csrftoken,
            });
        case Constant.REQUEST_ACT_SET_DISCLAIMER:
            return Object.assign({}, state, {
                isDisclaimerOpen: action.isDisclaimerOpen
            });
        case Constant.REQUEST_ACT_SET_CUSTOMERADDRESSBOOK:
            const customerAddressBook = action.customerAddressBook.map(x => new CustomerAddressBook(x));
            return Object.assign({}, state, {
                customerAddressBook,
            });
        case Constant.USERSETTING_ACT_SET_CUSTOMERENDUSERS:
            const customerEndUsers = action.customerEndUsers.map(x => new CustomerEndUser(x));
            return Object.assign({}, state, {
                customerEndUsers,
            });
        case Constant.PROTECTOPNPLAN_ACT_SET_TEMPINVOICEMASTER:
            const tempInvoiceMaster = action.invoices.map(x => new InvoiceFromJbase(x));
            return Object.assign({}, state, {
                tempInvoiceMaster,
            });
        case Constant.PROTECTOPNPLAN_ACT_UPDATE_PROTECTIONPLAN:
            let protectionPlans = [...state.protectionPlans];
            action.headers.forEach(header => {
                protectionPlans = protectionPlans.filter(x => x.Id != header.id);

                const newHeader = new ProtectionPlan(header);
                const details = action.details.filter(x => x.protectionPlanHeaderId == newHeader.Id);
                newHeader.Details = details.map(x => new ProtectoinPlanDetail(x));
                protectionPlans.push(newHeader);
            });
            return Object.assign({}, state, {
                protectionPlans,
            });
        case Constant.PROTECTOPNPLAN_ACT_SET_REPAIRSERVICEPLANMASTER:
            const repairServicePlanMaster = action.repairServicePlanMaster.map(master => {
                const newMaster = new RepairServicePlanMaster(master);
                newMaster.Prices = action.repairServicePlanPrices.filter(x => x.repairServicePlanMasterId == newMaster.Id)
                    .map(x => new RepairServicePlanPrice(x));
                return newMaster;
            });
            return Object.assign({}, state, {
                repairServicePlanMaster,
            });
        case Constant.PROTECTOPNPLAN_ACT_SET_MODELRULEMASTER:
            const modelRuleMaster = action.modelRuleMaster.map(x => new ModelRuleMaster(x));
            return Object.assign({}, state, {
                modelRuleMaster,
            });
        case Constant.PROTECTOPNPLAN_ACT_SET_MODELRULESFORREPAIRSERVICEPLANCOVERAGE:
            const modelRulesForRepairServicePlanCoverage = action.coverages.map(x => new ModelRulesForRepairServicePlanCoverage(x));
            return Object.assign({}, state, {
                modelRulesForRepairServicePlanCoverage,
            });

        // set tabStatus
        case Constant.REPAIRREQUEST_ACT_SET_TABSTATUS:
            return Object.assign({}, state, {
                tabStatus: action.tabStatus,
            });

        default:
            return state;
    }
}

function findGetParam(paramName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === paramName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
