import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mapStateToProps, mapDispatchToProps } from '../../Root.js'

import { Segment, Header, Form, Button, Dimmer, Icon, Modal, Message, Loader } from 'semantic-ui-react';

import * as Messages from '../../Resources/Messages';
import AppConfig from '../../AppConfig';
import ErrorMessage from '../ErrorMessage.js';

export class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: this.props.Reducer.userInformation.UserName,
            email: this.props.Reducer.userInformation.UserId,
            subject: '',
            message: '',

            validationErrors: {},
            isModalOpen: false,
            loading: false,
            loadingError: '',
            loadingSuccess: false,
        };

        this.loadingErrorFlagInitialization = this.loadingErrorFlagInitialization.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.confirmModalClose = this.confirmModalClose.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
    }

    shouldComponentUpdate(prevProps, prevState) {
        const propsDiff = _.isEqual(prevProps, this.props);
        const stateDiff = _.isEqual(prevState, this.state);
        return !(propsDiff && stateDiff);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!prevProps.Reducer.manualDownloading && this.props.Reducer.manualDownloading)
            || (!prevProps.Reducer.logouting && this.props.Reducer.logouting)
        ) {
            this.setState({
                validationErrors: {},
                loadingError: '',
                loadingSuccess: false,
            });
        }
    }

    componentWillUnmount() {
        this.loadingErrorFlagInitialization();
    }

    loadingErrorFlagInitialization() {
        this.props.Action.logoutFetchingError(false);
        this.props.Action.manualDownloadingError(false);
    }

    onTextChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onConfirmClick() {
        let validationErrors = {};
        let email = this.state.email.replace(/\s+/g, '');
        if (!this.state.contactName) {
            validationErrors.contactName = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CONTACTNAME;
        }
        if (!email) {
            validationErrors.email = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_EMAIL;
        }
        if (!this.state.subject) {
            validationErrors.subject = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_SUBJECT;
        }
        if (!this.state.message) {
            validationErrors.message = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_MESSAGE;
        }
        this.setState({
            validationErrors,
            loadingError: '',
            isModalOpen: !Object.keys(validationErrors).length,
            email,
        });

        this.loadingErrorFlagInitialization();
    }

    confirmModalClose() {
        this.setState({
            isModalOpen: false,
            loadingError: '',
        });
    }

    sendMail() {
        this.setState({ loading: true });
        fetch(AppConfig.ApiUrlContactUs, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.csrftoken),
            },
            body: JSON.stringify({
                contactName: this.state.contactName,
                email: this.state.email,
                subject: this.state.subject,
                body: this.state.message,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.setState({ isModalOpen: false, loading: false, loadingSuccess: true });
            })
            .catch(e => {
                this.setState({ loading: false, loadingError: e.message });
            });
    }

    onOkClick() {
        this.setState({
            contactName: this.props.Reducer.userInformation.UserName,
            email: this.props.Reducer.userInformation.UserId,
            subject: '',
            message: '',
            loadingSuccess: false,
        });
    }

    render() {
        let errorMessage = '';
        if (Object.keys(this.state.validationErrors).length) {
            let errors = Object.keys(this.state.validationErrors).map(x => <>{this.state.validationErrors[x]}<br /></>);
            errorMessage = <Message error>{errors}</Message>;
        } else if (this.state.loadingError) {
            errorMessage = <ErrorMessage content={this.state.loadingError} active />;
        } else if (this.props.Reducer.logoutingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active />;
        } else if (this.props.Reducer.manualDownloadingError) {
            errorMessage = <ErrorMessage content={this.props.Reducer.errorMessage} active page />;
        }

        let confirmModal = this.state.isModalOpen ?
            <Modal open={true} onClose={this.confirmModalClose}>
                <Modal.Content>
                    <Message warning icon='exclamation triangle'
                        header={Messages.REPAIRREQUEST_MSG_WARNINGHEADER_CONTACTUS}
                        content={Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_CONTACTUS}
                    />
                    {errorMessage}
                    <dl>
                        <dt>Contact Name</dt>
                        <dd>{this.state.contactName}</dd>
                        <dt>Email</dt>
                        <dd>{this.state.email}</dd>
                        <dt>Subject</dt>
                        <dd>{this.state.subject}</dd>
                        <dt>Message</dt>
                        <dd style={{ whiteSpace: 'pre-wrap' }}>{this.state.message}</dd>
                    </dl>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Cancel' onClick={this.confirmModalClose} />
                    <Button content='Send' icon='paper plane outline' color='blue' onClick={this.sendMail} />
                </Modal.Actions>
            </Modal> : '';

        let successDimmer = this.state.loadingSuccess ?
            <Dimmer active={this.state.loadingSuccess} page>
                <Header as='h2' icon inverted>
                    <Icon name='check' />
                    {Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_CONTACTUS}
                </Header>
                <div style={{ display: 'flex' }}>
                    <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={this.onOkClick} icon='check' content='OK' />
                </div>
            </Dimmer> : '';

        return (
            <>
                <Header as='h2' content='Contact Us' icon='mail outline' />
                {errorMessage}
                <Segment>
                    <Header icon='pencil' content='Send message' dividing />
                    <Form>
                        <Form.Group>
                            <Form.Input label='Contact name' name='contactName' value={this.state.contactName} onChange={this.onTextChange} width={4} error={!!this.state.validationErrors.contactName} />
                            <Form.Input label='Email' name='email' value={this.state.email} onChange={this.onTextChange} width={4} error={!!this.state.validationErrors.email} />
                        </Form.Group>
                        <Form.Input label='Subject' name='subject' value={this.state.subject} onChange={this.onTextChange} error={!!this.state.validationErrors.subject} />
                        <Form.TextArea label='Message' rows={10} name='message' value={this.state.message} onChange={this.onTextChange} error={!!this.state.validationErrors.message} />
                        <div style={{ display: 'flex' }}>
                            <Button primary icon='check' content='Confirm' style={{ marginLeft: 'auto' }} onClick={this.onConfirmClick} />
                        </div>
                    </Form>
                </Segment>
                {confirmModal}{successDimmer}
                <Dimmer active={this.state.loading} page>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUs);
